import { Link } from 'react-router-dom';
import discord from '@/Assets/Icons/discord2.svg';
import gitbook from '@/Assets/Icons/gitbook.svg';
import github from '@/Assets/Icons/github.svg';
import medium from '@/Assets/Icons/medium.svg';
import twitter from '@/Assets/Icons/twitter2.png';

import { FC } from 'react';
import { DocumentIcon } from '@/Assets/Icons/Document';
import peckShield from '@/Assets/Icons/PeckShield.png';
import { QuestionIcon } from '@/Assets/Icons/QuestionIcon';
import { Settings } from '@/Assets/Icons/Settings';
import { Shield } from '@/Assets/Icons/Shield';
import { ROUTES } from '@/constants/routes.constants';
import { AuditsPopover } from './AuditsPopover';
import './styles.css';

interface FooterProps {
  auditsOnly?: boolean;
}

const Footer: FC<FooterProps> = ({ auditsOnly = false }) => {
  return (
    <footer className="footer footer-mt">
      <div className="footer-nav">
        {!auditsOnly && (
          <>
            <Link to={ROUTES.FAQ_PAGE} className="footer-nav-item">
              <QuestionIcon />
              FAQs
            </Link>
            <Link to={ROUTES.PRIVACY_POLICY_PAGE} className="footer-nav-item">
              <DocumentIcon />
              Terms & Privacy
            </Link>
            <Link to={ROUTES.DEV_API_PAGE} className="footer-nav-item">
              <Settings />
              API
            </Link>
          </>
        )}
        <AuditsPopover />
      </div>

      <div className="footer-audits">
        <Link
          to={ROUTES.AUDIT_PAGE}
          target="_blank"
          className="footer-audits-item"
        >
          <Shield />
          <span>
            <span className="text-white fw-bold bold">BezuhlyiE</span> Security
            Audit
          </span>
        </Link>
        <Link
          to={ROUTES.MONITORING_AUDIT_PAGE}
          target="_blank"
          className="footer-audits-item"
          aria-label="PeckShield Security Monitoring"
        >
          <img src={peckShield} draggable={false} alt="" />
          Security Monitoring
        </Link>
      </div>

      {!auditsOnly && (
        <div className="footer-socials">
          <a
            href="https://docs.retrobridge.io/"
            target="_blank"
            rel="noreferrer"
            className="footer-socials-item"
          >
            <img
              style={{ width: '16px', height: '16px' }}
              src={gitbook}
              alt="logo"
            />
          </a>
          <a
            href="https://github.com/retro-bridge"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-socials-item"
          >
            <img
              style={{ width: '14px', height: '14px' }}
              src={github}
              alt="logo"
            />
          </a>
          <a
            href="https://retro-bridge.medium.com"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-socials-item"
          >
            <img
              style={{ width: '14px', height: '14px' }}
              src={medium}
              alt="logo"
            />
          </a>
          <a
            href="https://twitter.com/retro_bridge"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-socials-item"
          >
            <img
              style={{ width: '14px', height: '14px' }}
              src={twitter}
              alt="logo"
            />
          </a>
          <a
            href="https://discord.gg/retrobridge"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-socials-item"
          >
            <img
              style={{ width: '16px', height: '16px' }}
              src={discord}
              alt="logo"
            />
          </a>
        </div>
      )}
    </footer>
  );
};

export default Footer;
