import { isValidAddress } from '@/evm/utils';
import { NetworkTypes } from '@/providers/web3Provider';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { isValidStarknetAddress } from '@/starknet/utils/isValidAddress';
import { useAppStore } from '@/stores/app.store';
import { useNetworksStore } from '@/stores/networks.store';
import { isValidTonAddress } from '@/ton/utils';
import { useTonAddress } from '@tonconnect/ui-react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useAccount as useEVMAccount } from 'wagmi';
import { useShallow } from 'zustand/react/shallow';
import { AmountInput } from '../../../SendPage/components/AmountInput';
import { CurrencySelect } from '../../../SendPage/components/CurrencySelect';
import { NetworkExchange } from '../../../SendPage/components/NetworkExchange';
import { NetworkInput } from '../../../SendPage/components/NetworkInput';
import { WalletButtons } from '../../../SendPage/components/WalletButtons';

export function FormPlaceholder() {
  const networks = useNetworksStore(s => s.networks);
  const [networkFrom, networkTo, error, walletTo] = useAppStore(
    useShallow(s => [s.networkFrom, s.networkTo, s.error, s.walletTo])
  );
  const { address: evmAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const tonAddress = useTonAddress();

  const isFromStarknet = networkFrom?.network_type === NetworkTypes.STARKNET;
  const isFromTon = networkFrom?.network_type === NetworkTypes.TON;
  const isFromEVM = !isFromStarknet && !isFromTon;

  const isFromConnected =
    (isFromStarknet && !!starknetAddress) ||
    (isFromTon && !!tonAddress) ||
    (isFromEVM && !!evmAddress);

  const isToStarknet = networkTo?.network_type === NetworkTypes.STARKNET;
  const isToTon = networkTo?.network_type === NetworkTypes.TON;
  const isToEVM = !isToStarknet && !isToTon;

  const addressTo = useMemo(() => {
    switch (networkTo?.network_type) {
      case NetworkTypes.EVM:
        return evmAddress;
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;

      default:
        return evmAddress;
    }
  }, [evmAddress, starknetAddress, tonAddress, networkTo?.network_type]);

  const isToConnected =
    (isToStarknet && !!starknetAddress) ||
    (isToTon && !!tonAddress) ||
    (isToEVM && !!evmAddress);

  const isWalletToAddressValid = (
    address: string,
    isToStark: boolean,
    isToTon: boolean
  ): boolean => {
    if (isToStark) {
      return isValidStarknetAddress(address);
    } else if (isToTon) {
      return isValidTonAddress(address);
    }
    return isValidAddress(address);
  };

  return (
    <div
      style={{ opacity: 0, pointerEvents: 'none', maxWidth: '404px' }}
      id="advanced-form-placeholder"
    >
      <div className="d-flex flex-column gap-2 mt-4">
        <div className="d-flex">
          <div className="send-form--label">From</div>
          {isFromConnected && (
            <div className="send-form--label ms-auto">
              Your balance: <span className="text-white fw-bold">{0}</span>
            </div>
          )}
        </div>
        <NetworkInput
          networks={networks}
          value={networkFrom}
          onChange={() => {}}
          title="Select Source"
        />
        <AmountInput
          label={
            <div className="d-flex">
              <span className="amount-input--label">You send</span>
              <button className="amount-input--action">Max</button>
              <div className="amount-input--label ms-auto">${0}</div>
            </div>
          }
          value={'0'}
        >
          <CurrencySelect value={undefined} options={[]} />
        </AmountInput>
      </div>
      <AnimatePresence initial={false}>
        {!!error && (
          <motion.p
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            className="send-form--error"
          >
            <span className="d-block fw-medium error-message mt-2">
              {error}
            </span>
          </motion.p>
        )}
      </AnimatePresence>
      <div className="d-flex justify-content-center mt-4">
        <NetworkExchange />
      </div>
      <div className="d-flex flex-column gap-2 mt-3">
        <div className="d-flex">
          <div className="send-form--label">To</div>
          {isToConnected && (
            <div className="send-form--label ms-auto">
              Your balance: <span className="text-white fw-bold">{0}</span>
            </div>
          )}
        </div>
        <NetworkInput
          networks={networks}
          value={networkTo}
          onChange={() => {}}
          title="Select Destination"
        />
        <AmountInput
          label={
            <div className="d-flex">
              <span className="amount-input--label">You receive</span>
              <div className="amount-input--label ms-auto">${0}</div>
            </div>
          }
          readonly
          value={'0'}
        >
          <CurrencySelect value={undefined} options={[]} />
        </AmountInput>
      </div>
      <div className="fs-6 text-white fw-bold select-address-text mt-4">
        Select Receiving Address
      </div>

      <WalletButtons
        isFromConnected={isFromConnected}
        isToConnected={isToConnected}
        destinationType={networkTo?.network_type}
        connectedAddress={addressTo}
        value={walletTo}
        setValue={() => {}}
        isValidAddress={isWalletToAddressValid(walletTo, isToStarknet, isToTon)}
      />
      {isFromConnected ? (
        <button
          className={clsx('btn btnSecondary w-100 margin-top', 'disabled-link')}
        >
          Create Transfer
        </button>
      ) : (
        <Button
          variant="outline-secondary"
          className={'btn btnGradient w-100 mt-1 py-3 '}
        >
          {isToConnected
            ? 'Connect Required Wallets to Transfer'
            : 'Connect Wallet'}
        </Button>
      )}
    </div>
  );
}
