import { useTonAddress } from '@tonconnect/ui-react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useAccount as useEVMAccount, useSwitchChain } from 'wagmi';

import { ArrowBackIcon } from '@/Assets/Icons/arrowBack';
import { ArrowDown } from '@/Assets/Icons/arrowDown';
import { BASE_URL } from '@/Config/ApiConfig';
import { PROGRESS_PAGE } from '@/constants/routes.constants';
import { useConnectedIcon } from '@/hooks/useConnectedIcon';
import { useNotify } from '@/hooks/useToast';
import { useTonConnectedIcon } from '@/hooks/useTonConnectedIcon';
import { useWithdraw } from '@/hooks/useWithdraw';
import { formatAddress } from '@/pages/PhasePage/PhaseCard/WalletMenu';
import { NetworkTypes } from '@/providers/web3Provider';
import { api } from '@/services/api';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useStarknetConnectedIcon } from '@/starknet/hooks/useConnectedIcon';
import { useAppStore } from '@/stores/app.store';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { toFixed } from '@/utils/numbers';
import clsx from 'clsx';
import { SwitchChainError, toHex, UserRejectedRequestError } from 'viem';
import { useShallow } from 'zustand/react/shallow';
import './TransferInfoPage.css';
import { FeeDetails } from './components/FeeDetails';
import { NetworkInfo } from './components/networkInfo';
import { UnsupportedChainDialog } from '@/components/UnsoportedChainDialog';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useSolanaConnectedIcon } from '@/hooks/useSolanaConnectedIcon';

function TransferInfoForm({ closeConfirm }: { closeConfirm: () => void }) {
  const navigate = useNavigate();
  const { address: account, chain: evmChain } = useEVMAccount();
  const { address: starknetAddress, chainId: starknetChainId } =
    useStarknetAccount();
  const tonAddress = useTonAddress();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const { switchChainAsync } = useSwitchChain();
  const [isUnsupportedDialogOpen, setIsUnsupportedDialogOpen] = useState(false);

  const { notify } = useNotify();

  const setIsWaitingForConfirmation = useAppStore(
    s => s.setIsWaitingForConfirmation
  );

  const [amountFrom, amountTo, walletTo, setIsCancelled] = useAppStore(
    useShallow(s => [s.amountFrom, s.amountTo, s.walletTo, s.setIsCancelled])
  );
  const [networkFrom, networkTo] = useAppStore(
    useShallow(s => [s.networkFrom, s.networkTo])
  );
  const [currencyFrom, currencyTo] = useAppStore(
    useShallow(s => [s.currencyFrom, s.currencyTo])
  );
  const [isDisabled, setIsDisabled] = useAppStore(
    useShallow(s => [s.isDisabled, s.setIsDisabled])
  );
  const [receiver, transactionFee] = useAppStore(
    useShallow(s => [s.receiver, s.transactionFee])
  );

  const { withdraw } = useWithdraw();

  const evmIcon = useConnectedIcon();
  const tonIcon = useTonConnectedIcon();
  const starknetIcon = useStarknetConnectedIcon();
  const solanaIcon = useSolanaConnectedIcon();

  const [infoBlockFromWidth, setInfoBlockFromWidth] = useState<number | null>(
    null
  );
  const [infoBlockToWidth, setInfoBlockToWidth] = useState<number | null>(null);

  const { prices } = usePriceUpdater();

  const amountFromUSD = useMemo(() => {
    if (!currencyFrom?.symbol) return 0;

    const currencyFromPrice = getPrice(currencyFrom?.symbol, prices);

    const amountFromUSD = +amountFrom * currencyFromPrice.usd;
    return amountFromUSD;
  }, [prices, currencyFrom?.symbol, amountFrom]);

  const amountToUSD = useMemo(() => {
    if (!currencyTo?.symbol) return 0;

    const currencyToPrice = getPrice(currencyTo?.symbol, prices);

    const amountToUSD = +amountTo * currencyToPrice.usd;
    return amountToUSD;
  }, [prices, currencyTo?.symbol, amountTo]);

  const isWrongNetwork = useMemo(() => {
    if (!networkFrom) {
      return true;
    }

    if (networkFrom.network_type === NetworkTypes.TON) return false;
    if (networkFrom.network_type === NetworkTypes.SOLANA) return false;

    if (networkFrom.network_type === NetworkTypes.STARKNET) {
      return !starknetChainId || toHex(starknetChainId) !== networkFrom.chainId;
    }
    if (!evmChain) {
      return true;
    }
    return evmChain.id !== +networkFrom.chainId;
  }, [evmChain, starknetChainId, networkFrom]);

  const switchNetworkHelper = useCallback(async () => {
    try {
      setIsDisabled(true);
      if (currencyFrom && currencyTo && networkFrom && switchChainAsync) {
        await switchChainAsync({ chainId: +networkFrom.chainId });
      }
    } catch (error) {
      console.error(error);
      if (error instanceof UserRejectedRequestError) {
        const parsedError = JSON.parse(JSON.stringify(error));
        if (
          parsedError.details.includes(
            'Missing or invalid. request() method: wallet_addEthereumChain'
          )
        ) {
          setIsUnsupportedDialogOpen(true);
        } else if (error instanceof SwitchChainError) {
          setIsUnsupportedDialogOpen(true);
        }
      }
    } finally {
      setIsDisabled(false);
    }
  }, [currencyFrom, currencyTo, networkFrom, switchChainAsync, setIsDisabled]);

  useEffect(() => {
    if (currencyFrom && currencyTo) api.getReceiver(currencyFrom, currencyTo);
  }, [currencyFrom, currencyTo]);

  const withdrawCurrencyHelper = useCallback(
    async (wallet_sender: string) => {
      let order;
      try {
        setIsDisabled(true);

        if (!currencyFrom || !currencyTo) {
          return;
        }

        order = await api.sendOrder(
          currencyFrom,
          currencyTo,
          amountFrom,
          wallet_sender,
          walletTo
        );

        if (!order) {
          throw new Error('Failed to create order');
        }

        navigate(`${PROGRESS_PAGE}/${order.id}`);

        setIsWaitingForConfirmation(true);

        const result = await withdraw({
          sendedAmount: +amountFrom,
          sendedCurrency: currencyFrom,
          network: networkFrom,
          walletReceiver: receiver,
        });

        if (!result) {
          setIsCancelled(true);
        }
      } catch (error) {
        if (
          (error as Error).message === 'ACTION_REJECTED' &&
          order &&
          networkFrom
        ) {
          api
            .closeOrder(order.id, networkFrom)
            .catch(() => console.warn('Failed to close order'));
        } else if (order && networkFrom) {
          api.setOrderAsFailed(order.id, networkFrom).catch(() => {
            console.warn('Failed to set order as failed');
          });
        }

        if (error instanceof AxiosError) {
          const defaultMessage =
            'Something went wrong. Please try again later.';
          switch (error.response?.status) {
            case 503:
              return notify({
                title: 'Temporary transfer limit',
                meassage: 'Destination network will become available shortly',
                type: 'error',
              });
            default:
              return notify({
                meassage: error?.response?.data?.message ?? defaultMessage,
                type: 'error',
              });
          }
        }
        notify({
          meassage: 'Something went wrong. Please try again later.',
          type: 'error',
        });
        setIsCancelled(true);
      } finally {
        setIsWaitingForConfirmation(false);
        setIsDisabled(false);
      }
    },
    [
      amountFrom,
      currencyFrom,
      networkFrom,
      receiver,
      currencyTo,
      walletTo,
      withdraw,
      navigate,
      setIsCancelled,
      setIsDisabled,
      setIsWaitingForConfirmation,
      notify,
    ]
  );

  const withdrawHelper = useCallback(async () => {
    if (
      networkFrom?.network_type === NetworkTypes.STARKNET &&
      starknetAddress
    ) {
      return await withdrawCurrencyHelper(starknetAddress);
    } else if (networkFrom?.network_type === NetworkTypes.TON && tonAddress) {
      return await withdrawCurrencyHelper(tonAddress);
    } else if (
      networkFrom?.network_type === NetworkTypes.SOLANA &&
      solanaAddress
    ) {
      return await withdrawCurrencyHelper(solanaAddress.toBase58());
    } else if (account) {
      return await withdrawCurrencyHelper(account);
    }
  }, [
    networkFrom,
    account,
    starknetAddress,
    tonAddress,
    solanaAddress,
    withdrawCurrencyHelper,
  ]);

  const onClickSendTransactionHandler = useCallback(() => {
    if (isWrongNetwork) {
      switchNetworkHelper();
    } else {
      withdrawHelper();
    }
  }, [isWrongNetwork, switchNetworkHelper, withdrawHelper]);

  const totalFeeInUSD = useMemo(() => {
    if (!currencyTo?.symbol) return 0;

    const price = getPrice(currencyTo?.symbol, prices);

    return toFixed(price.usd * +(transactionFee?.full_fee ?? 0), 2);
  }, [transactionFee, prices, currencyTo]);

  const isStarknetInBridge = useMemo(() => {
    const fromNet = networkFrom;
    const toNet = networkTo;

    const isFromStark = fromNet?.network_type === NetworkTypes.STARKNET;
    const isToStark = toNet?.network_type === NetworkTypes.STARKNET;
    return isFromStark || isToStark;
  }, [networkFrom, networkTo]);

  const minutes = isStarknetInBridge ? 2 : 1;

  return (
    <div className="formBg defaultRadius gradient-border-mask form-max-width form-info-mt">
      <Form className="transitionHeight formBody text-light bg-opacity-25   defaultRadius align-items-center mobile-margin-bottom">
        <div className="transferInfoTitle">Your Transfer Details</div>

        <div className="transactionInfoDableDiv">
          <div className="infoDiv ">
            {networkFrom?.network_type === NetworkTypes.SOLANA &&
              !!solanaAddress && (
                <NetworkInfo
                  netwSrc={BASE_URL + networkFrom?.network_image_url}
                  netw={networkFrom?.name ?? ''}
                  wallet={solanaAddress.toBase58() ?? ''}
                  amount={amountFrom}
                  amountUsd={amountFromUSD.toString()}
                  coinSymbol={currencyFrom?.symbol ?? ''}
                  setWidth={setInfoBlockFromWidth}
                />
              )}
            {networkFrom?.network_type === NetworkTypes.TON && !!tonAddress && (
              <NetworkInfo
                netwSrc={BASE_URL + networkFrom?.network_image_url}
                netw={networkFrom?.name ?? ''}
                wallet={tonAddress ?? ''}
                amount={amountFrom}
                amountUsd={amountFromUSD.toString()}
                coinSymbol={currencyFrom?.symbol ?? ''}
                setWidth={setInfoBlockFromWidth}
              />
            )}

            {networkFrom?.network_type === NetworkTypes.STARKNET &&
              !!starknetAddress && (
                <NetworkInfo
                  netwSrc={BASE_URL + networkFrom?.network_image_url}
                  netw={networkFrom?.name ?? ''}
                  wallet={starknetAddress ?? ''}
                  amount={amountFrom}
                  amountUsd={amountFromUSD.toString()}
                  coinSymbol={currencyFrom?.symbol ?? ''}
                  setWidth={setInfoBlockFromWidth}
                />
              )}
            {(networkFrom?.network_type === NetworkTypes.EVM ||
              networkFrom?.network_type === NetworkTypes.ZK_SYNC_ERA) &&
              !!account && (
                <NetworkInfo
                  netwSrc={BASE_URL + networkFrom?.network_image_url}
                  netw={networkFrom?.name ?? ''}
                  wallet={account ?? ''}
                  amount={amountFrom}
                  amountUsd={amountFromUSD.toString()}
                  coinSymbol={currencyFrom?.symbol ?? ''}
                  setWidth={setInfoBlockFromWidth}
                />
              )}
          </div>

          <div className="circle .circle-bg">
            <div className="transactionInfoDableDivCircle">
              <ArrowDown className="arrowDownIcon" />
            </div>
          </div>

          <div className="infoDiv">
            <NetworkInfo
              netwSrc={BASE_URL + networkTo?.network_image_url}
              netw={networkTo?.name ?? ''}
              wallet={walletTo}
              amount={amountTo}
              amountUsd={amountToUSD.toString()}
              coinSymbol={currencyTo?.symbol ?? ''}
              infoBlockFromWidth={infoBlockFromWidth}
              infoBlockToWidth={infoBlockToWidth}
              setWidth={setInfoBlockToWidth}
            />
          </div>
        </div>
        <div className="transfer-info--label mt-4">
          <span>Amount You Sent</span>
          <span className="ms-auto text-white">
            ${toFixed(amountFromUSD, 2)}
          </span>
        </div>

        <div className="infoDiv mb-3">
          <div className="networkInfoLeftDiv">
            <div className="imgDiv20 me-3">
              <img
                className="coinImg"
                src={BASE_URL + currencyFrom?.image_url}
                alt="network icon"
              />
            </div>
            <div className="infoText transfer-info--value">
              {amountFrom} {currencyFrom?.symbol}
            </div>
          </div>
        </div>

        <div className="transfer-info--label">
          <span>You receive</span>
          <span className="ms-auto text-white">${toFixed(amountToUSD, 2)}</span>
        </div>
        <div className="mb-4">
          <div className="infoDiv rounded-bottom-0">
            <div className="networkInfoLeftDiv">
              <div className="imgDiv20 me-3">
                <img
                  className="coinImg"
                  src={BASE_URL + currencyTo?.image_url}
                  alt="network icon"
                />
              </div>
              <div className="infoText transfer-info--value">
                {amountTo || 0} {currencyTo?.symbol}
              </div>
            </div>
            <div className="d-flex flex-column receiveShortBlock ms-auto">
              <p className="text-white bold">
                In less than {minutes} minute{minutes > 1 ? 's' : ''}
              </p>
              <span className="fw-medium blanco">
                ${totalFeeInUSD} Total Fee
              </span>{' '}
            </div>
          </div>
          <FeeDetails />
        </div>

        <div className="transfer-info--label-bold">Receiving address</div>
        <div className="infoDiv mb-4">
          <div className="networkInfoLeftDiv">
            <div className="imgDiv20 me-3">
              {(networkTo?.network_type === NetworkTypes.EVM ||
                networkTo?.network_type === NetworkTypes.ZK_SYNC_ERA) && (
                <img src={evmIcon} alt="network icon" />
              )}
              {networkTo?.network_type === NetworkTypes.TON && (
                <img src={tonIcon} alt="network icon" />
              )}
              {networkTo?.network_type === NetworkTypes.STARKNET && (
                <img
                  className="coinImg"
                  src={
                    starknetIcon || BASE_URL + '/static/networks/starkNet.svg'
                  }
                  alt="network icon"
                />
              )}
              {networkTo?.network_type === NetworkTypes.SOLANA && (
                <img
                  src={solanaIcon || BASE_URL + '/static/networks/solana.png'}
                  className={clsx(!solanaIcon && 'coinImg')}
                  alt="network icon"
                />
              )}
            </div>
            <div className="infoText fs-6 fw-bold">
              {formatAddress(walletTo, 4, 3)}
            </div>
          </div>
        </div>
        <button
          onClick={onClickSendTransactionHandler}
          type="button"
          className={clsx(
            'w-100 mb-4 py-3',
            isDisabled ? 'disabled-link btnSecondary' : 'btnGradient'
          )}
        >
          {isWrongNetwork ? 'Switch Network' : 'Send Transfer'}
        </button>

        <div className="center">
          <button
            onClick={closeConfirm}
            type="button"
            className="backBtn ms-auto me-auto"
          >
            <div className="d-flex">
              <ArrowBackIcon className="arroBack" />
              <div className="ms-2">Back</div>
            </div>
          </button>
        </div>
      </Form>
      <UnsupportedChainDialog
        isOpen={isUnsupportedDialogOpen}
        onOpenChange={setIsUnsupportedDialogOpen}
      />
    </div>
  );
}

export default TransferInfoForm;
