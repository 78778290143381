import { useTonConnectedIcon } from '@/hooks/useTonConnectedIcon';
import { useStarknetAccount } from '@/starknet/hooks/account';
import {
  PopoverContent,
  PopoverPortal,
  Root as PopoverRoot,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useEffect, useState } from 'react';
import { jsNumberForAddress } from 'react-jazzicon';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import { useDisconnect, useAccount as useEVMAccount } from 'wagmi';
import { useConnectedIcon } from '../../../hooks/useConnectedIcon';
import { useWindowResize } from '../../../hooks/useWindowResize';
import {
  NetworkTypes,
  useWalletConnectInteractor,
} from '../../../providers/web3Provider';
import { PhaseIcons } from '../Icons';

export const formatAddress = (
  address: string,
  startChars: number,
  endChars: number
) => {
  return `${address.slice(0, startChars)}...${address.slice(-endChars)}`;
};

interface WalletMenuProps {
  networkType: NetworkTypes;
}

export function WalletMenu({ networkType }: WalletMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  const icon = useConnectedIcon();
  const { address: EVMAddress } = useEVMAccount();
  const { disconnect: EVMdisconnect } = useDisconnect();

  const { address: starknetAddress } = useStarknetAccount();
  const { handleDeactivate } = useWalletConnectInteractor();

  const tonIcon = useTonConnectedIcon();
  const tonAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  const { width, height } = useWindowResize();

  const handleCollision = () => {
    const el = document.getElementById(
      'phase-wallet-menu'
    ) as HTMLElement | null;

    if (!el) return;

    const availableHeight = getComputedStyle(el).getPropertyValue(
      '--radix-popper-available-height'
    );

    if (parseInt(availableHeight) <= 0) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleCollision);

    return () => window.removeEventListener('scroll', handleCollision);
  }, []);

  useEffect(() => {
    handleCollision();
  }, [height]);

  const disconnect = async () => {
    if (starknetAddress) {
      handleDeactivate(NetworkTypes.STARKNET);
    }

    if (EVMAddress) {
      EVMdisconnect();
    }

    if (tonAddress) {
      await tonConnectUI.disconnect();
    }

    setIsOpen(false);
  };

  if (
    (!EVMAddress && networkType === NetworkTypes.EVM) ||
    (!tonAddress && networkType === NetworkTypes.TON)
  ) {
    return null;
  }

  return (
    <PopoverRoot open={isOpen} onOpenChange={val => setIsOpen(val)}>
      <PopoverTrigger className="phase-page-card--wallet">
        {networkType === NetworkTypes.EVM && (
          <>
            <span className="phase-page-card--wallet-icon">
              <img src={icon} alt="" />
            </span>
            {EVMAddress && formatAddress(EVMAddress, 4, 3)}
          </>
        )}
        {networkType === NetworkTypes.TON && (
          <>
            <span className="phase-page-card--wallet-icon">
              <img src={tonIcon} alt="" />
            </span>
            {tonAddress && formatAddress(tonAddress, 4, 3)}
          </>
        )}
        <PhaseIcons.Down />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          className="phase-wallet-menu"
          sideOffset={width > 768 ? 23 : 30}
          avoidCollisions={false}
          side="bottom"
          id="phase-wallet-menu"
        >
          <Jazzicon
            diameter={28}
            seed={EVMAddress && jsNumberForAddress(EVMAddress)}
          />
          <div className="phase-wallet-menu-content">
            <div className="phase-wallet-menu-heading">Connected account</div>
            <div className="phase-wallet-menu-address">
              {networkType === NetworkTypes.EVM &&
                EVMAddress &&
                formatAddress(EVMAddress, 7, 4)}
              {networkType === NetworkTypes.TON &&
                tonAddress &&
                formatAddress(tonAddress, 7, 4)}
            </div>
          </div>
          <button
            className="d-flex p-0 border-0 bg-transparent ms-auto"
            onClick={() => disconnect()}
          >
            <PhaseIcons.LogOut />
          </button>
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  );
}
