import {
  PopoverAnchor,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import curetDown from '@/Assets/Icons/curetDownWiti.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { ICurrency } from '@/types/apiTypes';
import { useAdvancedWalletStore } from '../../store';
import { AdvancedTokenSelect } from '../AdvancedTokenSelect';
import './styles.css';

interface TokenFromInputProps {
  rootProps?: PopoverProps;
  menuProps?: PopoverContentProps;
  className?: string;
}

export function TokenFromInput({
  rootProps,
  menuProps,
  className,
}: TokenFromInputProps) {
  const [currencyFromId, setCurrencyFrom] = useAdvancedWalletStore(s => [
    s.currencyFrom,
    s.setCurrencyFrom,
  ]);
  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeCurrency = (currency: ICurrency | undefined) => {
    if (!currency) return;
    setCurrencyFrom(currency);
  };

  useEffect(() => {
    let animationFrameId: number;

    const handleInput = () => {
      if (inputRef.current) {
        const inputText = inputRef.current.value;
        const textWidth = getTextWidth(inputText, inputRef.current);

        if (textWidth !== inputWidth) {
          animationFrameId = requestAnimationFrame(() => {
            setInputWidth(textWidth);
          });
        }
      }
    };

    const inputElement = inputRef.current;
    inputElement?.addEventListener('input', handleInput);

    return () => {
      inputElement?.removeEventListener('input', handleInput);
      cancelAnimationFrame(animationFrameId);
    };
  }, [inputWidth]);

  const getTextWidth = (text: string, inputElement: HTMLInputElement) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const style = window.getComputedStyle(inputElement);
      context.font = `${style.fontSize} ${style.fontFamily}`;
      return context.measureText(text).width;
    }
    return 0;
  };

  return (
    <div className="advanced-token-from-input-wrapper">
      <p>Token</p>
      <AdvancedTokenSelect
        value={currencyFromId}
        onChange={handleChangeCurrency}
        contentProps={menuProps}
        rootProps={rootProps}
      >
        {currency => (
          <PopoverAnchor
            className={clsx('advanced-token-from-input', className)}
          >
            <div className="advanced-token-from-token">
              <div className="d-flex flex-diections-row  my-auto">
                <div className="w-100 d-flex flex-direction-row align-items-center flex-nowrap">
                  <PopoverTrigger asChild className="w-100">
                    <div className="advanced-selected-network-trigger-wrapper">
                      <button
                        key={'currency-' + currency?.id}
                        className={'advanced-selected-network'}
                      >
                        <div className="d-flex advanced-selected-network-icon border-0 p-1">
                          <img
                            src={
                              currency?.image_url
                                ? BASE_URL + currency?.image_url
                                : ''
                            }
                            alt="token"
                            className={`${
                              currency?.id === currencyFromId && 'ms1'
                            }`}
                          />
                        </div>
                        <div className="mx-1 networkSelectBtn-text">
                          {currency?.symbol}
                        </div>
                      </button>

                      <div className="rounded-circle d-flex">
                        <img
                          width="11"
                          height="8"
                          src={curetDown}
                          alt="open"
                          className="btnIcon"
                        />
                      </div>
                    </div>
                  </PopoverTrigger>
                </div>
              </div>
            </div>
          </PopoverAnchor>
        )}
      </AdvancedTokenSelect>
    </div>
  );
}
