import { Adventure } from '..';
import nftImage from '@/Assets/images/journeys/taiko/nftImage.png';
import { ROUTES } from '@/constants/routes.constants';
import { Phase } from '@/types/phases';

export const taikoAdventure: Adventure = {
  phase: Phase.TaikoJourney,

  anchorClassName: 'taiko',

  nftImage,
  heading: <>Taiko Adventure</>,
  startDate: '2024-06-27T12:00:00.000Z',
  endDate: '2024-07-04T12:00:00.000Z',
  link: ROUTES.PHASE_TAIKO_ADVENTURE_PAGE,
  linkText: 'Take Taiko Adventure',
};
