import { DocumentWithContentIcon } from '@/Assets/Icons/DocumentWithContent';
import CloseIcon from '@/Assets/Icons/close-icon.svg';
import { maxPlatformDecimals } from '@/constants/numbers';
import { isValidAddress } from '@/evm/utils/isValidAddress';
import { NetworkTypes } from '@/providers/web3Provider';
import { isValidStarknetAddress } from '@/starknet/utils/isValidAddress';
import { useNetworksStore } from '@/stores/networks.store';
import { isValidTonAddress } from '@/ton/utils';
import { isValidSolAddress } from '@/solana/utils/isValidSolanaAddress';
import { toFixed } from '@/utils/numbers';
import { chunk } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import SimpleBar from 'simplebar-react';
import {
  MAX_BULK_RECIPIENTS,
  advancedWalletStore,
  useAdvancedWalletStore,
} from '../../store';
import { TokenChangeInput } from '../TokenChangeInput';
import { FillInWithTextError } from './Error';
import './styles.css';

export function FillInWithTextDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [lines, setLines] = useState(1);
  const [error, setError] = useState<{
    line: number;
    message: string;
  } | null>(null);
  const [setReceivingData] = useAdvancedWalletStore(s => [s.setReceivingData]);
  const networks = useNetworksStore(s => s.networks);

  const formatValue = (value: string) => {
    const chunks = chunk(value.split(';'), 3);

    return chunks
      .map(chunk =>
        chunk
          .map(el => el.trim())
          .join('; ')
          .trim()
      )
      .join(';\n');
  };

  const handleValueChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setError(null);

    setValue(value);

    e.target.style.width = 'auto';
    e.target.style.width = `${e.target.scrollWidth}px`;
  };

  const isAddressValid = useCallback(
    (address: string, networkType?: NetworkTypes) => {
      switch (networkType) {
        case NetworkTypes.STARKNET:
          return isValidStarknetAddress(address) as boolean;
        case NetworkTypes.TON:
          return isValidTonAddress(address);
        case NetworkTypes.SOLANA:
          return isValidSolAddress(address);
        default:
          return isValidAddress(address);
      }
    },
    []
  );

  const handleSubmit = () => {
    const data: {
      address: string;
      amount: string;
      network: string;
    }[] = [];
    const { networkFrom, receiving } = advancedWalletStore.getState();
    let tempValue = value.trim().replaceAll(/\n/g, '');

    if (tempValue.endsWith(';')) {
      tempValue = tempValue.slice(0, -1);
    }

    const chunks = chunk(tempValue.split(';'), 3);

    for (let j = 0; j < chunks.length; j++) {
      const line = (j % Math.max(1, lines)) + 1;

      const [address, amount, networkName] = chunks[j];
      const parsedAmount = parseFloat(
        toFixed(+amount?.replace(',', '.'), maxPlatformDecimals)
      );

      const network = networks.find(
        net => net.name.toLowerCase() === networkName?.trim()?.toLowerCase()
      );

      const isAddressInvalid = !isAddressValid(
        address?.trim(),
        network?.network_type
      );
      const isAmountInvalid = isNaN(parsedAmount) || parsedAmount <= 0;

      if (isAddressInvalid && isAmountInvalid) {
        setError({
          line,
          message: `<span>${address}</span> is an invalid wallet address and wrong amount. E.g.: address, number`,
        });
        return;
      }

      if (isAddressInvalid) {
        setError({
          line,
          message: `<span>${address}</span> is an invalid wallet address`,
        });
        return;
      }

      if (isAmountInvalid) {
        setError({
          line,
          message: amount
            ? `<span>${amount}</span> is an invalid amount`
            : 'Amount is required',
        });
        return;
      }

      if (!network) {
        setError({
          line,
          message: `<span>${networkName}</span> is an invalid network name`,
        });
        return;
      }

      if (network.id === networkFrom?.id) {
        setError({
          line,
          message:
            'Destination network cannot be the same as the source network',
        });
        return;
      }

      data.push({
        address: address?.trim(),
        amount: toFixed(parsedAmount, maxPlatformDecimals),
        network: networkName?.trim(),
      });
    }

    if (data.length > MAX_BULK_RECIPIENTS) {
      setError({
        line: 1,
        message: `You can send to a maximum of ${MAX_BULK_RECIPIENTS} recipients at once`,
      });
      return;
    }

    const filteredNetworks = networks.filter(
      network => network.id !== networkFrom?.id
    );

    let offset = 0;
    for (let i = 0; i < data.length; i++) {
      const networkId = filteredNetworks.find(
        net => net.name.toLowerCase() === data[i].network.toLowerCase()
      )?.id;

      if (!networkId) continue;

      if (!receiving[i + offset]?.address && !receiving[i + offset]?.amount) {
        setReceivingData(i + offset, {
          address: data[i].address,
          amount: data[i].amount,
          network: networkId,
        });
      } else {
        offset++;
        i--;
      }
    }

    handleOpenChange(false);
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);

    if (!open) {
      setValue('');
      setLines(1);
      setError(null);
    }
  };

  return (
    <>
      <button
        onClick={() => handleOpenChange(true)}
        className="fillInDialog-trigger"
      >
        <DocumentWithContentIcon />
        Fill in with text
      </button>
      <Modal
        show={isOpen}
        onHide={() => handleOpenChange(false)}
        className="absoluteCenter"
        dialogClassName="fillInDialog-modal"
      >
        <Modal.Body className="fillInDialog-modalBody">
          <div
            className="connectModalCloseBtn"
            onClick={() => handleOpenChange(false)}
          >
            <img
              className="notification-img"
              style={{ width: 20 }}
              alt=""
              src={CloseIcon}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <h2 className="fillInDialog-heading">
              <DocumentWithContentIcon width={24} height={24} color="#ffffff" />
              Fill in with text
            </h2>
          </div>
          <div className="fillInDialog-token-change-wrapper">
            <TokenChangeInput
              className="fillInDialog-token-change"
              menuProps={{
                align: 'start',
              }}
              rootProps={{
                modal: true,
              }}
            />
          </div>
          <div className="text-white bold network-label">
            Enter Receiving Addresses:
          </div>
          <label className="fillInDialog-textarea-container">
            <SimpleBar
              style={{
                maxHeight: 173,
                paddingRight: '16px',
                height: 173,
              }}
            >
              <div className="fillInDialog-textarea">
                <div className="d-flex flex-column align-items-end">
                  {Array(lines)
                    .fill(0)
                    .map((_, i) => (
                      <span
                        key={i}
                        className="fillInDialog-textarea-line-number"
                      >
                        {i + 1}
                      </span>
                    ))}
                </div>
                <TextareaAutosize
                  className="fillInDialog-textarea-content"
                  placeholder="Insert address, amount and network, separate with semicolon "
                  value={value}
                  onChange={handleValueChange}
                  onHeightChange={(height, { rowHeight }) => {
                    const newLines = Math.floor(height / rowHeight);
                    setLines(newLines);
                  }}
                  onBlur={e => {
                    setValue(formatValue(value));

                    setTimeout(() => {
                      e.target.style.width = 'auto';
                      e.target.style.width = `${e.target.scrollWidth}px`;
                    }, 0);
                  }}
                />
              </div>
            </SimpleBar>
          </label>
          <FillInWithTextError>
            {error && (
              <div
                className="d-flex flex-wrap"
                dangerouslySetInnerHTML={{
                  __html: `Line ${error?.line}: ${error?.message}`,
                }}
              />
            )}
          </FillInWithTextError>
          <div className="fillInDialog-actions">
            <button className="btn btnGradient" onClick={handleSubmit}>
              Submit
            </button>
            <button
              onClick={() => handleOpenChange(false)}
              className="fillInDialog-cancel"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
