import {
  PHASE_ADVENTURES_PAGE,
  PHASE_BASE_ADVENTURE_PAGE,
  PHASE_LINEA_ADVENTURE_PAGE,
  PHASE_MULTICHAIN_ADVENTURE_PAGE,
  PHASE_ZKSYNC_ADVENTURE_PAGE,
} from './routes.constants';

interface Redirect {
  from: string;
  to: string;
}

export const redirects: Redirect[] = [
  {
    from: '/retro-adventures',
    to: PHASE_ADVENTURES_PAGE,
  },
  {
    from: '/phases/linea-adventure',
    to: PHASE_LINEA_ADVENTURE_PAGE,
  },
  {
    from: '/phases/zksync-adventure',
    to: PHASE_ZKSYNC_ADVENTURE_PAGE,
  },
  {
    from: '/phases/multichain-adventure',
    to: PHASE_MULTICHAIN_ADVENTURE_PAGE,
  },
  {
    from: '/phases/base-adventure',
    to: PHASE_BASE_ADVENTURE_PAGE,
  },
];
