import { CloseIcon } from '@/Assets/Icons/Close';
import {
  FormStep,
  useAdvancedWalletStore,
} from '@/pages/SendAdvancedPage/store';
import { NetworkTypes } from '@/providers/web3Provider';
import { useAppStore } from '@/stores/app.store';
import { useNetworksStore } from '@/stores/networks.store';
import { logInGroup } from '@/utils';
import {
  DialogClose,
  DialogContent,
  DialogPortal,
  Root,
} from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useScrollLock } from 'usehooks-ts';
import { mainnet } from 'viem/chains';
import {
  useChainId as useEVMNetwork,
  useSwitchChain as useEVMSwitchNetwork,
} from 'wagmi';
import { useShallow } from 'zustand/react/shallow';
import background from './assets/bg.png';
import dialogIcon from './assets/dialogIcon.png';
import './styles.css';

interface UnsupportedChainDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export function UnsupportedChainDialog({
  isOpen,
  onOpenChange,
}: UnsupportedChainDialogProps) {
  const chainId = useEVMNetwork();
  const { switchChainAsync } = useEVMSwitchNetwork();
  const networks = useNetworksStore(s => s.networks);
  const [isAdvancedOpen, networkFrom, setNetworkFrom, setIsConfirmOpen] =
    useAppStore(
      useShallow(s => [
        s.isAdvancedOpen,
        s.networkFrom,
        s.setNetworkFrom,
        s.setIsConfirmOpen,
      ])
    );
  const [advancedNetworkFrom, setAdvancedNetworkFrom, setFormStep] =
    useAdvancedWalletStore(
      useShallow(s => [s.networkFrom, s.setNetworkFrom, s.setFormStep])
    );
  const { lock, unlock } = useScrollLock({
    autoLock: false,
  });

  useEffect(() => {
    if (isOpen) {
      lock();
    }
  }, [isOpen]);

  const handleSwitchChain = async () => {
    try {
      const defaultNetwork =
        networks.find(network => network.chainId === '1') || networks[0];

      if (isAdvancedOpen) {
        setAdvancedNetworkFrom(defaultNetwork);
        setFormStep(FormStep.Input);
      } else {
        setNetworkFrom(defaultNetwork);
        setIsConfirmOpen(false);
      }

      const networkType = isAdvancedOpen
        ? advancedNetworkFrom?.network_type
        : networkFrom?.network_type;

      switch (networkType) {
        case NetworkTypes.EVM:
        case NetworkTypes.ZK_SYNC_ERA:
          if (chainId !== mainnet.id)
            switchChainAsync?.({
              chainId: mainnet.id,
            });
          break;
      }
    } catch (error) {
      logInGroup('[UnsupportedChainDialog] handleSwitchChain', error);
    }
  };

  return (
    <Root open={isOpen} onOpenChange={onOpenChange}>
      <AnimatePresence
        onExitComplete={() => {
          unlock();
        }}
      >
        {isOpen && (
          <DialogPortal forceMount>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="unsupported-chain-dialog--overlay"
            >
              <DialogContent asChild>
                <motion.div className="unsupported-chain-dialog">
                  <div className="unsupported-chain-dialog--bg">
                    <img src={background} alt="" draggable={false} />
                  </div>
                  <DialogClose className="unsupported-chain-dialog--close">
                    <CloseIcon />
                  </DialogClose>
                  <div className="position-relative d-flex flex-column align-items-center gap-4 z-1">
                    <img
                      src={dialogIcon}
                      className="unsupported-chain-dialog--icon"
                      draggable={false}
                      alt=""
                    />
                    <h2 className="unsupported-chain-dialog--heading">
                      Network Switching Issue
                    </h2>
                    <div className="unsupported-chain-dialog--text">
                      Your connected wallet does not support the selected
                      blockchain network
                    </div>
                    <button
                      onClick={handleSwitchChain}
                      className="unsupported-chain-dialog--action btnGradient"
                    >
                      Switch Network
                    </button>
                    <div className="unsupported-chain-dialog--hint">
                      Switch to a supported blockchain network to continue.
                    </div>
                  </div>
                </motion.div>
              </DialogContent>
            </motion.div>
          </DialogPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
