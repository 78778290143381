import CloseIcon from '@/Assets/Icons/close-icon.svg';
import { NotificationIcon } from '@/Assets/Icons/Notification';
import { NotificationOutlinedIcon } from '@/Assets/Icons/NotificationOutlined';
import { adventures } from '@/pages/PhaseAdventuresPage/config';
import { useAppStore } from '@/stores/app.store';
import {
  PopoverContent,
  PopoverPortal,
  Root as PopoverRoot,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import {
  TabsContent,
  TabsList,
  Root as TabsRoot,
  TabsTrigger,
} from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { useWindowSize } from 'usehooks-ts';
import './styles.css';
import { ActivitiesTab } from './tabs/ActivitiesTab';
import { LatestTab } from './tabs/LatestTab';
import { NewDestinationsTab } from './tabs/NewDestinationsTab';

export enum NotificationTab {
  Latest = 'Latest',
  Activities = 'Activities',
  NewDestinations = 'New Destinations',
}

export function NotificationsPopover() {
  const { width } = useWindowSize();
  const [selectedTab, setSelectedTab] = useState(NotificationTab.Latest);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdvancedOpen] = useAppStore(s => [s.isAdvancedOpen]);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (width && width <= 993) {
      handleOpenChange(false);
    } else {
      handleOpenChange(
        !isAdvancedOpen && !pathname.startsWith('/progress/advanced')
      );
    }
  }, [isAdvancedOpen, pathname, width]);

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);

    if (!isOpen) {
      setSelectedTab(NotificationTab.Latest);
    }
  };

  const activeAdventures = useMemo(() => {
    return adventures.filter(adventure => {
      const currentDate = new Date();
      const startDate = new Date(adventure.startDate);
      const endDate = new Date(adventure.endDate);

      return currentDate >= startDate && currentDate <= endDate;
    });
  }, []);

  return (
    <TabsRoot
      value={selectedTab}
      onValueChange={tab => setSelectedTab(tab as NotificationTab)}
    >
      <PopoverRoot open={isOpen} onOpenChange={handleOpenChange}>
        <PopoverTrigger className="notifications-menu--trigger">
          {isOpen ? <NotificationIcon /> : <NotificationOutlinedIcon />}
        </PopoverTrigger>
        <AnimatePresence initial={false}>
          {isOpen && (
            <PopoverPortal forceMount>
              <PopoverContent
                align="start"
                collisionPadding={18}
                sideOffset={47}
                asChild
                hideWhenDetached={width <= 993}
                onInteractOutside={e => {
                  e.preventDefault();

                  const walletPopovers =
                    document.querySelectorAll('.wallet-popover');

                  if (
                    e.target &&
                    Array.from(walletPopovers).some(el =>
                      el.contains(e.target as Node)
                    )
                  ) {
                    handleOpenChange(false);
                  }
                }}
                onOpenAutoFocus={e => e.preventDefault()}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  className="notifications-menu"
                >
                  <button
                    className="notifications-menu--close"
                    onClick={() => handleOpenChange(false)}
                  >
                    <img src={CloseIcon} width={16} height={16} alt="" />
                  </button>
                  <SimpleBar
                    style={{
                      maxHeight: 'calc(100% - 36px)',
                      outline: 'none',
                    }}
                  >
                    <TabsList className="notifications-menu--tabs">
                      <TabsTrigger
                        className="notifications-menu--tabs-trigger"
                        value={NotificationTab.Latest}
                      >
                        {NotificationTab.Latest}
                        <span className="notifications-menu--tabs-trigger--counter">
                          {4 +
                            (activeAdventures.length <= 0
                              ? 1
                              : activeAdventures.length)}
                        </span>
                      </TabsTrigger>
                      <TabsTrigger
                        className="notifications-menu--tabs-trigger"
                        value={NotificationTab.Activities}
                      >
                        {NotificationTab.Activities}
                      </TabsTrigger>
                      <TabsTrigger
                        className="notifications-menu--tabs-trigger"
                        value={NotificationTab.NewDestinations}
                      >
                        {NotificationTab.NewDestinations}
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value={NotificationTab.Latest}>
                      <LatestTab />
                    </TabsContent>
                    <TabsContent value={NotificationTab.Activities}>
                      <ActivitiesTab />
                    </TabsContent>
                    <TabsContent value={NotificationTab.NewDestinations}>
                      <NewDestinationsTab />
                    </TabsContent>
                  </SimpleBar>
                </motion.div>
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </PopoverRoot>
    </TabsRoot>
  );
}
