import { RefObject, useEffect, useRef } from 'react';
import { stage } from '../Config/ApiConfig';

export const shortAddress = (address: string, chars = 4) => {
  return `${address.substring(0, chars)}...${address.substring(
    address.length - 3
  )}`;
};

export function useOnClickOutside(
  node: RefObject<HTMLDivElement>,
  handler?: () => void
) {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleClickOutside = (e: globalThis.MouseEvent) => {
      if (node.current?.contains(e.target as Node | null) ?? false) {
        return;
      }
      if (handlerRef.current) handlerRef.current();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [node]);
}

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const isEth = (symbol: string) => symbol === 'ETH' || symbol === 'WETH';

export const logInGroup = (group: string, ...args: unknown[]) => {
  if (stage === 'production') return;

  console.groupCollapsed(group);
  args.forEach(arg => console.log(arg));
  console.groupEnd();
};
