import { Adventure } from '..';
import nftImage from '@/Assets/images/journeys/blast/nftImage.png';
import { ROUTES } from '@/constants/routes.constants';
import { Phase } from '@/types/phases';

export const blastAdventure: Adventure = {
  phase: Phase.BlastAdventure,

  anchorClassName: 'blast',

  nftImage,
  heading: <>Blast Adventure</>,
  startDate: '2024-06-20T14:30:00.000Z',
  endDate: '2024-06-25T14:30:00.000Z',
  link: ROUTES.PHASE_BLAST_ADVENTURE_PAGE,
  linkText: 'Take Blast Adventure',
};
