import { Navigate, Route } from 'react-router-dom';
import {
  ADVANCED_PROGRESS_PAGE,
  DAPP_EXPLORER_PAGE,
  DEV_API_PAGE,
  EXPLORER_PAGE,
  FAQ_PAGE,
  HOME_PAGE,
  PHASES_PAGE,
  PHASE_ADVENTURES_PAGE,
  PHASE_BASE_ADVENTURE_PAGE,
  PHASE_BLAST_ADVENTURE_PAGE,
  PHASE_JOURNEYS_PAGE,
  PHASE_LINEA_ADVENTURE_PAGE,
  PHASE_MULTICHAIN_ADVENTURE_PAGE,
  PHASE_SUNRISE_PAGE,
  PHASE_SCROLL_ADVENTURE_PAGE,
  PHASE_TAIKO_ADVENTURE_PAGE,
  PHASE_ZKSYNC_ADVENTURE_PAGE,
  PRIVACY_POLICY_PAGE,
  PROGRESS_PAGE,
  SEND_PAGE,
  PHASE_MIDDAY_PAGE,
  PHASE_TON_ADVENTURE_PAGE,
} from '../constants/routes.constants';
import ExplorerPage from '../pages/ExplorerPage';
import { PhaseJourneysPage } from '../pages/PhaseJourneysPage';
import { stage } from '../Config/ApiConfig';
import { redirects } from '../constants/routes.redirects';
import ProgressPage from '../pages/ProgressPage';
import SendPage from '../pages/SendPage';
import { Phase } from '../types/phases';
import { lazy } from 'react';
import { isOneDomain } from '@/Config';

const MainPage = lazy(() => import('@/pages/Main'));
const DappExplorer = lazy(() => import('@/pages/DappExplorerPage'));
const DevelopersApiPage = lazy(() => import('@/pages/DevelopersApiPage'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'));
const FAQPage = lazy(() => import('@/pages/FAQ'));
const PhasesPage = lazy(() => import('@/pages/PhasesPage'));
const PhasePage = lazy(() => import('@/pages/PhasePage'));
const PhaseAdventuresPage = lazy(() => import('@/pages/PhaseAdventuresPage'));

export const explorerRoutes = [
  'explorer.retrobridge.io',
  'explorer.retrobridge.dev',
];
export const bridgeRoutes = ['app.retrobridge.io', 'app.retrobridge.dev'];
export const landingRoutes = ['retrobridge.io', 'retrobridge.dev'];

export const getRoutes = () => {
  if (explorerRoutes.includes(window.location.host)) {
    return <Route path={HOME_PAGE} element={<ExplorerPage />} />;
  }

  if (bridgeRoutes.includes(window.location.host)) {
    return (
      <>
        <Route path={HOME_PAGE} element={<SendPage />} />
        <Route path={`${PROGRESS_PAGE}/:orderId`} element={<ProgressPage />} />
        <Route
          path={`${ADVANCED_PROGRESS_PAGE}/:orderId`}
          element={<ProgressPage isAdvancedTransfer />}
        />
      </>
    );
  }

  return (
    <>
      {(stage === 'development' || isOneDomain) && (
        <>
          <Route index path={SEND_PAGE} element={<SendPage />} />
          <Route path={EXPLORER_PAGE} element={<ExplorerPage />} />
          <Route
            path={`${PROGRESS_PAGE}/:orderId`}
            element={<ProgressPage />}
          />
          <Route
            path={`${ADVANCED_PROGRESS_PAGE}/:orderId`}
            element={<ProgressPage isAdvancedTransfer />}
          />
        </>
      )}

      <Route index path={HOME_PAGE} Component={MainPage} />

      <Route path={DAPP_EXPLORER_PAGE} Component={DappExplorer} />

      <Route path={DEV_API_PAGE} Component={DevelopersApiPage} />

      <Route path={PRIVACY_POLICY_PAGE} Component={PrivacyPolicy} />

      <Route path={FAQ_PAGE} Component={FAQPage} />

      <Route path={PHASES_PAGE} Component={PhasesPage} />

      <Route path={PHASE_SUNRISE_PAGE} element={<PhasePage />} />
      <Route
        path={PHASE_MIDDAY_PAGE}
        element={<PhasePage initialPhase={Phase.Midday} />}
      />
      <Route path={PHASE_JOURNEYS_PAGE} element={<PhaseJourneysPage />} />
      <Route path={PHASE_ADVENTURES_PAGE} Component={PhaseAdventuresPage} />
      <Route
        path={PHASE_LINEA_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.LineaJourney} />}
      />
      <Route
        path={PHASE_ZKSYNC_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.ZkSyncJourney} />}
      />
      <Route
        path={PHASE_MULTICHAIN_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.MultiChainJourney} />}
      />
      <Route
        path={PHASE_BASE_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.BaseJourney} />}
      />
      <Route
        path={PHASE_SCROLL_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.ScrollJourney} />}
      />
      <Route
        path={PHASE_BLAST_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.BlastAdventure} />}
      />
      <Route
        path={PHASE_TAIKO_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.TaikoJourney} />}
      />
      <Route
        path={PHASE_TON_ADVENTURE_PAGE}
        element={<PhasePage initialPhase={Phase.TonJourney} />}
      />

      {redirects.map(({ from, to }) => (
        <Route key={from} path={from} element={<Navigate to={to} />} />
      ))}
    </>
  );
};
