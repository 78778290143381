import external from '@/Assets/Icons/external.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { maxPlatformDecimals } from '@/constants/numbers';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { IBulkOrder, ICurrency } from '@/types/apiTypes';
import { BulkOrderStatuses } from '@/types/enums';
import { shortAddress } from '@/utils';
import { toFixed } from '@/utils/numbers';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';

interface OrderItemProps {
  order: IBulkOrder['bulk_order_schedules'][0];
  currencyIn: ICurrency;
  walletSender: string;
  i: number;
}

export function ProgressTransactionItem({
  order,
  currencyIn,
  walletSender,
  i,
}: OrderItemProps) {
  const { prices } = usePriceUpdater();
  const { width } = useWindowSize();
  const isMobile = width < 746;

  const usdPrice = useMemo(() => {
    if (!order.currency_out.symbol) {
      return 0;
    }

    return getPrice(order.currency_out.symbol, prices).usd;
  }, [order.currency_out.symbol, prices]);

  const status = useMemo(() => {
    switch (order.status) {
      case BulkOrderStatuses.COMPLETE:
        return 'completed';
      case BulkOrderStatuses.DEPOSITED:
      case BulkOrderStatuses.PENDING:
      case BulkOrderStatuses.WAIT_DEPOSIT:
      case BulkOrderStatuses.PROCESSING:
        return 'awaiting';
      default:
        return 'delayed';
    }
  }, [order.status]);

  if (isMobile) {
    return (
      <div className="advanced-transfer-table--item-wrapper">
        <div
          className="advanced-transfer-table--item advanced-transfer-progress-table-grid--item"
          key={order.id}
        >
          <div
            className="advanced-transfer--item-no self-center"
            style={{ margin: 0 }}
          >
            {i + 1}
          </div>

          <div className="advanced-transfer-table--item-block self-center">
            <div className="infoText secondary-text-weight">{`${shortAddress(
              order.wallet_receiver
            )}`}</div>
          </div>

          <div className="advanced-transfer-table--item-block self-center">
            <div className={clsx('advanced-transfer--item-tag', status)}>
              {status}
            </div>
          </div>

          {order?.order?.transaction_out_hash && status === 'completed' && (
            <a
              href={
                order.currency_out.contract.network.blockscan_url +
                'tx/' +
                order?.order?.transaction_out_hash
              }
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open in explorer"
              className="advanced-transfer--item-external"
            >
              <img src={external} className="external" alt="external" />
            </a>
          )}
        </div>

        <div className="advanced-transfer-table--item advanced-transfer-progress-table-grid--item">
          <div />

          <div className="advanced-transfer-table--item-block self-center">
            <div className="networkInfoLeftDiv">
              <div className="imgDiv20">
                <img
                  className="coinImg"
                  src={
                    BASE_URL +
                    order.currency_out.contract.network.network_image_url
                  }
                  alt="network icon"
                />
              </div>

              <div className="d-flex flex-column ms-2">
                <div className="infoText">
                  {order.currency_out.contract.network.name}
                </div>
              </div>
            </div>
          </div>

          <div className="advanced-transfer-table--item-block self-center">
            <div className="d-flex flex-column progress-info-block-price-div">
              <div className="infoText w-100 mw-100">{`${toFixed(
                +order.amount_out,
                maxPlatformDecimals
              )} ${order.currency_out.symbol}`}</div>
              <div className="infoText secondarytext infoText-bold">{`${toFixed(
                +order.amount_out * usdPrice,
                2
              )} $`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="advanced-transfer-table--item" key={order.id}>
        <div className="advanced-transfer--item-no">{i + 1}</div>
        <div className="networkInfoLeftDiv">
          <div className="imgDiv20">
            <img
              className="coinImg"
              src={BASE_URL + currencyIn.contract.network.network_image_url}
              alt="network icon"
            />
          </div>

          <div className="d-flex flex-column ms-2">
            <div className="infoText">{currencyIn.contract.network.name}</div>
            <div className="infoText secondarytext secondary-text-weight">{`${shortAddress(
              walletSender
            )}`}</div>
          </div>
        </div>
        <div className="networkInfoLeftDiv">
          <div className="imgDiv20">
            <img
              className="coinImg"
              src={
                BASE_URL + order.currency_out.contract.network.network_image_url
              }
              alt="network icon"
            />
          </div>

          <div className="d-flex flex-column ms-2">
            <div className="infoText">
              {order.currency_out.contract.network.name}
            </div>
            <div className="infoText secondarytext secondary-text-weight">{`${shortAddress(
              order.wallet_receiver
            )}`}</div>
          </div>
        </div>
        <div className={clsx('advanced-transfer--item-tag', status)}>
          {status}
        </div>
        <div className="d-flex flex-column progress-info-block-price-div">
          <div className="infoText text-end">{`${toFixed(
            +order.amount_out,
            maxPlatformDecimals
          )} ${order.currency_out.symbol}`}</div>
          <div className="infoText secondarytext infoText-bold text-end">{`${toFixed(
            +order.amount_out * usdPrice,
            2
          )} $`}</div>
        </div>
        {order?.order?.transaction_out_hash && status === 'completed' && (
          <a
            href={
              order.currency_out.contract.network.blockscan_url +
              'tx/' +
              order?.order?.transaction_out_hash
            }
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Open in explorer"
            className="advanced-transfer--item-external"
          >
            <img src={external} className="external" alt="external" />
          </a>
        )}
      </div>
    );
  }
}
