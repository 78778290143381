import { INetwork } from '../types/apiTypes';

type ILocalNetwork = Pick<
  INetwork,
  'id' | 'name' | 'chainId' | 'network_image_url' | 'category' | 'tag'
> & {
  order_on_main_page?: number;
  is_coming_soon?: boolean;
  is_testnet?: boolean;

  is_new_destination?: boolean;
  new_destination_bg?: string;
  is_latest_new_destination?: boolean;
};

export const mainnetNetworks: ILocalNetwork[] = [
  {
    id: 'e8a1ff3d-0391-4214-9f0e-e6b691c9a11d',
    name: 'Ethereum',
    chainId: '1',
    network_image_url: '/static/networks/ethereum.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 0,
  },
  {
    id: '7f16e773-ac07-447f-9af0-f3b7f244d24b',
    name: 'Polygon',
    chainId: '137',
    network_image_url: '/static/networks/polygon.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 1,
  },
  {
    id: '9da2c35c-7b75-450b-97a1-3191a71f6c63',
    name: 'Arbitrum One',
    chainId: '42161',
    network_image_url: '/static/networks/arbitrum.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 3,
  },
  {
    id: 'f51b2b7c-d09c-4502-b72b-86f069a74d92',
    name: 'Optimism',
    chainId: '10',
    network_image_url: '/static/networks/optimism.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 9,
  },
  {
    id: '0a2541ef-83ac-48f1-af59-ebf1d438a61d',
    name: 'zkSync Era',
    chainId: '324',
    network_image_url: '/static/networks/zkSyncEra.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 5,
  },
  {
    id: '7142f22b-221c-4d7d-b636-d5f23638b753',
    name: 'Starknet',
    chainId: '0x534e5f4d41494e',
    network_image_url: '/static/networks/starkNet.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 7,
  },
  {
    id: '26ef6cb3-f7d8-42fe-a9e4-f7bcfbddb5c3',
    name: 'Base',
    chainId: '8453',
    network_image_url: '/static/networks/base.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 10,
  },
  {
    id: '2d7e8bbc-193e-4ed5-a512-7cd49c1d27d9',
    name: 'Linea',
    chainId: '59144',
    network_image_url: '/static/networks/linea.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 7,
  },
  {
    id: 'e6fc724a-6cff-4510-8276-ffcf21f97b03',
    name: 'BNB Chain',
    chainId: '56',
    network_image_url: '/static/networks/binance.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 2,
  },
  {
    id: '226f910f-2108-492d-9c5d-63dc75c6796b',
    name: 'Avalanche',
    chainId: '43114',
    network_image_url: '/static/networks/avalanche.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 8,
  },
  {
    id: '51748ee9-43dd-49f7-9c4c-7410c6e9c5ba',
    name: 'Arbitrum Nova',
    chainId: '42170',
    network_image_url: '/static/networks/arbitrumNova.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 4,
  },
  {
    id: 'b7db1b45-6e3c-4623-b6d7-af31e4bad8eb',
    name: 'Polygon zkEVM',
    chainId: '1101',
    network_image_url: '/static/networks/polygonZkEVM.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 6,
  },
  {
    id: 'f143deba-ca33-4f9d-be86-c9dfde17dbb9',
    name: 'Scroll',
    chainId: '534352',
    network_image_url: '/static/networks/scroll_network.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 11,
  },
  {
    id: 'c2ac0244-3e25-42bb-9677-cd8c3746273b',
    name: 'Zora',
    chainId: '7777777',
    network_image_url: '/static/networks/zora.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 12,
  },
  {
    id: '2de8dc80-f117-4dc9-9b95-19fb3f60b2d8',
    name: 'Manta Pacific',
    chainId: '169',
    network_image_url: '/static/networks/mantaPacific.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 13,
  },
  {
    id: '15ca069c-cc8e-45a9-8991-03cf84ea9de5',
    name: 'Mode',
    chainId: '34443',
    network_image_url: '/static/networks/mode.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 14,
  },
  {
    id: '2cb23b31-d5a5-4b21-8f77-7b65c4561b92',
    name: 'Kroma',
    chainId: '255',
    network_image_url: '/static/networks/kroma.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 15,
  },
  {
    id: 'd2da2429-21f6-4f3c-9979-2f5f967654b4',
    name: 'Mantle',
    chainId: '5000',
    network_image_url: '/static/networks/mantle.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 14,
  },
  {
    id: '531a1820-1852-4b22-8662-1ad9eb1a4443',
    name: 'Zeta Chain',
    chainId: '7000',
    network_image_url: '/static/networks/zeta_chain.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 13,
  },
  {
    id: '2f4af8d9-81fd-4f4a-b428-dfeca99eb160',
    name: 'Blast',
    chainId: '81457',
    network_image_url: '/static/networks/blast.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 15,
  },
  {
    id: '5a2ac0fc-41a3-4fe2-a3cc-8ff1e808d96b',
    name: 'zkLink Nova',
    chainId: '810180',
    network_image_url: '/static/networks/zkLinkNova.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 16,
  },
  {
    id: 'e3e9b7bf-3d7f-4c15-8188-eef9dbc97c42',
    name: 'Rari Chain',
    chainId: '1380012617',
    network_image_url: '/static/networks/rari_chain.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 19,
  },
  {
    id: '19c9a829-f2f0-4974-8ef9-7f6d8f126f1a',
    name: 'X Layer',
    chainId: '196',
    network_image_url: '/static/networks/x_layer.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 20,
  },
  {
    id: 'd93e91e0-a457-482a-b7a4-22f91c9cc656',
    name: 'Taiko',
    chainId: '167000',
    network_image_url: '/static/networks/taiko.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 17,
  },
  {
    id: '3c213de8-7564-4f5c-a2ed-e346e817e1c0',
    name: 'TON',
    chainId: '0',
    network_image_url: '/static/networks/ton.svg',
    category: 'Innovative Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 23,

    is_new_destination: true,
    is_latest_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/tonBannerBg.png'),
  },
  {
    id: 'b666ac9a-ecc7-4531-a403-746f5cf9ca7d',
    name: 'opBNB',
    chainId: '204',
    network_image_url: '/static/networks/binance.svg',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 21,
  },
  {
    id: 'b08b886e-b215-431a-9192-ad3b26c23122',
    name: 'Mint Chain',
    chainId: '185',
    network_image_url: '/static/networks/mint_chain.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 22,

    is_new_destination: false,
    new_destination_bg: require('@/Assets/images/networks/mintBannerBg.png'),
  },
  {
    id: 'fca5bf9d-fc16-467f-9d59-03e41e79d8a5',
    name: 'Merlin',
    chainId: '4200',
    network_image_url: '/static/networks/merlin.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 24,

    is_new_destination: false,
    is_latest_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/merlinBannerBg.png'),
  },
  {
    id: 'a9cf1f67-340d-4caf-8801-ca65e56751c0',
    name: 'B² Network',
    chainId: '223',
    network_image_url: '/static/networks/bsquared.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 24,

    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/B2BannerBg.png'),
  },
  {
    id: '15839712-445a-4a67-8b55-71c235e4fd90',
    name: 'BOB',
    chainId: '60808',
    network_image_url: '/static/networks/bob.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 25,

    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/BOBBannerBg.png'),
  },
  {
    id: '0134dbf9-0390-4aca-98a7-db9912c10a10',
    name: 'Bitlayer',
    chainId: '200901',
    network_image_url: '/static/networks/bitlayer.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 26,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/BitlayerBannerBg.png'),
  },
  {
    id: 'db99e982-dc13-4302-bc16-8a882e223d25',
    name: 'Metis',
    chainId: '1088',
    network_image_url: '/static/networks/metis.png',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 27,
  },
  {
    id: 'b9881297-f8f7-4a92-a0de-5f474cb8e199',
    name: 'Solana',
    chainId: '900',
    network_image_url:
      window.location.origin + require('@/Assets/Icons/networks/solana.png'),
    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 28,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/SolanaBannerBg.png'),
  },
  {
    id: 'ac543856-8112-498d-ae46-a328072c6d34',
    name: 'Tron',
    chainId: '1000',
    network_image_url: '/static/networks/tron.png',
    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 29,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/TronBannerBg.png'),
  },
  {
    id: 'a23cd70f-6eb2-431f-9ce6-5d65e4b22263',
    name: 'Zircuit',
    chainId: '48900',
    network_image_url: '/static/networks/zircuit.png',
    category: 'L2 EVM Networks',
    tag: 'Innovative Networks',
    order_on_main_page: 30,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/zircuitBannerBg.png'),
  },
  {
    id: '26904570-3a50-457f-b849-83131bb25322',
    name: 'Gravity',
    chainId: '1625',
    network_image_url: '/static/networks/gravity.png',
    category: 'L1 EVM Networks',
    order_on_main_page: 31,
    tag: 'L1 EVM',
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/gravityBannerBg.png'),
  },
];

export const testnetNetworks: ILocalNetwork[] = [
  {
    id: 'c7d59050-6f3e-4b9c-a793-852c89859553',
    name: 'Goerli Testnet',
    chainId: '5',
    network_image_url: '/static/networks/ethereum.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: '839c3c7f-9d9a-4783-b1f1-02e258fe736b',
    name: 'Mumbai Testnet',
    chainId: '80001',
    network_image_url: '/static/networks/polygon.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: '0e6268d5-6757-47bf-bd04-217ff8d6974e',
    name: 'BNB Smart Chain Testnet',
    chainId: '97',
    network_image_url: '/static/networks/binance.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: '588dc52b-1b9e-47e8-b4c8-bcdc9a5e85ac',
    name: 'Sepolia Testnet',
    chainId: '11155111',
    network_image_url: '/static/networks/ethereum.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: 'e8599c5c-3176-421f-8e2a-77306bd30fff',
    name: 'Fantom Testnet',
    chainId: '4002',
    network_image_url: '/static/networks/fantom.webp',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '44a7bd7c-168a-42aa-8156-b5e27138ebb5',
    name: 'Scroll Sepolia Testnet',
    chainId: '534351',
    network_image_url: '/static/networks/scroll_network.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '2d7e8bbc-193e-4ed5-a512-7cd49c1d27d9',
    name: 'Linea Testnet',
    chainId: '59140',
    network_image_url: '/static/networks/linea.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '355ff90f-ba61-41fa-900a-67e54d00a2f7',
    name: 'zkSync Era Testnet',
    chainId: '280',
    network_image_url: '/static/networks/zkSyncEra.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: 'e2c334bf-e874-432d-a227-291d0784a32e',
    name: 'Starknet Testnet',
    chainId: '0x534e5f474f45524c49',
    network_image_url: '/static/networks/starkNet.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '30a9afc7-d927-4e17-acf2-16544ba8050d',
    name: 'Zora Testnet',
    chainId: '999999999',
    network_image_url: '/static/networks/zora.png',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '38e020c4-7e6e-4c3f-9a60-8d98612a706a',
    name: 'Manta Pacific Testnet',
    chainId: '3441005',
    network_image_url: '/static/networks/mantaPacific.png',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: 'ba9a69f3-5d24-4cc9-bc90-7ea0d88e8c5e',
    name: 'Rari Chain Testnet',
    chainId: '1918988905',
    network_image_url: '/static/networks/rari_chain.png',
    category: 'Innovative Networks',
    is_testnet: true,
  },
  {
    id: 'e27a839f-a668-4b88-82db-04b7d95cbea3',
    name: 'TON Testnet',
    chainId: '0',
    network_image_url: '/static/networks/ton.svg',
    category: 'Innovative Networks',
    is_testnet: true,

    new_destination_bg: require('@/Assets/images/networks/tonBannerBg.png'),
  },
  {
    id: '1aedcb46-1a3c-48b0-82c0-fb019d6eba89',
    name: 'Solana Testnet',
    chainId: '901',
    network_image_url: '/static/networks/solana.png',
    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    is_testnet: true,
  },
];
