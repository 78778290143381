import { stage, STAGES } from '../Config/ApiConfig';
import Cookies from 'universal-cookie';

const validDomain: Record<STAGES, string> = {
  development: 'retrobridge.on.fleek.co',
  staging: '.retrobridge.dev',
  production: '.retrobridge.io',
};

const cookies = new Cookies(null, {
  path: '/',
  domain: validDomain[stage],
});

export function setCookie(name: string, value: string, days = 365) {
  const domain = document.location.hostname.startsWith('localhost')
    ? 'localhost'
    : validDomain[stage];

  cookies.set(name, value, {
    path: '/',
    domain: domain,
    expires: new Date(Date.now() + days * 24 * 60 * 60 * 1000),
  });
}

export function getCookie(name: string) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
