import {
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { TriangleDown } from '@/Assets/Icons/TriangleDown';
import { BASE_URL } from '@/Config/ApiConfig';
import { ICurrency } from '@/types/apiTypes';
import './styles.css';

interface CurrencySelectProps {
  value?: ICurrency;
  onChange?: (currency: ICurrency) => void;
  options: ICurrency[];
}

export function CurrencySelect({
  value,
  onChange,
  options,
}: CurrencySelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="currency-select">
      <Root open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className="currency-select--trigger">
          {value?.image_url && (
            <div className="currency-select--image">
              <img
                src={BASE_URL + value?.image_url}
                alt={`${value?.symbol} favicon`}
              />
            </div>
          )}
          {value?.symbol || 'Select currency'}
          <TriangleDown />
        </PopoverTrigger>
        <AnimatePresence key={value?.id}>
          {isOpen && (
            <PopoverPortal forceMount>
              <PopoverContent
                align="end"
                sideOffset={27}
                alignOffset={-20}
                avoidCollisions={false}
                asChild
              >
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="currency-select--menu"
                >
                  {options.map(option => (
                    <PopoverClose
                      key={option.id}
                      className={clsx(
                        'currency-select--menu-item',
                        option.id === value?.id && 'active'
                      )}
                      onClick={() => onChange?.(option)}
                      disabled={!option.active}
                    >
                      <div className="currency-select--image">
                        <img
                          src={BASE_URL + option.image_url}
                          alt={`${option.symbol} favicon`}
                        />
                      </div>
                      {option.symbol}
                      {option.active && (
                        <div className="currency-select--menu-indicator" />
                      )}
                    </PopoverClose>
                  ))}
                </motion.div>
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </Root>
    </div>
  );
}
