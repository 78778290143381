import scrollBadge from '../assets/ScrolBadge.png';
import { OtherItem } from './OtherItem';
import './styles.css';

export function Other() {
  return (
    <div>
      <OtherItem
        backgroundSrc={require('../assets/ScrollBadgeBg.png')}
        href="https://scroll.io/canvas/badge-contract/0x59700c6Ed282eD7611943129f226914ACBB3982b"
        target="_blank"
      >
        <span className="notifications-menu--other-item--content">
          <strong>Scroll Canvas RetroBridger Badge</strong>
          <br />
          Bridge or swap to Scroll 0.05 ETH or 150$ in stables and get
          RetroBridger Canvas Badge
        </span>
        <div className="notifications-menu--other-item--image">
          <img src={scrollBadge} draggable={false} alt="" />
        </div>
      </OtherItem>
    </div>
  );
}
