import { Adventure } from '..';
import activeCardBg from '@/Assets/images/journeys/multiChain/adventureCardBg.png';
import nftImage from '@/Assets/images/journeys/multiChain/nftImage.png';
import { ROUTES } from '@/constants/routes.constants';
import { Phase } from '@/types/phases';

export const multiChainAdventure: Adventure = {
  phase: Phase.MultiChainJourney,

  nftImage,
  activeCardBg,
  heading: <>Multichain Adventure</>,
  startDate: '2024-05-07T10:00:00.000Z',
  endDate: '2024-05-22T10:00:00.000Z',
  link: ROUTES.PHASE_MULTICHAIN_ADVENTURE_PAGE,
  linkText: 'Take Multichain Adventure',
};
