import { AppModeSwitch } from '@/components/AppModeSwitch';
import { Loader } from '@/components/Loader';
import { LoaderSmall } from '@/components/LoaderSmall';
import { maxPlatformDecimals } from '@/constants/numbers';
import { isValidAddress } from '@/evm/utils/isValidAddress';
import { useBalance } from '@/hooks/useBalance';
import { ANIMATION_DURATION_S } from '@/pages/SendAdvancedPage';
import { NetworkTypes } from '@/providers/web3Provider';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { isValidStarknetAddress } from '@/starknet/utils/isValidAddress';
import { WalletToType, appStore, useAppStore } from '@/stores/app.store';
import { useNetworksStore } from '@/stores/networks.store';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { isValidTonAddress } from '@/ton/utils';
import { ICurrency, INetwork } from '@/types/apiTypes';
import { toFixed } from '@/utils/numbers';
import { useTonAddress } from '@tonconnect/ui-react';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAccount as useEVMAccount } from 'wagmi';
import { AmountInput } from '../AmountInput';
import { CurrencySelect } from '../CurrencySelect';
import { NetworkExchange } from '../NetworkExchange';
import { NetworkInput } from '../NetworkInput';
import { WalletButtons } from '../WalletButtons';
import './styles.css';
import { isValidSolAddress } from '@/solana/utils/isValidSolanaAddress';

function SendForm({
  openConfirm,
  setOpenModal,
}: {
  openConfirm: () => void;
  setOpenModal: (value: boolean) => void;
}) {
  const networks = useNetworksStore(s => s.networks);
  const [networkFrom, currencyFrom, amountFrom, sourceCurrencies] = useAppStore(
    s => [s.networkFrom, s.currencyFrom, s.amountFrom, s.sourceCurrencies]
  );

  const [
    setNetworkFrom,
    setCurrencyFrom,
    setAmountFrom,
    setWalletToType,
    setIsAdvancedOpen,
  ] = useAppStore(s => [
    s.setNetworkFrom,
    s.setCurrencyFrom,
    s.setAmountFrom,
    s.setWalletToType,
    s.setIsAdvancedOpen,
  ]);

  const [
    networkTo,
    currencyTo,
    amountTo,
    walletTo,
    destinationCurrencies,
    isLoading,
  ] = useAppStore(s => [
    s.networkTo,
    s.currencyTo,
    s.amountTo,
    s.walletTo,
    s.destinationCurrencies,
    s.isLoading,
  ]);

  const [setNetworkTo, setCurrencyTo, setAmountTo, setWalletTo] = useAppStore(
    s => [s.setNetworkTo, s.setCurrencyTo, s.setAmountTo, s.setWalletTo]
  );

  const [minSend, maxSend] = useAppStore(s => [s.minSend, s.maxSend]);

  const [error, setError] = useAppStore(s => [s.error, s.setError]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { address: evmAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const tonAddress = useTonAddress();
  const { publicKey: solanaAddress } = useSolanaWallet();

  const { balance: sourceBalance, loading: isSourceBalanceLoading } =
    useBalance(networkFrom, currencyFrom);
  const { balance: destinationBalance, loading: isDestinationBalanceLoading } =
    useBalance(networkTo, currencyTo);

  const isFromStarknet = networkFrom?.network_type === NetworkTypes.STARKNET;
  const isFromTon = networkFrom?.network_type === NetworkTypes.TON;
  const isFromSolana = networkFrom?.network_type === NetworkTypes.SOLANA;
  const isFromEVM = !isFromStarknet && !isFromTon && !isFromSolana;

  const isFromConnected =
    (isFromStarknet && !!starknetAddress) ||
    (isFromTon && !!tonAddress) ||
    (isFromSolana && !!solanaAddress) ||
    (isFromEVM && !!evmAddress);

  const isToStarknet = networkTo?.network_type === NetworkTypes.STARKNET;
  const isToTon = networkTo?.network_type === NetworkTypes.TON;
  const isToSolana = networkTo?.network_type === NetworkTypes.SOLANA;
  const isToEVM = !isToStarknet && !isToTon && !isToSolana;

  const addressTo = useMemo(() => {
    switch (networkTo?.network_type) {
      case NetworkTypes.EVM:
        return evmAddress;
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;
      case NetworkTypes.SOLANA:
        return solanaAddress?.toBase58();

      default:
        return evmAddress;
    }
  }, [
    evmAddress,
    starknetAddress,
    tonAddress,
    solanaAddress,
    networkTo?.network_type,
  ]);

  const isToConnected =
    (isToStarknet && !!starknetAddress) ||
    (isToTon && !!tonAddress) ||
    (isToSolana && !!solanaAddress) ||
    (isToEVM && !!evmAddress);

  const { prices } = usePriceUpdater();

  const sourceNetworks = useMemo(() => {
    return sortBy(networks, 'source_position');
  }, [networks]);

  const destinationNetworks = useMemo(() => {
    return sortBy(networks, 'destination_position');
  }, [networks]);

  const amountFromUSD = useMemo(() => {
    if (!currencyFrom?.symbol) return 0;

    const currencyFromPrice = getPrice(currencyFrom?.symbol, prices);

    const amountFromUSD = +amountFrom * currencyFromPrice.usd;
    return amountFromUSD;
  }, [prices, currencyFrom?.symbol, amountFrom]);

  const amountToUSD = useMemo(() => {
    if (!currencyTo?.symbol) return 0;

    const currencyToPrice = getPrice(currencyTo?.symbol, prices);

    const amountToUSD = +amountTo * currencyToPrice.usd;
    return amountToUSD;
  }, [prices, currencyTo?.symbol, amountTo]);

  useEffect(() => {
    const activeNetworks = networks?.filter(net => net.active);

    const fromQuery = searchParams.get('source');
    if (activeNetworks && activeNetworks.length > 1 && !networkFrom) {
      const defaultNetwork = networks.find(
        item =>
          (fromQuery
            ? item.name.toLowerCase() === fromQuery.toLowerCase()
            : item.id === sourceNetworks[0].id) && item.active
      );

      setNetworkFrom(defaultNetwork ?? sourceNetworks[0]);
    }

    const toQuery = searchParams.get('destination');
    if (activeNetworks && activeNetworks.length > 1 && !networkTo) {
      const availableDestinationNetworks = destinationNetworks.filter(
        item => item.name !== fromQuery
      );

      const defaultNetwork = availableDestinationNetworks.find(
        item =>
          (toQuery && fromQuery !== toQuery
            ? item.name.toLowerCase() === toQuery.toLowerCase()
            : item.id === availableDestinationNetworks[0].id) && item.active
      );

      setNetworkTo(defaultNetwork ?? availableDestinationNetworks[0]);
    }

    const queryAmount = searchParams.get('amount');
    if (queryAmount && !amountFrom) {
      setAmountFrom(queryAmount);
    }

    const queryReceiver = searchParams.get('receiver');
    if (
      (queryReceiver && isValidAddress(queryReceiver)) ||
      (queryReceiver && isValidStarknetAddress(queryReceiver)) ||
      (queryReceiver && isValidTonAddress(queryReceiver)) ||
      (queryReceiver && isValidSolAddress(queryReceiver))
    ) {
      setWalletToType(WalletToType.InputAddress);
      setWalletTo(queryReceiver);
    } else if (addressTo) {
      setWalletToType(WalletToType.CurrentWallet);
      setWalletTo(addressTo);
    }
  }, [
    searchParams,
    networks,
    networkFrom,
    networkTo,
    amountFrom,
    addressTo,
    sourceCurrencies,
    destinationCurrencies,
    sourceNetworks,
    destinationNetworks,
  ]);

  useEffect(() => {
    if (!sourceCurrencies || sourceCurrencies.length < 1) return;
    const activeSourceCurrencies = sourceCurrencies?.filter(c => c.active);
    const tokenFromParam = searchParams.get('tokenFrom');

    if (tokenFromParam) {
      const token = activeSourceCurrencies.find(token =>
        tokenFromParam.toLowerCase().includes('eth')
          ? token.symbol.toLowerCase().includes('eth')
          : tokenFromParam.toLowerCase() === token.symbol.toLowerCase()
      );

      setCurrencyFrom(token || activeSourceCurrencies[0]);
    } else {
      const token = activeSourceCurrencies.find(c =>
        c.symbol.toLowerCase().includes('eth')
      );
      setSearchParams(searchParams => {
        searchParams.set(
          'tokenFrom',
          token?.symbol || activeSourceCurrencies?.[0]?.symbol
        );
        return searchParams;
      });
      setCurrencyFrom(token || activeSourceCurrencies[0]);
    }
  }, [searchParams, sourceCurrencies, setCurrencyFrom, setSearchParams]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!destinationCurrencies || destinationCurrencies.length < 1) return;
    const activeDestCurrencies = destinationCurrencies?.filter(c => c.active);
    const tokenToParam = searchParams.get('tokenTo');
    if (tokenToParam) {
      const token = activeDestCurrencies.find(token =>
        tokenToParam.toLowerCase().includes('eth')
          ? token.symbol.toLowerCase().includes('eth')
          : tokenToParam.toLowerCase() === token.symbol.toLowerCase()
      );
      setCurrencyTo(token || activeDestCurrencies[0]);
    } else {
      const token = activeDestCurrencies.find(c =>
        c.symbol.toLowerCase().includes(isFirstRender.current ? 'usdc' : 'eth')
      );
      setCurrencyTo(token || activeDestCurrencies[0]);
    }
    return () => {
      isFirstRender.current = false;
    };
  }, [searchParams, destinationCurrencies, setCurrencyTo, setSearchParams]);

  const isWalletToAddressValid = (
    address: string,
    networkType: NetworkTypes | undefined
  ): boolean => {
    switch (networkType) {
      case NetworkTypes.STARKNET:
        return isValidStarknetAddress(address);
      case NetworkTypes.TON:
        return isValidTonAddress(address);
      case NetworkTypes.SOLANA:
        return isValidSolAddress(address);
      default:
        return isValidAddress(address);
    }
  };

  const handleNetworkFromChange = useCallback(
    (network: INetwork) => {
      const from = networkFrom;
      const toId = networkTo?.id;
      if (network.id === toId) {
        if (from) {
          setSearchParams(searchParams => {
            searchParams.set('destination', from.name);
            return searchParams;
          });
          setNetworkTo(from);
        }
      }

      if (network && !!networkFrom) {
        setSearchParams(searchParams => {
          searchParams.set('source', network.name);
          return searchParams;
        });
      }

      setNetworkFrom(network);
    },
    [networkFrom, networkTo, setNetworkFrom, setNetworkTo, setSearchParams]
  );

  const handleNetworkToChange = useCallback(
    (network: INetwork) => {
      const from = networkFrom;
      const to = networkTo;
      if (network?.id === from?.id) {
        if (to) {
          setSearchParams(searchParams => {
            searchParams.set('source', to.name);
            return searchParams;
          });
          setNetworkFrom(to);
        }
      }

      if (network && !!networkTo) {
        setSearchParams(searchParams => {
          searchParams.set('destination', network.name);
          return searchParams;
        });
      }

      setNetworkTo(network);
    },
    [networkFrom, networkTo, setNetworkFrom, setNetworkTo, setSearchParams]
  );

  const isValidAmount = useCallback(
    (value: string, currency: ICurrency | undefined) => {
      const amount = +value;

      if (!currency || !currency.active || value === '' || +amount === 0) {
        setError('');
        return;
      }
      if (amount < minSend) {
        setError(`Minimum amount to send is ${minSend} ${currency.symbol}`);
        return false;
      }

      if (amount > maxSend) {
        setError(`Maximum amount to send is ${maxSend} ${currency.symbol}`);
        return false;
      }

      if (amount > +sourceBalance && isFromConnected) {
        setError("You don't have enough funds on your balance");
        return false;
      }

      setError('');
      return true;
    },
    [sourceBalance, setError, isFromConnected, minSend, maxSend]
  );

  useEffect(() => {
    isValidAmount(amountFrom, currencyFrom);
  }, [sourceBalance, amountFrom, currencyFrom, isValidAmount]);

  const shouldDisableTransfer = useMemo(() => {
    return (
      !walletTo ||
      !(+amountTo > 0) ||
      !isWalletToAddressValid(walletTo, networkTo?.network_type) ||
      !isValidAmount(amountFrom, currencyFrom)
    );
  }, [amountFrom, currencyFrom, walletTo, amountTo, networkTo, isValidAmount]);

  const setMax = () => {
    if (!currencyFrom) return;

    const { transactionFee } = appStore.getState();
    const sourceChainFee = transactionFee?.source_chain_fee ?? 0;

    const max = maxSend + sourceChainFee;
    if (max) {
      if (+sourceBalance > max) {
        setAmountFrom((max - sourceChainFee).toString());
      } else if (+sourceBalance <= max) {
        setAmountFrom(toFixed(+sourceBalance, maxPlatformDecimals));
      }
    }
  };

  const handleCurrencyFromChange = (currency: ICurrency) => {
    setCurrencyFrom(currency);
    setSearchParams(searchParams => {
      searchParams.set('tokenFrom', currency.symbol);
      return searchParams;
    });
  };

  const handleCurrencyToChange = (currency: ICurrency) => {
    setCurrencyTo(currency);
    setSearchParams(searchParams => {
      searchParams.set('tokenTo', currency.symbol);
      return searchParams;
    });
  };

  const isOpenChangeTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleSwitchChange = (isChecked: boolean) => {
    if (!isOpenChangeTimeout.current) {
      setIsAdvancedOpen(isChecked);
    }
    if (isOpenChangeTimeout.current) clearTimeout(isOpenChangeTimeout.current);

    isOpenChangeTimeout.current = setTimeout(
      () => {
        isOpenChangeTimeout.current = null;
      },
      ANIMATION_DURATION_S * 1000 * 2
    );
  };

  const handleSubmit = () => {
    const balance = +sourceBalance;
    const { transactionFee } = appStore.getState();

    if (balance < +amountFrom + (transactionFee?.source_chain_fee ?? 0)) {
      setAmountFrom(
        toFixed(
          balance - (transactionFee?.source_chain_fee ?? 0),
          maxPlatformDecimals
        )
      );
    }
    openConfirm();
  };

  return (
    <>
      <AppModeSwitch
        isAdvancedOpen={false}
        setIsAdvancedOpen={handleSwitchChange}
      />
      <div
        className="formBg mt-0 form-max-width defaultRadius regular-bridge-form gradient-border-mask"
        id="regular-bridge-form"
      >
        <div className="transitionHeight formBody text-white align-items-center send-form-min-height">
          {networks.length ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="d-flex flex-column gap-2 mt-4">
                <div className="d-flex">
                  <div className="send-form--label">From</div>
                  {isFromConnected && (
                    <div className="send-form--label ms-auto">
                      Your balance:{' '}
                      <span className="text-white fw-bold">
                        {isSourceBalanceLoading && (
                          <LoaderSmall width={14} height={14} />
                        )}
                        {!isSourceBalanceLoading &&
                          toFixed(+sourceBalance, maxPlatformDecimals)}
                      </span>
                    </div>
                  )}
                </div>
                <NetworkInput
                  networks={sourceNetworks}
                  value={networkFrom}
                  onChange={handleNetworkFromChange}
                  title="Select Source"
                />
                <AmountInput
                  label={
                    <div className="d-flex">
                      <span className="amount-input--label">
                        You send (min {minSend} - max {maxSend})
                      </span>
                      {isFromConnected && (
                        <button
                          className="amount-input--action"
                          onClick={setMax}
                        >
                          Max
                        </button>
                      )}
                      <div className="amount-input--label ms-auto">
                        ${amountFromUSD.toFixed(2)}
                      </div>
                    </div>
                  }
                  value={amountFrom}
                  onChange={setAmountFrom}
                >
                  <CurrencySelect
                    value={currencyFrom}
                    onChange={handleCurrencyFromChange}
                    options={sourceCurrencies}
                  />
                </AmountInput>
              </div>
              <AnimatePresence initial={false}>
                {!!error && (
                  <motion.p
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    className="send-form--error"
                  >
                    <span className="d-block fw-medium error-message mt-2">
                      {error}
                    </span>
                  </motion.p>
                )}
              </AnimatePresence>
              <div className="d-flex justify-content-center mt-4">
                <NetworkExchange />
              </div>
              <div className="d-flex flex-column gap-2 mt-3">
                <div className="d-flex">
                  <div className="send-form--label">To</div>
                  {isToConnected && (
                    <div className="send-form--label ms-auto">
                      Your balance:{' '}
                      <span className="text-white fw-bold">
                        {isSourceBalanceLoading && (
                          <LoaderSmall width={14} height={14} />
                        )}
                        {!isSourceBalanceLoading &&
                          toFixed(+destinationBalance, maxPlatformDecimals)}
                      </span>
                    </div>
                  )}
                </div>
                <NetworkInput
                  networks={destinationNetworks}
                  value={networkTo}
                  onChange={handleNetworkToChange}
                  title="Select Destination"
                />
                <AmountInput
                  label={
                    <div className="d-flex">
                      <span className="amount-input--label">You receive</span>
                      <div className="amount-input--label ms-auto">
                        ${amountToUSD.toFixed(2)}
                      </div>
                    </div>
                  }
                  readonly
                  value={amountTo}
                  onChange={setAmountTo}
                  isLoading={isLoading}
                >
                  <CurrencySelect
                    value={currencyTo}
                    onChange={handleCurrencyToChange}
                    options={destinationCurrencies}
                  />
                </AmountInput>
              </div>
              <div className="fs-6 text-white fw-bold select-address-text mt-4">
                Select Receiving Address
              </div>

              <WalletButtons
                isFromConnected={isFromConnected}
                isToConnected={isToConnected}
                destinationType={networkTo?.network_type}
                connectedAddress={addressTo}
                value={walletTo}
                setValue={setWalletTo}
                isValidAddress={isWalletToAddressValid(
                  walletTo,
                  networkTo?.network_type
                )}
              />
              {isFromConnected ? (
                <button
                  onClick={handleSubmit}
                  className={clsx(
                    'btn btnSecondary w-100 margin-top',
                    shouldDisableTransfer ||
                      isSourceBalanceLoading ||
                      isDestinationBalanceLoading
                      ? 'disabled-link'
                      : 'btnGradient'
                  )}
                >
                  Create Transfer
                </button>
              ) : (
                <button
                  onClick={() => setOpenModal(true)}
                  className="btnGradient w-100 mt-1 py-3"
                >
                  {isToConnected
                    ? 'Connect Required Wallets to Transfer'
                    : 'Connect Wallet'}
                </button>
              )}
            </motion.div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
}

export default SendForm;
