import { SVGAttributes } from 'react';

export function AdventureBox(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      {...props}
    >
      <path
        d="M1.19827 6.93469L10.9181 1.28614L20.7014 6.93469L10.9816 12.5832L1.19827 6.93469Z"
        fill="#D3A5E4"
      />
      <path
        d="M20.4784 6.80569L10.9178 1.28612L1.42136 6.80499L3.33404 7.90932L10.9615 3.47717L18.6087 7.89241L20.4784 6.80569Z"
        fill="#B981CD"
      />
      <path
        d="M10.9814 12.5833L20.7012 6.93472V18.1599L10.9814 23.8085V12.5833Z"
        fill="#9785E2"
      />
      <path
        d="M10.9817 12.5833V23.8085L1.19839 18.1599V6.93472L10.9817 12.5833Z"
        fill="#433288"
      />
      <path
        d="M10.9814 12.5833V14.6038L20.7012 8.95452V6.93472L10.9814 12.5833Z"
        fill="#6251A4"
      />
      <path
        d="M1.19839 6.93472V8.95522L10.9817 14.6038V12.5833L1.19839 6.93472Z"
        fill="#251D44"
      />
      <path
        d="M2.63977 17.1274L3.13591 16.8392L3.20357 16.8779L2.70672 17.1669L2.63977 17.1274Z"
        fill="#180C49"
      />
      <path
        d="M2.70693 17.167L2.70129 19.0635L2.63434 19.0247L2.63998 17.1275L2.70693 17.167Z"
        fill="#180C49"
      />
      <path
        d="M2.70706 17.1669L3.20391 16.878L9.76721 20.6674L9.27036 20.9556L2.70706 17.1669Z"
        fill="#291E53"
      />
      <path
        d="M9.27008 20.9555L9.76693 20.6673L9.83388 20.7061L9.33703 20.9943L9.27008 20.9555Z"
        fill="#362F55"
      />
      <path
        d="M9.33727 20.9944L9.33163 22.8916L9.26468 22.8528L9.27032 20.9557L9.33727 20.9944Z"
        fill="#180C49"
      />
      <path
        d="M2.62839 9.41626L3.12523 9.12732L3.1358 16.8394L2.63966 17.1276L2.62839 9.41626Z"
        fill="#180C49"
      />
      <path
        d="M3.12523 9.20625V9.12732L2.62839 9.41626V9.49449L3.12523 9.20625Z"
        fill="#180C49"
      />
      <path
        d="M2.63928 17.0498L2.63999 17.1273L3.13613 16.839V16.7615L2.63928 17.0498Z"
        fill="#180C49"
      />
      <path
        d="M2.70685 17.1672L9.27015 20.9566L9.26451 22.8538L2.70121 19.0643L2.70685 17.1672ZM0.983035 18.0721L2.63355 19.0249L2.63919 17.1277L2.62862 9.41565L2.63426 7.51918L2.70121 7.55794L2.69557 9.45441L9.25887 13.2438L9.26451 11.3474L9.33146 11.3861L9.32582 13.2826L9.33639 20.9946L9.33076 22.8918L10.982 23.8453V12.3389L0.98233 6.56636V18.0721H0.983035Z"
        fill="#7F6EC3"
      />
      <path
        d="M2.70098 7.55784L2.69534 9.45432L2.62839 9.41626L2.63402 7.51979L2.70098 7.55784Z"
        fill="#180C49"
      />
      <path
        d="M9.33168 11.3859L9.32675 13.2823L9.2598 13.2436L9.26473 11.3471L9.33168 11.3859Z"
        fill="#180C49"
      />
      <path
        d="M19.3256 17.1274L18.8295 16.8392L18.7618 16.8779L19.2587 17.1669L19.3256 17.1274Z"
        fill="#B19EFF"
      />
      <path
        d="M19.2588 17.167L19.2644 19.0635L19.3313 19.0247L19.3257 17.1275L19.2588 17.167Z"
        fill="#D7CDFF"
      />
      <path
        d="M19.2584 17.1669L18.7615 16.878L12.1982 20.6674L12.6951 20.9556L19.2584 17.1669Z"
        fill="#B19EFF"
      />
      <path
        d="M12.6953 20.9555L12.1984 20.6673L12.1315 20.7061L12.6283 20.9943L12.6953 20.9555Z"
        fill="#B19EFF"
      />
      <path
        d="M12.6284 20.9944L12.6341 22.8916L12.701 22.8528L12.6954 20.9557L12.6284 20.9944Z"
        fill="#D7CDFF"
      />
      <path
        d="M19.337 9.41626L18.8402 9.12732L18.8296 16.8394L19.3258 17.1276L19.337 9.41626Z"
        fill="#8472CB"
      />
      <path
        d="M19.3368 9.49237V9.41626L18.8399 9.12732V9.20413L19.3368 9.49237Z"
        fill="#B19EFF"
      />
      <path
        d="M19.3258 17.0515L18.8296 16.7632V16.8393L19.3258 17.1276V17.0515Z"
        fill="#B19EFF"
      />
      <path
        d="M19.2644 19.0636L12.7011 22.8531L12.6954 20.9559L19.2587 17.1665L19.2644 19.0636ZM20.9825 6.56636L10.9829 12.3389V23.8453L12.6341 22.8918L12.6285 20.9946L12.639 13.2826L12.6334 11.3861L12.7003 11.3474L12.706 13.2438L19.2693 9.45441L19.2636 7.55794L19.3306 7.51918L19.3362 9.41565L19.3257 17.1277L19.3313 19.0249L20.9818 18.0721V6.56636H20.9825Z"
        fill="#C0B3F2"
      />
      <path
        d="M19.2642 7.55784L19.2698 9.45432L19.3368 9.41626L19.3311 7.51979L19.2642 7.55784Z"
        fill="#D7CDFF"
      />
      <path
        d="M12.6339 11.3859L12.6388 13.2823L12.7058 13.2436L12.7008 11.3471L12.6339 11.3859Z"
        fill="#D7CDFF"
      />
      <path
        d="M12.6392 13.2824L10.983 14.2388V14.317L12.6392 13.3599V13.2824Z"
        fill="#D7CDFF"
      />
      <path
        d="M20.9825 8.54034V8.46282L19.3369 9.41353V9.41634V9.49105L20.9825 8.54034Z"
        fill="#D7CDFF"
      />
      <path
        d="M12.6286 20.917L10.983 21.8705V21.9487L12.6286 20.9952V20.9177V20.917Z"
        fill="#D7CDFF"
      />
      <path
        d="M20.9824 16.1719V16.0944L19.3256 17.05V17.0528V17.1275L20.9824 16.1719Z"
        fill="#D7CDFF"
      />
      <path
        d="M12.6733 1.7853L11.0284 2.73811L10.9608 2.69935L12.6063 1.74653L12.6733 1.7853Z"
        fill="white"
      />
      <path
        d="M11.0287 2.73799L11.0266 3.31236L10.9597 3.27359L10.9611 2.69923L11.0287 2.73799Z"
        fill="#7B4D3A"
      />
      <path
        d="M10.9607 2.69923L10.9593 3.27359L4.28323 7.14054L4.28535 6.56617L10.9607 2.69923Z"
        fill="#9E5FB4"
      />
      <g style={{ mixBlendMode: 'overlay' }} opacity="0.4">
        <path
          d="M4.2854 6.65426L10.9608 2.78732V2.69923L4.2854 6.56617V6.65426Z"
          fill="white"
        />
      </g>
      <path
        d="M10.8938 2.73791L10.8924 3.31228L10.9593 3.27352L10.9608 2.69915L10.8938 2.73791Z"
        fill="#7B4D3A"
      />
      <path
        d="M4.3523 6.52754L4.28535 6.5663L4.28323 7.14067L4.35089 7.10191L4.3523 6.52754Z"
        fill="#7B4D3A"
      />
      <path
        d="M4.35263 6.60518L2.70141 7.5587L2.63446 7.51994L4.28498 6.56641L4.35263 6.60518Z"
        fill="white"
      />
      <path
        d="M11.0044 10.4333L9.33136 11.384L9.2637 11.3474L10.9375 10.3945L11.0044 10.4333Z"
        fill="white"
      />
      <path
        d="M17.6137 6.52744L17.6115 7.10181L11.0264 3.31238L11.0285 2.73802L17.6137 6.52744Z"
        fill="#B684C8"
      />
      <g style={{ mixBlendMode: 'overlay' }} opacity="0.4">
        <path
          d="M11.0286 2.82611L17.613 6.61553L17.6137 6.52744L11.0286 2.73802V2.82611Z"
          fill="white"
        />
      </g>
      <path
        d="M19.3257 5.61342L17.6808 6.56624L17.6139 6.52748L19.2587 5.57466L19.3257 5.61342Z"
        fill="white"
      />
      <path
        d="M17.6808 6.56638L17.6793 7.14075L17.6117 7.10199L17.6138 6.52762L17.6808 6.56638Z"
        fill="#7B4D3A"
      />
      <path
        d="M10.9607 2.69939L4.28468 6.56634L2.63346 7.51986L0.982941 6.56634L10.9495 0.793055L12.6056 1.74587L10.9607 2.69869V2.69939ZM2.70111 7.55862L9.26371 11.3473L10.9368 10.3938L4.35163 6.60439L2.70041 7.55862H2.70111ZM12.6733 1.78534L11.0284 2.73816L17.6135 6.52758L19.2584 5.57476L12.6733 1.78534ZM19.3254 5.61352L20.9815 6.56704L10.9819 12.3396L9.33066 11.3847L11.0037 10.4333L17.6798 6.56634L19.3247 5.61352H19.3254Z"
        fill="#F4D4FF"
      />
      <path
        d="M17.6139 6.60518L19.2637 7.55799L19.3313 7.51923L17.6808 6.56641L17.6139 6.60518Z"
        fill="white"
      />
      <path
        d="M11.0047 10.4326L12.6334 11.3854L12.7003 11.3467L11.0717 10.3945L11.0047 10.4326Z"
        fill="white"
      />
      <path
        d="M9.24362 1.78459L10.8941 2.73811L10.9611 2.69935L9.31128 1.74653L9.24362 1.78459Z"
        fill="white"
      />
      <path
        d="M2.64478 5.60349L4.28754 6.56406L4.35449 6.5253L2.71243 5.56544L2.64478 5.60349Z"
        fill="white"
      />
      <path
        d="M9.33688 20.917L10.9825 21.8706V21.9488L9.33688 20.9953V20.9177V20.917Z"
        fill="#B19EFF"
      />
      <path
        d="M0.98288 16.1719V16.0944L2.63974 17.05V17.0528V17.1275L0.98288 16.1719Z"
        fill="#B19EFF"
      />
      <path
        d="M9.32602 13.2825L10.9829 14.2388V14.3171L9.32602 13.3607V13.2832V13.2825Z"
        fill="#B19EFF"
      />
      <path
        d="M0.98288 8.54031V8.46279L2.62846 9.41561V9.41843V9.49384L0.98288 8.54031Z"
        fill="#B19EFF"
      />
      <g style={{ mixBlendMode: 'overlay' }} opacity="0.4">
        <path
          d="M10.9829 12.422L20.9825 6.64665V6.56772L10.9829 12.3431V12.422Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'overlay' }} opacity="0.4">
        <path
          d="M10.9825 12.422L0.98288 6.64665V6.56772L10.9825 12.3431V12.422Z"
          fill="white"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.9">
        <path
          d="M10.9829 23.8451L20.9825 18.0697V17.9908L10.9829 23.7662V23.8451Z"
          fill="#E3CABE"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.9">
        <path
          d="M10.9825 23.8451L0.98288 18.0697V17.9908L10.9825 23.7662V23.8451Z"
          fill="#E3CABE"
        />
      </g>
      <path
        d="M13.9738 17.1269L14.0637 17.0831L14.0567 16.9833C13.9764 15.8352 14.7499 14.4955 15.7843 13.991C16.8187 13.4865 17.7224 14.0082 17.8027 15.1563L17.8097 15.256L17.8995 15.2122L18.22 15.0559L18.3099 15.0121L18.3029 14.9123C18.2012 13.4576 17.0562 12.7965 15.7455 13.4358C14.4348 14.0751 13.4548 15.7726 13.5565 17.2273L13.5635 17.327L13.6533 17.2832L13.9738 17.1269Z"
        fill="#C1B6EE"
        stroke="#C1B6EE"
        strokeWidth="0.2"
      />
      <path
        d="M8.1169 17.0755L8.02702 17.0317L8.034 16.932C8.11485 15.7758 7.3359 14.4266 6.29418 13.9185C5.25246 13.4104 4.34243 13.9358 4.26158 15.092L4.25461 15.1918L4.16473 15.1479L3.8421 14.9906L3.75222 14.9467L3.7592 14.847C3.86158 13.3828 5.01399 12.7175 6.33317 13.3609C7.65235 14.0043 8.63877 15.7128 8.53638 17.177L8.52941 17.2767L8.43953 17.2329L8.1169 17.0755Z"
        fill="#6D5FA8"
        stroke="#6D5FA8"
        strokeWidth="0.2"
      />
      <path
        d="M10.0823 8.57335L10.1722 8.52951L10.0944 8.46658C9.21601 7.75524 9.32748 6.77689 10.3434 6.28139C11.3594 5.78588 12.8951 5.96085 13.7735 6.67219L13.8512 6.73513L13.9411 6.69129L14.2561 6.53765L14.346 6.49381L14.2683 6.43088C13.1536 5.52821 11.2048 5.30618 9.91562 5.93496C8.62643 6.56374 8.48498 7.80523 9.59969 8.70789L9.6774 8.77083L9.76728 8.72699L10.0823 8.57335Z"
        fill="#F0DBF7"
        stroke="#F0DBF7"
        strokeWidth="0.2"
      />
    </svg>
  );
}
