import adventureNftImage from '@/Assets/images/phaseAdventureCard.png';
import adventureCardBg from '@/Assets/images/phaseAdventureCardBg.png';
import earlyNftImage from '@/Assets/images/phaseEarlyCard.png';
import lateNftImage from '@/Assets/images/phaseLateCard.png';
import sunriseJourney from '@/Assets/images/sunriseJourney.png';
import sunriseJourneyMobile from '@/Assets/images/sunriseJourneyMobile.png';
import { Loader } from '@/components/Loader';
import { useImgPreloader } from '@/hooks/useImagePreload';
import { phaseService } from '@/services/phaseApi';
import { Journeys, Phase } from '@/types/phases';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PhaseJourneysContent } from './PhaseJourneysContent';

export function PhaseJourneysPage() {
  const { imgsPreloaded } = useImgPreloader([
    sunriseJourney,
    sunriseJourneyMobile,
    adventureNftImage,
    adventureCardBg,
    earlyNftImage,
    lateNftImage,
  ]);

  useEffect(() => {
    document.body.classList.add('body--blue');

    return () => document.body.classList.remove('body--blue');
  }, []);

  return (
    <div>
      <Helmet>
        <title>Retro Bridge | All chains, single app.</title>
      </Helmet>
      {!imgsPreloaded && (
        <div className="phases-loader">
          <Loader />
        </div>
      )}
      <PhaseJourneysContent />
    </div>
  );
}
