import clsx from 'clsx';
import { useMemo } from 'react';
import sunriseJourney from '@/Assets/images/sunriseJourney.png';
import sunriseJourneyMobile from '@/Assets/images/sunriseJourneyMobile.png';
import { Image } from '@/components/Image';
import { useWindowResize } from '@/hooks/useWindowResize';
import '../../PhasePage/PhaseHero/styles.css';

export function PhaseJourneysHero() {
  const { width } = useWindowResize();
  const isMobile = width < 768;

  const bgImage = useMemo(() => {
    if (isMobile) {
      return sunriseJourneyMobile;
    }

    return sunriseJourney;
  }, [isMobile]);
  return (
    <>
      <div className="phase-page-hero--bg-container">
        <Image
          withSmoothLoading
          src={bgImage}
          className="phase-page-hero--bg"
          alt=""
        />
      </div>
      <div className={clsx('phase-page-hero')}>
        <h1 className="phase-page-hero--heading">Sunrise Journey</h1>
        <p className="phase-page-hero--content text-center">
          Sunrise Journey will be dedicated to initial functionality and
          bridging directions of RetroBridge. As a reward for users commitment
          to Sunrise Journey they will receive Soulbound NFT, which is
          impossible to transfer or sell.
        </p>
      </div>
    </>
  );
}
