import nftImage from '@/Assets/images/journeys/ton/nftImage.png';
import notificationBg from '@/Assets/images/journeys/ton/notificationBg.png';
import tokenIcon from '@/Assets/images/journeys/ton/tokenIcon.png';
import { Image } from '@/components/Image';
import { stage } from '@/Config/ApiConfig';
import { ROUTES } from '@/constants/routes.constants';
import { Phase } from '@/types/phases';
import { Adventure } from '..';
import { TONBanner } from './banners/TONBanner ';

export const tonAdventure: Adventure = {
  phase: Phase.TonJourney,

  anchorClassName: 'ton',

  nftImage,
  heading: <>TON Adventure</>,
  startDate:
    stage === 'staging'
      ? '2024-08-15T06:00:00.000Z'
      : '2024-08-15T12:00:00.000Z',
  endDate: '2024-08-22T12:00:00.000Z',
  link: ROUTES.PHASE_TON_ADVENTURE_PAGE,
  linkText: 'Take TON Adventure',

  banner: <TONBanner />,

  notificationText: (
    <>
      Take{' '}
      <strong>
        <Image withSmoothLoading src={tokenIcon} alt="" /> TON Adventure
      </strong>{' '}
      to get <strong>Adventure NFT</strong> and{' '}
      <strong>-30% on bridging fees to TON</strong>
    </>
  ),
  notificationBg,
};
