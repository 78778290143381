import {
  TonClient,
  JettonMaster,
  JettonWallet,
  Address,
  fromNano,
} from '@ton/ton';
import { getHttpEndpoint } from '@orbs-network/ton-access';
import { stage } from '@/Config/ApiConfig';

export const isValidTonAddress = (address?: string) => {
  try {
    Address.parse(address || '');
    return true;
  } catch (e) {
    return false;
  }
};

export const getTonBalance = async (
  walletAddress: string,
  rpc: string,
  jettonAddress = '',
  jettonDecimals = 6
) => {
  let balance = '0';
  try {
    let endpoint = await getHttpEndpoint({ network: 'mainnet' });

    if (stage === 'development')
      endpoint = await getHttpEndpoint({ network: 'testnet' });

    const client = new TonClient({ endpoint });

    if (!isValidTonAddress(jettonAddress)) {
      balance = fromNano(await client.getBalance(Address.parse(walletAddress)));
    } else {
      const jettonWalletAddr = await client
        .open(new JettonMaster(Address.parse(jettonAddress)))
        .getWalletAddress(Address.parse(walletAddress));

      const jettonWalletBalance = await client
        .open(JettonWallet.create(jettonWalletAddr))
        .getBalance();

      balance = (
        Number(jettonWalletBalance.toString()) /
        10 ** jettonDecimals
      ).toString();
    }
  } catch (error) {
    console.error(error);
  }

  return balance;
};
