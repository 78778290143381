import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Exchange } from '@/Assets/Icons/Exchange';
import { useAppStore } from '@/stores/app.store';

export function NetworkExchange() {
  const [active, setActive] = useState(false);

  const [networkFrom, networkTo, currencyFrom, currencyTo] = useAppStore(s => [
    s.networkFrom,
    s.networkTo,
    s.currencyFrom,
    s.currencyTo,
  ]);
  const [setNetworkFrom, setNetworkTo, setCurrencyFrom, setCurrencyTo] =
    useAppStore(s => [
      s.setNetworkFrom,
      s.setNetworkTo,
      s.setCurrencyFrom,
      s.setCurrencyTo,
    ]);
  const setSearchParams = useSearchParams()[1];

  const handleClick = () => {
    const networkFromElement = networkFrom;
    const networkToElement = networkTo;
    const currencyFromElement = currencyFrom;
    const currencyToElement = currencyTo;

    setActive(!active);

    networkTo && setNetworkFrom(networkTo);
    currencyFrom && setCurrencyFrom(currencyFrom);

    networkFrom && setNetworkTo(networkFrom);
    currencyTo && setCurrencyTo(currencyTo);

    if (networkToElement) {
      setSearchParams(searchParams => {
        searchParams.set('source', networkToElement?.name);
        return searchParams;
      });
    }
    if (currencyToElement) {
      setSearchParams(s => {
        s.set('tokenFrom', currencyToElement.symbol);
        return s;
      });
    }
    if (networkFromElement) {
      setSearchParams(searchParams => {
        searchParams.set('destination', networkFromElement?.name);
        return searchParams;
      });
    }
    if (currencyFromElement) {
      setSearchParams(s => {
        s.set('tokenTo', currencyFromElement.symbol);
        return s;
      });
    }
  };

  return (
    <button className="send-form--swap-networks" onClick={handleClick}>
      <Exchange width={12} height={16} />
    </button>
  );
}
