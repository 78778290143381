import check from '@/Assets/Icons/check.svg';
import external from '@/Assets/Icons/external.svg';
import { Loader } from '@/components/Loader';
import { MINUTE } from '@/constants/time';
import { IBulkOrder } from '@/types/apiTypes';
import { BulkOrderStatuses } from '@/types/enums';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useWindowSize } from 'usehooks-ts';
import '../../../ProgressPage/ProgressPage.css';
import { useAdvancedProgressStore } from '../../store';
import { ProgressTransactionItem } from './ProgressTransactionItem';
import './styles.css';

export function AdvancedProgress({ order }: { order: IBulkOrder }) {
  const status = order.status;
  const [isWaitingForConfirmation] = useAdvancedProgressStore(s => [
    s.isWaitingForConfirmation,
  ]);
  const { width } = useWindowSize();

  const statusClass = useMemo(() => {
    switch (status) {
      case BulkOrderStatuses.PENDING:
      case BulkOrderStatuses.WAIT_DEPOSIT:
      case BulkOrderStatuses.REJECTED:
      case BulkOrderStatuses.EXPIRED:
      case BulkOrderStatuses.DEPOSIT_FAILED:
        return 'transY0';
      case BulkOrderStatuses.DEPOSITED:
        return 'trans50';
      case BulkOrderStatuses.PROCESSING:
        return 'transY100';
      default:
        throw new Error(`Unhandled status: ${status}`);
    }
  }, [status]);

  const [hasDelay, setHasDelay] = useState(false);
  const processingOrders = useMemo(
    () =>
      order.bulk_order_schedules.filter(
        el => el.status !== BulkOrderStatuses.COMPLETE
      ),
    [order.bulk_order_schedules]
  );
  const [delayedLength, setDelayedLength] = useState(processingOrders.length);

  useEffect(() => {
    setHasDelay(false);

    const delay = setTimeout(() => {
      if (statusClass === 'transY100') {
        setHasDelay(processingOrders.length >= delayedLength);
      } else {
        setHasDelay(true);
      }
    }, MINUTE * 3);

    setDelayedLength(processingOrders.length);
    return () => clearTimeout(delay);
  }, [statusClass, delayedLength, processingOrders.length]);

  const isDetectionDelay =
    hasDelay &&
    statusClass === 'transY0' &&
    status !== BulkOrderStatuses.REJECTED &&
    status !== BulkOrderStatuses.EXPIRED &&
    status !== BulkOrderStatuses.DEPOSIT_FAILED;

  const isTransferDelay = false;

  const estimatedPerTransfer = useMemo(() => {
    return Math.floor(order.execution_time / 1000 / 60);
  }, [order.execution_time]);

  return (
    <div className="progressContainer flex-column w-100">
      {isWaitingForConfirmation && (
        <div className="progressDepositSending">
          <span className="progressDepositSending-loader">
            <Loader />
          </span>
          <span className="progressDepositSending-text">
            Awaiting transfer confirmation
          </span>
        </div>
      )}
      {!isWaitingForConfirmation && (
        <>
          <div className="d-flex gap-3">
            <div
              className={clsx(
                'progressLeftDiv',
                hasDelay &&
                  statusClass !== 'trans50' &&
                  statusClass !== 'transY100' &&
                  'withDelay'
              )}
            >
              <div className={'progressCircle activeProgressCircle'}>
                {<img src={check} className="progressCheckIcon" alt="" />}
              </div>

              <div
                className={clsx(
                  'progress',
                  statusClass === 'transY0' && 'inactive'
                )}
              />

              <div
                className={`progressCircle ${
                  statusClass === 'transY100' && 'activeProgressCircle'
                } middleCheckIcon`}
              >
                {statusClass === 'transY100' && (
                  <img src={check} className="progressCheckIcon" alt="" />
                )}
              </div>

              <div
                className={`progressCircle ${
                  status === BulkOrderStatuses.COMPLETE &&
                  'activeProgressCircle'
                }`}
              >
                {status === BulkOrderStatuses.COMPLETE && (
                  <img src={check} className="progressCheckIcon" alt="" />
                )}
              </div>

              <div className={` progressAbsolute ${statusClass}`}>
                <span className="ring ring-1"></span>
                <span className="ring ring-2"></span>
                <span className="ring ring-3"></span>
                <span className="ring ring-4"></span>
                <span className="arrow"></span>
              </div>
            </div>

            <div className="progressRightDiv">
              <div
                className={clsx(
                  isDetectionDelay && 'withDelay',
                  statusClass === 'transY0' && 'text-gradient',
                  'title mb-1'
                )}
              >
                {isDetectionDelay
                  ? 'Detection Is Experiencing Slight Delay...'
                  : 'Detecting Your Transfer'}
                {order.order.transaction_in_hash && (
                  <a
                    href={
                      order.order.currency_in.contract.network.blockscan_url +
                      'tx/' +
                      order.order.transaction_in_hash
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="progressTransactionLink"
                  >
                    <img src={external} alt="" />
                  </a>
                )}
              </div>
              <div
                className={clsx(
                  isDetectionDelay && 'withDelay',
                  'title subtitle'
                )}
              >
                {isDetectionDelay
                  ? 'Your assets will be detected in a while'
                  : 'Estimated time: less than 3 min'}
              </div>
              <div
                className={clsx(
                  'title mTop40',
                  statusClass === 'trans50' && 'text-gradient',
                  statusClass === 'transY0' && 'textBlack'
                )}
              >
                Transfer Confirmation
              </div>
              <div
                className={clsx(
                  isTransferDelay && 'withDelay',
                  `title ${
                    statusClass === 'transY100' ? 'text-gradient' : 'textBlack'
                  }`
                )}
              >
                {statusClass === 'transY100' &&
                  isTransferDelay &&
                  'Bridging is experiencing slight delay...'}
                {statusClass !== 'transY100' &&
                  !isTransferDelay &&
                  'Transfer Of Assets To Your Address'}
                {statusClass === 'transY100' &&
                  !isTransferDelay &&
                  `${order.transfer_time_type} Mode`}
              </div>
              <div
                className={clsx(
                  isTransferDelay && 'withDelay',
                  statusClass !== 'transY100' && 'textBlack',
                  'title subtitle'
                )}
              >
                {statusClass === 'transY100' &&
                  isTransferDelay &&
                  'Your assets will be with you shortly in the destination network'}
                {!isTransferDelay &&
                  `Estimated time: about ${estimatedPerTransfer} min per transfer`}
              </div>
            </div>
          </div>
          <AnimatePresence mode="wait">
            {statusClass === 'transY100' && (
              <motion.div
                initial={{ opacity: 0, maxHeight: 0 }}
                animate={{ opacity: 1, maxHeight: 326 }}
                exit={{ opacity: 0, maxHeight: 0 }}
                key={statusClass}
                className="advanced-transfer-table"
              >
                {width > 746 && (
                  <div className="advanced-transfer-table--header">
                    <div />
                    <div className="advanced-transfer-table--header-item">
                      Source Network
                    </div>
                    <div className="advanced-transfer-table--header-item">
                      Destination Network
                    </div>
                    <div className="advanced-transfer-table--header-item">
                      Status
                    </div>
                    <div className="advanced-transfer-table--header-item">
                      Amount to Receive
                    </div>
                  </div>
                )}

                <SimpleBar
                  style={{
                    maxHeight: '290px',
                    marginRight: '-24px',
                    paddingRight: '24px',
                    height: '100%',
                  }}
                  id="receiving-viewport"
                >
                  <div className="advanced-transfer-table--body">
                    {order.bulk_order_schedules.map((schedule, i) => (
                      <ProgressTransactionItem
                        key={i}
                        i={i}
                        order={schedule}
                        currencyIn={order.order.currency_in}
                        walletSender={order.wallet_sender}
                      />
                    ))}
                  </div>
                </SimpleBar>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </div>
  );
}
