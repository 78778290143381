import { AdventureBox } from '@/Assets/Icons/AdventureBox';
import { Faucet } from '@/Assets/Icons/Faucet';
import { Globe } from '@/Assets/Icons/Globe';
import BurgerMenuIcon from '@/Assets/Icons/burger-menu.svg';
import CloseBurgerMenuIcon from '@/Assets/Icons/close-burger-menu.svg';
import { DappsIcon } from '@/Assets/Icons/dapps';
import discord from '@/Assets/Icons/discord2.svg';
import gitbook from '@/Assets/Icons/gitbook.svg';
import github from '@/Assets/Icons/github.svg';
import medium from '@/Assets/Icons/medium.svg';
import retroIcon from '@/Assets/Icons/retroIcon.svg';
import twitter from '@/Assets/Icons/twitter2.png';
import MobileMenuBg from '@/Assets/images/mobile-menu-bg.png';
import sunIcon from '@/Assets/images/sunIcon.svg';
import { ROUTES } from '@/constants/routes.constants';
import {
  DialogClose,
  DialogContent,
  DialogPortal,
  DialogTrigger,
  Root,
} from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { MobileMenuNetworkSwitch } from '../MobileMenuNetworkSwitch';

interface MobileMenuProps {
  isAppMenu?: boolean;
}

const MobileMenu: FC<MobileMenuProps> = ({ isAppMenu = false }) => {
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (width >= 992) {
      setIsOpen(false);
    }
  }, [width]);

  return (
    <Root open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <div>
          <img
            width="24px"
            height="24px"
            draggable={false}
            src={BurgerMenuIcon}
            alt=""
          />
        </div>
      </DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal forceMount>
            <DialogContent asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="mobile-menu-container"
              >
                <div className="mobile-menu-background">
                  <img src={MobileMenuBg} alt="" className="mobile-menu-img" />
                  <div className="mobile-menu-content">
                    <div className="d-lg-none d-flex justify-content-between align-items-center w-100 mobile-menu-header menu-header-ml">
                      <div style={{ minWidth: '24px', width: '24px' }} />
                      <DialogClose asChild>
                        <Link to={ROUTES.LANDING_PAGE}>
                          <img
                            src={retroIcon}
                            alt="logo"
                            style={{
                              width: '170px',
                              height: '40px',
                            }}
                          />
                        </Link>
                      </DialogClose>
                      <DialogClose className="mobile-menu-close">
                        <img
                          width="24px"
                          height="24px"
                          src={CloseBurgerMenuIcon}
                          draggable={false}
                          alt=""
                        />
                      </DialogClose>
                    </div>
                    <div
                      className="mobile-menu-body"
                      style={{ marginTop: !isAppMenu ? '69px' : undefined }}
                    >
                      {isAppMenu && <MobileMenuNetworkSwitch />}
                      <div className="mobile-menu-tabs">
                        <DialogClose asChild>
                          <Link
                            to={ROUTES.TESTNET_SEND_PAGE}
                            className="mobile-menu-tabs-item mobile-menu-tabs-item_flex"
                          >
                            <Faucet />
                            Testnets & Faucet
                          </Link>
                        </DialogClose>
                        <DialogClose asChild>
                          <Link
                            to={ROUTES.PHASES_PAGE}
                            className="mobile-menu-tabs-item mobile-menu-tabs-item_flex"
                          >
                            <img src={sunIcon} alt="sunIcon" />
                            Phases
                          </Link>
                        </DialogClose>
                        <DialogClose asChild>
                          <Link
                            to={ROUTES.PHASE_ADVENTURES_PAGE}
                            className="mobile-menu-tabs-item mobile-menu-tabs-item_flex"
                          >
                            <AdventureBox />
                            Retro Adventures
                          </Link>
                        </DialogClose>
                        <DialogClose asChild>
                          <Link
                            to={ROUTES.DAPP_EXPLORER_PAGE}
                            className="mobile-menu-tabs-item mobile-menu-tabs-item_flex"
                          >
                            <DappsIcon />
                            Dapps Explorer
                          </Link>
                        </DialogClose>
                        {isAppMenu && (
                          <DialogClose asChild>
                            <Link
                              to={ROUTES.EXPLORER_PAGE}
                              className="mobile-menu-tabs-item mobile-menu-tabs-item_flex"
                            >
                              <Globe />
                              Explorer
                            </Link>
                          </DialogClose>
                        )}
                      </div>
                    </div>
                    <div className="mobile-menu-footer">
                      <a
                        href="https://docs.retrobridge.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobile-menu-footer-btn"
                        aria-label="Docs"
                      >
                        <img
                          style={{ width: '16px', height: '16px' }}
                          src={gitbook}
                          alt="logo"
                        />
                      </a>
                      <a
                        href="https://github.com/retro-bridge"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobile-menu-footer-btn"
                        aria-label="GitHub"
                      >
                        <img
                          style={{ width: '16px', height: '16px' }}
                          src={github}
                          alt="logo"
                        />
                      </a>
                      <a
                        href="https://retro-bridge.medium.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobile-menu-footer-btn"
                        aria-label="Medium"
                      >
                        <img
                          style={{ width: '16px', height: '16px' }}
                          src={medium}
                          alt="logo"
                        />
                      </a>
                      <a
                        href="https://twitter.com/retro_bridge"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobile-menu-footer-btn"
                        aria-label="X (Twitter)"
                      >
                        <img src={twitter} alt="logo" />
                      </a>
                      <a
                        href="https://discord.gg/retrobridge"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobile-menu-footer-btn"
                        aria-label="Discord"
                      >
                        <img src={discord} alt="logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </motion.div>
            </DialogContent>
          </DialogPortal>
        )}
      </AnimatePresence>
    </Root>
  );
};

export default MobileMenu;
