import { useStarknetAccount } from '@/starknet/hooks/account';
import { IBulkReceipt } from '@/types/apiTypes';
import { useNetwork, UserRejectedRequestError } from '@starknet-react/core';
import { useTonAddress } from '@tonconnect/ui-react';
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SwitchChainError, toHex } from 'viem';
import { useAccount as useEVMAccount, useSwitchChain } from 'wagmi';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { ConnectWalletModalWithDisabled } from '../../../../components/ConnectWalletModalWithDisabled';
import { ADVANCED_PROGRESS_PAGE } from '../../../../constants/routes.constants';
import { useBalance } from '../../../../hooks/useBalance';
import { useNotify } from '../../../../hooks/useToast';
import { useWithdraw } from '../../../../hooks/useWithdraw';
import { NetworkTypes } from '../../../../providers/web3Provider';
import { bulkApi } from '../../../../services/BulkApi';
import { useAdvancedProgressStore } from '../../../ProgressAdvancedPage/store';
import { FormStep, useAdvancedWalletStore } from '../../store';
import { UnsupportedChainDialog } from '@/components/UnsoportedChainDialog';

export function SubmitButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { address: EVMAddress, chain: evmChain } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const tonAddress = useTonAddress();

  const { withdraw } = useWithdraw();
  const { notify } = useNotify();

  const [
    formStep,
    networkFrom,
    currencyFrom,
    receiving,
    executionType,
    estimatedFee,
    isEstimationLoading,
  ] = useAdvancedWalletStore(s => [
    s.formStep,
    s.networkFrom,
    s.currencyFrom,
    s.receiving,
    s.executionType,
    s.estimatedFee,
    s.isEstimationLoading,
  ]);

  const isFromStarknet = networkFrom?.network_type === NetworkTypes.STARKNET;
  const isFromTon = networkFrom?.network_type === NetworkTypes.TON;
  const isFromSolana = networkFrom?.network_type === NetworkTypes.SOLANA;
  const isFromEVM = !isFromStarknet && !isFromTon && !isFromSolana;

  const isFromConnected =
    (isFromStarknet && !!starknetAddress) ||
    (isFromTon && !!tonAddress) ||
    (isFromSolana && !!solanaAddress) ||
    (isFromEVM && !!EVMAddress);

  const [isUnsupportedDialogOpen, setIsUnsupportedDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { chain } = useNetwork();
  const { switchChainAsync } = useSwitchChain();
  const [setFormStep, validateForm] = useAdvancedWalletStore(s => [
    s.setFormStep,
    s.validateForm,
  ]);
  const { balance, loading: isBalanceLoading } = useBalance(
    networkFrom,
    currencyFrom
  );
  const navigate = useNavigate();
  const [setIsCanceled, setIsWaitingForConfirmation] = useAdvancedProgressStore(
    s => [s.setIsCanceled, s.setIsWaitingForConfirmation]
  );

  const address = useMemo(() => {
    switch (networkFrom?.network_type) {
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;
      case NetworkTypes.SOLANA:
        return solanaAddress?.toBase58();
      default:
        return EVMAddress;
    }
  }, [
    networkFrom?.network_type,
    starknetAddress,
    tonAddress,
    solanaAddress,
    EVMAddress,
  ]);

  const totalFee = useMemo(() => {
    return estimatedFee.reduce((acc, { full_fee }) => acc + full_fee, 0);
  }, [estimatedFee]);

  const totalToSent = useMemo(() => {
    return receiving.reduce((acc, { amount }) => acc + +amount, 0) + totalFee;
  }, [receiving, totalFee]);

  const isWrongNetwork = useMemo(() => {
    if (!networkFrom) {
      return true;
    }
    if (networkFrom.network_type === NetworkTypes.STARKNET) {
      return toHex(chain?.id) !== networkFrom.chainId;
    } else if (networkFrom.network_type === NetworkTypes.SOLANA) {
      return false;
    } else if (networkFrom.network_type === NetworkTypes.TON) {
      return false;
    }

    if (!evmChain) {
      return true;
    }
    return evmChain.id !== +networkFrom.chainId;
  }, [evmChain, chain, networkFrom]);

  const showBackendError = (error: AxiosError<{ message: string }>) => {
    const defaultMessage = 'Something went wrong. Please try again later.';
    switch (error.response?.status) {
      case 503:
        return notify({
          title: 'Temporary transfer limit',
          meassage: 'Destination network will become available shortly',
          type: 'error',
        });
      default:
        return notify({
          meassage: error?.response?.data?.message ?? defaultMessage,
          type: 'error',
        });
    }
  };

  const showSendError = (error: unknown) => {
    if (error instanceof AxiosError) {
      return showBackendError(error);
    }
    notify({
      meassage: 'Something went wrong. Please try again later.',
      type: 'error',
    });
  };

  const validate = () => {
    try {
      const result = validateForm();

      if (+balance < totalToSent) {
        throw new Error("You don't have enough funds on your balance");
      }

      return result;
    } catch (error) {
      if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
    return false;
  };

  const switchNetworkHelper = async () => {
    try {
      setIsDisabled(true);
      if (currencyFrom && networkFrom && switchChainAsync) {
        await switchChainAsync({ chainId: +networkFrom.chainId });
      }
    } catch (error) {
      console.error(error);
      if (error instanceof UserRejectedRequestError) {
        const parsedError = JSON.parse(JSON.stringify(error));
        if (
          parsedError.details.includes(
            'Missing or invalid. request() method: wallet_addEthereumChain'
          )
        ) {
          setIsUnsupportedDialogOpen(true);
        }
      } else if (error instanceof SwitchChainError) {
        setIsUnsupportedDialogOpen(true);
      }
    } finally {
      setIsDisabled(false);
    }
  };

  const handleSubmit = async () => {
    if (!address || isBalanceLoading) return;

    if (formStep === FormStep.Input) {
      if (!validate()) return;

      setFormStep(FormStep.Summary);
      return;
    }
    let order: IBulkReceipt | undefined;

    if (isWrongNetwork && formStep === FormStep.Summary) {
      await switchNetworkHelper();
      return;
    }

    try {
      if (formStep !== FormStep.Summary) return;
      setIsLoading(true);

      const totalSum = receiving.reduce(
        (acc, el) => acc + parseFloat(el.amount),
        0
      );

      const totalFee = estimatedFee.reduce(
        (acc, { full_fee }) => acc + full_fee,
        0
      );

      if (!currencyFrom) {
        throw new Error('Currency not found');
      }

      order = await bulkApi.createBulkOrder(
        currencyFrom.id,
        (totalSum + totalFee).toString(),
        address,
        receiving,
        executionType
      );

      if (!order) {
        throw new Error('Failed to create order');
      }

      navigate(`${ADVANCED_PROGRESS_PAGE}/${order.bulkOrderId}`);

      setIsWaitingForConfirmation(true);

      const result = await withdraw({
        sendedAmount: +order.paymentAmount,
        sendedCurrency: currencyFrom,
        network: networkFrom,
        walletReceiver: order.paymentAddress,
      });

      if (!result) {
        setIsCanceled(true);
      }
    } catch (error) {
      if (
        (error as Error).message === 'ACTION_REJECTED' &&
        order &&
        networkFrom
      ) {
        bulkApi.closeBulkOrder(order.bulkOrderId, networkFrom);
      } else if (order && networkFrom) {
        bulkApi.setOrderAsFailed(order.bulkOrderId, networkFrom);
      }
      setIsCanceled(true);
      showSendError(error);
    } finally {
      setIsWaitingForConfirmation(false);
      setIsLoading(false);
    }
  };

  const isAmountInvalid = useMemo(() => {
    if (!currencyFrom?.max_send || !currencyFrom.min_send) return;

    const isAmountDirty = receiving.some(({ amount }) => !!amount);

    const isSomeLessThanMin = receiving.some(
      ({ amount }) => +amount < currencyFrom.min_send && !!amount
    );

    if (
      (isSomeLessThanMin && isAmountDirty) ||
      +totalToSent > currencyFrom?.max_send
    ) {
      return true;
    }
    return false;
  }, [currencyFrom, totalToSent, receiving]);

  const shouldDisable =
    isDisabled || isLoading || isAmountInvalid || isEstimationLoading;

  return (
    <>
      {isFromConnected ? (
        <button
          className="btn btnSecondary w-100 margin-top btnGradient"
          onClick={handleSubmit}
          disabled={shouldDisable}
        >
          {isWrongNetwork && formStep === FormStep.Summary
            ? 'Switch Network'
            : 'Create Advanced Transfer'}
        </button>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          className="btn btnSecondary w-100 margin-top btnGradient"
        >
          {isFromEVM
            ? 'Connect Wallet'
            : 'Connect Required Wallets to Transfer'}
        </button>
      )}
      <ConnectWalletModalWithDisabled
        openModal={isOpen}
        setOpenModal={setIsOpen}
        networkType={networkFrom?.network_type}
        onlySelectedNetworkType
      />
      <UnsupportedChainDialog
        isOpen={isUnsupportedDialogOpen}
        onOpenChange={setIsUnsupportedDialogOpen}
      />
    </>
  );
}
