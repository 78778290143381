import { ReactNode } from 'react';
import './styles.css';
import { LoaderSmall } from '../../../../components/LoaderSmall';

interface AmountInputProps {
  value: string;
  onChange?: (amount: string) => void;

  readonly?: boolean;
  label: ReactNode;
  showMax?: boolean;
  isLoading?: boolean;

  children?: ReactNode;
}

export function AmountInput({
  value,
  onChange,
  readonly,
  label,
  children,
  showMax,
  isLoading,
}: AmountInputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    const value = e.target.value;
    const splitedInput = value.split('.');
    if (splitedInput[0]?.length > 5) return;
    if (splitedInput[1]?.length > 6) return;
    if (!/^[0-9]*(\.([0-9]+)?)?$/.test(value)) return;

    onChange?.(value);
  };

  const handleBlur = () => {
    if (value.startsWith('.')) {
      onChange?.('0' + value);
    }
  };

  return (
    <div className="amount-input">
      {label}
      <div className="d-flex">
        {isLoading && (
          <span
            className="d-flex flex-grow-1 align-items-center"
            style={{ height: '30px' }}
          >
            <LoaderSmall width={20} height={20} />
          </span>
        )}
        {!isLoading && (
          <input
            type="text"
            className="amount-input--value"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={readonly}
            placeholder="0.00"
          />
        )}
        {children}
      </div>
    </div>
  );
}
