import { adventures } from '@/pages/PhaseAdventuresPage/config';
import { useMemo } from 'react';
import { PhaseItem } from '../JourneyItem';
import { Other } from '../Other';
import { PhasesBlock } from '../PhasesBlock';

export function ActivitiesTab() {
  const activeAdventures = useMemo(() => {
    return adventures.filter(adventure => {
      const currentDate = new Date();
      const startDate = new Date(adventure.startDate);
      const endDate = new Date(adventure.endDate);

      return currentDate >= startDate && currentDate <= endDate;
    });
  }, []);

  return (
    <div className="notifications-menu--tabs-content">
      <PhasesBlock />
      {activeAdventures.length > 0 && (
        <div className="notifications-menu--group">
          <div className="notifications-menu--group-heading">Adventures</div>
          {activeAdventures.map(adventure => (
            <PhaseItem
              key={adventure.phase}
              href={adventure.link}
              thumbnailSrc={adventure.nftImage}
              backgroundSrc={adventure.notificationBg}
              startDate={adventure.startDate}
              endDate={adventure.endDate}
            >
              {adventure.notificationText}
            </PhaseItem>
          ))}
        </div>
      )}
      <div className="notifications-menu--group">
        <div className="notifications-menu--group-heading">Other</div>
        <Other />
      </div>
    </div>
  );
}
