import { Adventure } from '..';
import { ROUTES } from '@/constants/routes.constants';
import nftImage from '@/Assets/images/journeys/base/nftImage.png';
import activeCardBg from '@/Assets/images/journeys/base/adventureCardBg.png';
import { Phase } from '@/types/phases';

export const baseAdventure: Adventure = {
  phase: Phase.BaseJourney,

  anchorClassName: 'base',

  nftImage,
  activeCardBg,
  heading: <>Base Adventure</>,
  startDate: '2024-05-24T11:00:00.000Z',
  endDate: '2024-05-31T11:00:00.000Z',
  link: ROUTES.PHASE_BASE_ADVENTURE_PAGE,
  linkText: 'Take Base Adventure',
};
