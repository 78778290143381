export enum OrderStatuses {
  PENDING = 'Pending',
  WAIT_DEPOSIT = 'Wait deposit',
  DEPOSIT_FAILED = 'Deposit failed',
  DEPOSITED = 'Deposited',
  SENDING = 'Sending',
  SENT = 'Sent',
  COMPLETE = 'Completed',
  SEND_FAILED = 'Send failed',
  REJECTED = 'Rejected',
  EXPIRED = 'Expired',
}

export enum BulkOrderStatuses {
  PENDING = 'Pending',
  WAIT_DEPOSIT = 'Wait deposit',
  DEPOSIT_FAILED = 'Deposit failed',
  DEPOSITED = 'Deposited',
  PROCESSING = 'Processing',
  COMPLETE = 'Completed',
  REJECTED = 'Rejected',
  EXPIRED = 'Expired',
}

export enum OrderSearchStatuses {
  ALL = 'all',
  COMPLETE = 'complete',
  IN_PROGRESS = 'in_progress',
  HISTORY = 'history',
}

export enum ExecutionType {
  Sequence = 'Sequence',
  Fast = 'Fast',
}

export enum OrderType {
  BULK = 'bulk',
  STANDARD = 'standard',
  SWAP = 'swap',
}

export enum NetworkCategory {
  All = 'All',
  L1EVM = 'L1 EVM',
  L2EVM = 'L2 EVM',
  L1NonEVM = 'L1 Non-EVM',
  L2BTC = 'L2 BTC',
}

export enum NetworkCategoryExtended {
  All = 'All',
  L1EVM = 'L1 EVM',
  L2EVM = 'L2 EVM',
  L1NonEVM = 'L1 Non-EVM',
  L2BTC = 'L2 BTC',
  TESTNET = 'Testnet',
}
