import {
  PopoverClose,
  PopoverContent,
  PopoverContentProps,
  PopoverPortal,
  PopoverProps,
  Root,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import { Fragment, ReactNode, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { BASE_URL } from '../../../../Config/ApiConfig';
import { Image } from '../../../../components/Image';
import { ICurrency } from '../../../../types/apiTypes';
import { useAdvancedWalletStore } from '../../store';
import './styles.css';

interface AdvancedTokenSelectProps {
  children?: ReactNode | ((currency: ICurrency | undefined) => ReactNode);
  rootProps?: PopoverProps;
  contentProps?: PopoverContentProps;
  value: ICurrency | undefined;
  onChange: (currency: ICurrency | undefined) => void;
}

export function AdvancedTokenSelect({
  children,
  rootProps,
  contentProps,
  value,
  onChange,
}: AdvancedTokenSelectProps) {
  const [currencies, networkFrom] = useAdvancedWalletStore(
    useShallow(s => [s.currencies, s.networkFrom])
  );

  const tokens = useMemo(() => {
    const tokens: ICurrency[] = [];

    for (const token of currencies) {
      if (token.contract.network.id === networkFrom?.id) {
        tokens.push(token);
      }
    }

    return tokens;
  }, [currencies, networkFrom?.id]);

  return (
    <div className="advanced-network-select w-100" key="test">
      <Root {...rootProps}>
        {children instanceof Function ? children?.(value) : children}
        <PopoverPortal>
          <PopoverContent
            align="end"
            avoidCollisions={false}
            sideOffset={8}
            className="advanced-token-select-menu"
            autoFocus={false}
            onOpenAutoFocus={e => e.preventDefault()}
            {...contentProps}
          >
            <div className="d-flex flex-column">
              {tokens.map(token => (
                <Fragment key={token.id}>
                  <PopoverClose
                    className={`advanced-token-select-menu-item ${
                      token.id === value?.id
                        ? 'advanced-token-select-menu-item-active'
                        : ''
                    }`}
                    onClick={() => {
                      onChange(token);
                    }}
                    disabled={!token.active}
                  >
                    <span className="d-flex justify-content-center align-items-center">
                      <span className="smallCircleBtn smallCircleBtnCoinIcon me-2">
                        <Image
                          withSmoothLoading
                          src={BASE_URL + token.image_url}
                          className="coinImg"
                          alt="coin"
                        />
                      </span>

                      <span className="card-text-2 text-white me-2">
                        {token.symbol}
                      </span>
                    </span>
                    {token.active && (
                      <span
                        className={clsx(
                          'advanced-token-select-menu-item--indicator',
                          token.id === value?.id && 'active'
                        )}
                      />
                    )}
                  </PopoverClose>
                </Fragment>
              ))}
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Root>
    </div>
  );
}
