import sunriseNFTCard from '@/Assets/images/journeys/late/nftCard.png';
import middayNFTCard from '@/Assets/images/journeys/midday/nftCard.png';
import middayNFT from '@/Assets/images/phases/Midday.png';
import middayJourneyFilter4 from '@/Assets/images/phases/MiddayJourneyFilter4.png';
import midnightJourneyFilter3 from '@/Assets/images/phases/MidnightJourneyFilter3.png';
import midnightJourneyFilter4 from '@/Assets/images/phases/MidnightJourneyFilter4.png';
import sunriseNFT from '@/Assets/images/phases/Sunrise.png';
import sunsetJourneyFilter3 from '@/Assets/images/phases/SunsetJourneyFilter3.png';
import sunsetJourneyFilter4 from '@/Assets/images/phases/SunsetJourneyFilter4.png';
import { PHASES_API_URL } from '@/Config/ApiConfig';
import { RightArrow } from '@/components/TransactionTrackingNotification/icons/Rightarrow';
import { ROUTES } from '@/constants/routes.constants';
import { usePhaseBannerStore } from '@/stores/phaseBanner.store';
import { useWalletStore } from '@/stores/wallet.store';
import { OrderType } from '@/types/enums';
import { Phase } from '@/types/phases';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import './styles.css';

export function PhaseBanner() {
  const { isConnected } = useAccount();
  const { currentOrder } = useWalletStore();
  const [message, images] = usePhaseBannerStore(s => [s.title, s.images]);

  const title = useMemo(() => {
    let i = 0;

    if (!message) return '';

    return message.replaceAll('image', () => {
      const img = `<img src="${PHASES_API_URL}${
        images?.[i] ?? ''
      }" alt="image${i}" />`;
      i++;
      return img;
    });
  }, [message, images]);

  const phase = useMemo(() => {
    switch (currentOrder?.type) {
      case OrderType.SWAP:
        return Phase.Midday;

      default:
        return Phase.Sunrise;
    }
  }, [currentOrder?.type]);

  const link = useMemo(() => {
    switch (phase) {
      case Phase.Midday:
        return ROUTES.PHASE_MIDDAY_PAGE;
      case Phase.Sunrise:
      default:
        return ROUTES.PHASE_JOURNEYS_PAGE;
    }
  }, [phase]);

  const isCompleted = useMemo(() => {
    return message.startsWith('You have completed');
  }, [message]);

  if (!isConnected || message?.length <= 0) return null;

  return (
    <Link to={link} className={clsx('bridge-phase-banner', phase)}>
      <span className="bridge-phase-banner-content">
        <span className="bridge-phase-banner-nfts">
          <span className="phases-NFTs-progress">
            <img
              src={sunriseNFT}
              className="phases-NFTs-progress-item active"
              alt=""
            />
            <img
              src={phase === Phase.Midday ? middayNFT : middayJourneyFilter4}
              className={clsx(
                'phases-NFTs-progress-item',
                phase === Phase.Midday && 'active'
              )}
              alt=""
            />
            <img
              src={
                phase === Phase.Midday
                  ? sunsetJourneyFilter3
                  : sunsetJourneyFilter4
              }
              className="phases-NFTs-progress-item"
              alt=""
            />
            <img
              src={
                phase === Phase.Midday
                  ? midnightJourneyFilter3
                  : midnightJourneyFilter4
              }
              className="phases-NFTs-progress-item"
              alt=""
            />
          </span>
          {phase === Phase.Sunrise && '1'}
          {phase === Phase.Midday && '2'} of 4 Phases NFTs
        </span>
        <span
          className="bridge-phase-banner--heading"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <span className="bridge-phase-banner--action">
          {!isCompleted && (
            <span className="text-gradient">{phase} Journey</span>
          )}
          {isCompleted && (
            <span className="text-gradient">Mint {phase} NFT</span>
          )}
          <RightArrow width={14} height={14} color="currentColor" />
        </span>
      </span>
      <span className="bridge-phase-banner--image-container">
        {phase === Phase.Sunrise && (
          <img src={sunriseNFTCard} draggable={false} alt="" />
        )}
        {phase === Phase.Midday && (
          <img src={middayNFTCard} draggable={false} alt="" />
        )}
      </span>
    </Link>
  );
}
