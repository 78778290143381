import { connectorsForWallets, getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  bitgetWallet,
  coin98Wallet,
  coinbaseWallet,
  foxWallet,
  imTokenWallet,
  metaMaskWallet,
  okxWallet,
  phantomWallet,
  rabbyWallet,
  rainbowWallet,
  safepalWallet,
  tokenPocketWallet,
  trustWallet,
  walletConnectWallet,
  zerionWallet,
} from '@rainbow-me/rainbowkit/wallets';

import {
  arbitrum,
  arbitrumNova,
  arbitrumSepolia,
  avalanche,
  base,
  bsc,
  bscTestnet,
  fantomTestnet,
  goerli,
  kroma,
  linea,
  lineaTestnet,
  mainnet,
  manta,
  mantaTestnet,
  mantle,
  opBNB,
  optimism,
  optimismSepolia,
  polygon,
  polygonMumbai,
  polygonZkEvm,
  scroll,
  scrollSepolia,
  sepolia,
  zkSync,
  zkSyncSepoliaTestnet,
  zora,
  zoraTestnet,
  metis,
  Chain,
  gravity,
} from 'wagmi/chains';
import {
  arbitrumOne,
  b2_network,
  bitlayer,
  blast,
  blastTestnet,
  bob,
  merlin,
  mintChain,
  mode,
  rariChain,
  rariChainTestnet,
  taiko,
  taikoHekla,
  xLayer,
  zetaChain,
  zkLinkNova,
  zircuit,
} from './customChains';
// import { tobiFunWallet } from './customConnectors/tobiFun';
import { createConfig, http } from 'wagmi';
import { tobiFunWallet } from './customConnectors/tobiFun';

export const chains: Chain[] = [
  mainnet,
  goerli,
  sepolia,
  bscTestnet,
  fantomTestnet,
  lineaTestnet,
  polygonMumbai,
  scrollSepolia,
  zkSyncSepoliaTestnet,
  bsc,
  linea,
  polygon,
  avalanche,
  arbitrumNova,
  optimism,
  arbitrum,
  base,
  zkSync,
  scroll,
  polygonZkEvm,
  arbitrumOne,
  zora,
  zoraTestnet,
  manta,
  mantaTestnet,
  mantle,
  kroma,
  mode,
  arbitrumSepolia,
  optimismSepolia,
  blast,
  blastTestnet,
  zetaChain,
  zkLinkNova,
  rariChainTestnet,
  rariChain,
  xLayer,
  taiko,
  taikoHekla,
  opBNB,
  merlin,
  b2_network,
  bob,
  bitlayer,
  mintChain,
  metis,
  zircuit,
  gravity,
];
export const WALLET_CONNECT_PROJECT_ID = 'ef1ab3184c753ffb2b5c10f01449260c';

coinbaseWallet.preference = 'eoaOnly';

export const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet,
        okxWallet,
        trustWallet,
        rabbyWallet,
        phantomWallet,
        coinbaseWallet,
        rainbowWallet,
        bitgetWallet,
        coin98Wallet,
        imTokenWallet,
        safepalWallet,
        zerionWallet,
        foxWallet,
        tokenPocketWallet,
        tobiFunWallet,
        walletConnectWallet,
      ],
    },
  ],
  {
    projectId: WALLET_CONNECT_PROJECT_ID,
    appName: 'RetroBridge',
  }
);

export const wagmiConfig = createConfig<any, any>({
  chains: chains,
  connectors,
  transports: chains.reduce(
    (acc, chain) => {
      acc[chain.id] = http();
      return acc;
    },
    {} as Record<Chain['id'], ReturnType<typeof http>>
  ),
});
