import { ReactNode } from 'react';
import { multiChainAdventure } from './adventures/multiChain';
import { zkSyncAdventure } from './adventures/zkSync';
import { lineaAdventure } from './adventures/linea';
import { Phase } from '../../../types/phases';
import { baseAdventure } from './adventures/base';
import { scrollAdventure } from './adventures/scroll';
import { blastAdventure } from './adventures/blast';
import { taikoAdventure } from './adventures/taiko';
import { tonAdventure } from './adventures/ton';

export interface Adventure {
  /**
   * Phase of the adventure
   */
  phase: Phase;

  /**
   * Class name for the anchor element
   */
  anchorClassName?: string;

  /**
   * Image of the NFT
   */
  nftImage: string;
  /**
   * Background color of the active card
   */
  activeCardBg?: string;

  /**
   * Heading of the adventure card
   */
  heading: ReactNode;
  /**
   * Start date of the campaign
   */
  startDate: string;
  /**
   * End date of the campaign
   */
  endDate: string;
  /**
   * Link to the adventure page
   */
  link: string;
  /**
   * Text for the link
   */
  linkText: ReactNode;

  /**
   * Banner for the adventure on "All campaigns" page
   */
  banner?: ReactNode;

  notificationText?: ReactNode;
  notificationBg?: string;
}

export const adventures: Adventure[] = [
  tonAdventure,
  taikoAdventure,
  blastAdventure,
  scrollAdventure,
  baseAdventure,
  multiChainAdventure,
  zkSyncAdventure,
  lineaAdventure,
];
