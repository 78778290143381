import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import qs from 'query-string';
import { types } from 'starknet';

import { Address } from '@ton/core';
import { BASE_URL, BULK_API_URL } from '../Config/ApiConfig';
import { maxPlatformDecimals } from '../constants/numbers';
import { NetworkTypes } from '../providers/web3Provider';
import { AppTypedData } from '@/starknet/types/app_typed_data';
import { appStore } from '../stores/app.store';
import { networksStore } from '../stores/networks.store';
import { useSignStore } from '../stores/sign.store';
import { walletStore } from '../stores/wallet.store';
import {
  GetCurrencyPricesResponse,
  IAmountOut,
  IApiResp,
  ICurrency,
  IGetNetwork,
  ILimits,
  INetwork,
  IOrder,
  IOrderInfo,
  IOrderResponse,
  IReceiver,
  ISimpleApiResp,
  ITransactionFee,
} from '../types/apiTypes';
import { OrderSearchStatuses } from '../types/enums';
import { isEth, logInGroup } from '../utils';
import { getCookie } from '../utils/cookies';
import { toFixed } from '../utils/numbers';
import { ApiService as Api } from './CoreApi';
import { mockTron } from '@/constants/tron';

export const isDevelopment = process.env.NODE_ENV === 'development';
export const networkTypeName = 'network-type';
export const apiKey = 'api-key';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const api_key = getCookie('api_key');
  if (api_key) {
    config.headers[apiKey] = api_key;
  }
  return config;
};

export const networkTypeSelector = (network: INetwork) => {
  const type =
    network.network_type === NetworkTypes.ZK_SYNC_ERA
      ? NetworkTypes.EVM
      : network.network_type;

  return type;
};

export class ApiService {
  private _api: Api;
  constructor() {
    this._api = new Api(BASE_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.defaults.headers.put['Content-Type'] =
      'application/json';
    this._api.instance.interceptors.request.use(config =>
      requestInterceptor(config)
    );
  }

  async getNetwork() {
    const { setNetworks, setIsLoading } = networksStore.getState();
    const url = '/api/network';
    try {
      setIsLoading(true);

      const { data }: IGetNetwork = await this._api.get(url);

      if (!data.some(network => network.chainId === mockTron.chainId)) {
        data.push(mockTron);
      }

      setNetworks(data);

      return data;
    } catch (error) {
      isDevelopment && console.log(error, '======error getNetworks');
    } finally {
      setIsLoading(false);
    }
    return undefined;
  }

  async getCurrency(networkFromId: string, networkToId: string) {
    const { setSourceCurrencies, setIsLoading } = appStore.getState();

    if (!networkFromId || !networkToId) {
      return;
    }
    const url = `/api/currency?network_from_id=${networkFromId}&network_to_id=${networkToId}`;
    try {
      setIsLoading(true);

      const res: IApiResp<ICurrency[]> = await this._api.get(url);
      const sortedData = res.data.sort((a, b) => {
        if (isEth(a.symbol)) return -1;
        if (isEth(b.symbol)) return 1;
        return b.symbol.localeCompare(a.symbol);
      });
      setSourceCurrencies(sortedData);
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
    } finally {
      setIsLoading(false);
    }
  }

  async getDestinationPairs(currencyId: string, networkId: string) {
    const { setIsLoading, setDestinationCurrencies } = appStore.getState();

    const url = `/api/currency/${currencyId}/pairs`;
    const queryParams = qs.stringify({
      limit: 100,
      network_to_id: networkId,
    });
    try {
      setIsLoading(true);

      const res: ISimpleApiResp<IApiResp<ICurrency[]>> = await this._api.get(
        `${url}/?${queryParams}`
      );

      if (res.data) {
        const sortedData = res.data.data.sort((a, b) => {
          if (isEth(a.symbol)) return -1;
          if (isEth(b.symbol)) return 1;
          return b.symbol.localeCompare(a.symbol);
        });
        setDestinationCurrencies(sortedData);
      }
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
    } finally {
      setIsLoading(false);
    }
  }

  async getAllCurrencyPairs(currencyId: string) {
    const { setAllCurrencyPairs } = walletStore.getState();

    const url = `/api/currency/${currencyId}/pairs`;
    const queryParams = qs.stringify({
      limit: 100,
      excludeCrossChain: true,
    });

    try {
      const res: ISimpleApiResp<IApiResp<ICurrency[]>> = await this._api.get(
        `${url}/?${queryParams}`
      );

      if (res.data) {
        const sortedData = res.data.data.sort((a, b) => {
          if (isEth(a.symbol)) return -1;
          if (isEth(b.symbol)) return 1;
          return b.symbol.localeCompare(a.symbol);
        });
        setAllCurrencyPairs(sortedData);
      }
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
    }
    return undefined;
  }

  // NOT USED RIGHT NOW: FEES RETURNED TOGETHER WITH AMOUNT OUT
  async getTransactionFee(
    currencyFromId: string,
    currencyToId: string,
    amount = '0'
  ) {
    const { setIsLoading, setTransactionFee } = appStore.getState();
    const url = `/api/orders/fee/${currencyFromId}/${currencyToId}`;
    const params = qs.stringify({ amount });

    try {
      setIsLoading(true);

      const res: ISimpleApiResp<ITransactionFee> = await this._api.get(
        `${url}/?${params}`
      );

      if (res.data) setTransactionFee(res.data);

      setIsLoading(false);
    } catch (error) {
      isDevelopment && console.log(error, '======error getCurrency');
      setIsLoading(false);
    }
  }

  async getLimits(currencyFrom: ICurrency, currencyTo: ICurrency) {
    const { setMinSend, setMaxSend } = appStore.getState();
    const url = `/api/orders/limits/${currencyFrom.id}/${currencyTo.id}`;
    try {
      const res: ISimpleApiResp<ILimits> = await this._api.get(url);

      if (res.data) {
        setMinSend(+toFixed(res.data.min_send, maxPlatformDecimals));
        setMaxSend(+toFixed(res.data.max_send, maxPlatformDecimals));
      } else {
        setMinSend(currencyFrom.min_send);
        setMaxSend(currencyFrom.max_send);
      }
    } catch (error) {
      setMinSend(currencyFrom.min_send);
      setMaxSend(currencyFrom.max_send);

      isDevelopment && console.log(error, '======error getLimits');
      if (error instanceof AxiosError) {
        throw new Error(`${error.response?.data.message}`);
      }
    }
  }

  async getAmountOut(
    currencyFromId: string,
    currencyToId: string,
    amount: string,
    walletFrom?: string,
    walletTo?: string
  ) {
    const { setIsLoading, setAmountTo, setTransactionFee, setError } =
      appStore.getState();
    const url = `/api/orders/calculate_amount_out/${currencyFromId}/${currencyToId}`;
    const params = qs.stringify({
      amount,
      wallet_sender: walletFrom,
      wallet_receiver: walletTo,
    });
    try {
      setIsLoading(true);
      const res: ISimpleApiResp<IAmountOut & ITransactionFee> =
        await this._api.get(`${url}/?${params}`);

      if (res.data) {
        setAmountTo(toFixed(res.data.amount_out, maxPlatformDecimals));
        setTransactionFee(res.data);
      } else {
        setAmountTo('');
        setTransactionFee(null);
      }
    } catch (error) {
      setAmountTo('');
      isDevelopment && console.log(error, '======error getAmountOut');
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          if (
            error.response.data.message.startsWith(
              'Too small a amount to send.'
            )
          )
            setError(
              'Too small a amount to send. It will not cover destination fees'
            );
        } else
          setError(
            error.response?.status === 400
              ? error.response.data.message
              : 'Temporary transfer limit'
          );

        throw new Error(`${error.response?.data.message}`);
      } else {
        setError('Temporary transfer limit');
      }
    } finally {
      setIsLoading(false);
    }
  }

  async getReceiver(currencyFrom: ICurrency, currencyTo: ICurrency) {
    const { setIsLoading, setReceiver } = appStore.getState();
    const url = `/api/orders/receiver?currency_in_id=${currencyFrom?.id}&currency_out_id=${currencyTo?.id}`;
    try {
      setIsLoading(true);

      const res: IApiResp<IReceiver> = await this._api.get(url);

      if (res.data) {
        setReceiver(res.data.wallet);
      }
    } catch (error) {
      isDevelopment && console.log(error, '======error getReceiveAmount');
    } finally {
      setIsLoading(false);
    }
  }

  async getOrderById(orderId: string, setAsCurrent = true) {
    const { setCurrentOrder } = walletStore.getState();
    const url = `${BULK_API_URL}/api/bulk_order/info/${orderId}`;
    try {
      const res: { message: string; data: IOrderInfo } =
        await this._api.get(url);
      if (res && setAsCurrent) {
        setCurrentOrder(res.data);
      }
      return res.data;
    } catch (error) {
      isDevelopment && console.log(error, '======error getOrderById');
    }
    return undefined;
  }

  async sendOrder(
    fromCurrency: ICurrency,
    toCurrency: ICurrency,
    amount: string,
    wallet_sender: string,
    wallet_receiver: string
  ) {
    const { setIsLoading } = appStore.getState();
    const { setCurrentOrder } = walletStore.getState();
    const url = '/api/orders';
    const type = networkTypeSelector(fromCurrency.contract.network);
    try {
      setIsLoading(true);
      const data = {
        currency_in_id: fromCurrency.id,
        currency_out_id: toCurrency.id,
        amount,
        wallet_sender:
          type === NetworkTypes.TON
            ? Address.parse(wallet_sender).toRawString()
            : wallet_sender,
        wallet_receiver,
      };

      const res: { message: string; data: IOrder } = await this._api.post(
        url,
        JSON.stringify(data),
        {
          headers: {
            [networkTypeName]: type,
          },
        }
      );

      if (res) {
        setCurrentOrder(res.data);
      }

      return res.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        const { setResign } = useSignStore.getState();
        setResign(type);
      }
      isDevelopment && console.log(error, '======error sendOrder');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  async getOrders(
    status: OrderSearchStatuses,
    search?: string,
    wallet?: string,
    setToStore = true,
    skip = 0,
    take = 50,
    includeBulkOrders = false
  ) {
    const { setOrders, setIsLoading } = walletStore.getState();
    const url = '/api/orders';

    try {
      if (setToStore) setIsLoading(true);

      let data: IOrder[] = [];

      if (take <= 50) {
        const res: IApiResp<IOrderResponse> = await this._api.get(url, {
          params: {
            take,
            skip,
            status,
            include_bulk_orders: includeBulkOrders,
            search: search || undefined,
            wallet: wallet || undefined,
          },
        });
        data = res.data.data;
      } else {
        for (let i = 0; i * 50 < take; i++) {
          const res: IApiResp<IOrderResponse> = await this._api.get(url, {
            params: {
              take: 50,
              skip: skip + i * 50,
              status,
              include_bulk_orders: includeBulkOrders,
              search: search || undefined,
              wallet: wallet || undefined,
            },
          });

          data = [...data, ...res.data.data];
        }
      }

      if (data && setToStore) {
        setOrders(data);
      }

      if (setToStore) setIsLoading(false);
      return data;
    } catch (error) {
      isDevelopment && console.log(error, '======error getOrders');
      setIsLoading(false);
    }
    return undefined;
  }

  async getSignMessage() {
    const url = '/api/wallet_auth/message';
    try {
      const res: ISimpleApiResp<AppTypedData> = await this._api.get(url);

      return res;
    } catch (error) {
      isDevelopment && console.log(error, '======error getSignMessage');
      throw error;
    }
  }

  async validateSignedMessage(
    wallet_address: string,
    network_type: NetworkTypes,
    signature: string | types.Signature
  ) {
    const url = '/api/wallet_auth/message';

    const newApi = new Api(BULK_API_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    try {
      await this._api.post(url, { wallet_address, network_type, signature });
      await newApi.post(url, { wallet_address, network_type, signature });
    } catch (error) {
      isDevelopment && console.log(error, '======error validateSignedMessage');
      throw error;
    }
  }

  async checkWallet(wallet_address: string, network_type: NetworkTypes) {
    const url = `/api/wallet_auth/wallet/${wallet_address}`;
    try {
      await this._api.get(url, {
        headers: { [networkTypeName]: network_type },
      });
    } catch (error) {
      isDevelopment && console.log(error, '======error checkWallet');
      throw error;
    }
  }

  async sendApiKeyRequest(data: {
    name: string;
    email: string;
    telegramUsername: string;
    description: string;
  }) {
    const url = '/api/dashboard/request-api-key';
    return await this._api.post(url, data);
  }

  async getPrices(symbol?: string | string[]) {
    const url = '/api/prices';

    const response: GetCurrencyPricesResponse = await this._api.get(url, {
      params: symbol,
    });

    return response.data;
  }

  async closeOrder(orderId: string, network_type: INetwork) {
    const url = `api/orders/close/${orderId}`;

    return await this._api.put(
      url,
      {},
      {
        headers: {
          [networkTypeName]: networkTypeSelector(network_type),
        },
      }
    );
  }

  async setOrderAsFailed(orderId: string, network_type: INetwork) {
    const url = `api/orders/set_order_as_failed/${orderId}`;

    return await this._api.put(
      url,
      {},
      {
        headers: {
          [networkTypeName]: networkTypeSelector(network_type),
        },
      }
    );
  }

  async getAmountOfOrders() {
    try {
      const url = '/api/orders/get_total_count_of_orders';
      const { data } = await this._api.get<{ data: { total_count: number } }>(
        url
      );

      return data.total_count;
    } catch (error) {
      logInGroup('[API] Error while getting amount of orders', error);
    }
    return 0;
  }
}

export const api = new ApiService();
