import waitTable from '@/Assets/Icons/waitTable.svg';
import { Button } from 'react-bootstrap';

interface ConnectWalletPlaceHolderProps {
  setOpenModal: (value: boolean) => void;
}

export function ConnectWalletPlaceHolder({
  setOpenModal,
}: ConnectWalletPlaceHolderProps) {
  return (
    <div className="connectWalletPlaceholder">
      <img src={waitTable} alt="" className="emptyTableImage" />
      <span className="emptyTableText">
        To view your history please connect wallet
      </span>
      <Button
        onClick={() => setOpenModal(true)}
        className="btn btnGradientMain connectWalletPlaceholderButton"
        variant="outline-secondary"
      >
        Connect Wallet
      </Button>
    </div>
  );
}
