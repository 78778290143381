import { create } from 'zustand';
import { isValidAddress } from '../../../evm/utils/isValidAddress';
import { NetworkTypes } from '../../../providers/web3Provider';
import { isValidStarknetAddress } from '../../../starknet/utils/isValidAddress';
import { networksStore } from '../../../stores/networks.store';
import { ICurrency, INetwork } from '../../../types/apiTypes';
import { ExecutionType } from '../../../types/enums';

export enum FormStep {
  Input = 'Input',
  Summary = 'Summary',
}

export const ethSymbols = ['ETH', 'WETH'];
export const btcSymbols = ['BTC', 'WBTC'];
export const MAX_BULK_RECIPIENTS = 50;

export interface AdvancedWalletStore {
  isDirty: boolean;

  formStep: FormStep;
  setFormStep: (step: FormStep) => void;

  networkFrom?: INetwork;
  setNetworkFrom: (network: INetwork) => void;

  currencyFrom?: ICurrency;
  setCurrencyFrom: (currency: ICurrency) => void;

  currencies: ICurrency[];
  setCurrencies: (currencies: AdvancedWalletStore['currencies']) => void;

  receiving: {
    address: string;
    amount: string;
    network?: INetwork['id'];
  }[];
  executionType: ExecutionType;

  isEstimationLoading: boolean;
  setIsEstimationLoading: (value: boolean) => void;
  estimatedFee: {
    currency_out_id: string;
    platform_fee: number;
    platform_fee_in_usd: number;
    blockchain_fee: number;
    blockchain_fee_in_usd: number;
    full_fee: number;
  }[];
  setEstimatedFee: (fees: AdvancedWalletStore['estimatedFee']) => void;

  executionIntervals: Record<ExecutionType, number>;
  setExecutionIntervals: (intervals: Record<ExecutionType, number>) => void;

  shouldSetSingleNetwork: boolean;
  setShouldSetSingleNetwork: (value: boolean) => void;

  addReceiving: (address: string, amount: string, network?: string) => void;
  removeReceiving: (index: number) => void;

  setReceivingData: (
    index: number,
    data: { address: string; amount: string; network?: string }
  ) => void;
  setReceivingAddress: (index: number, address: string) => void;
  setReceivingAmount: (index: number, amount: string) => void;
  setReceivingNetwork: (
    index: number,
    network?: string,
    forAll?: boolean
  ) => void;

  setExecutionType: (type: ExecutionType) => void;

  validateForm: (isSafe?: boolean) => boolean;
  resetForm: () => void;
}

export const advancedWalletStore = create<AdvancedWalletStore>((set, get) => ({
  isDirty: false,

  formStep: FormStep.Input,
  setFormStep: (step: FormStep) =>
    set({ formStep: step, isDirty: step !== FormStep.Input }),

  networkFrom: undefined,
  setNetworkFrom: network => set({ networkFrom: network }),
  currencyFrom: undefined,
  setCurrencyFrom: currency => set({ currencyFrom: currency }),

  receiving: [],
  executionType: ExecutionType.Sequence,

  currencies: [],
  setCurrencies: currencies => set({ currencies }),

  shouldSetSingleNetwork: false,
  setShouldSetSingleNetwork: (value: boolean) =>
    set({ shouldSetSingleNetwork: value }),

  isEstimationLoading: true,
  setIsEstimationLoading: value => set({ isEstimationLoading: value }),

  estimatedFee: [],
  setEstimatedFee: fees => set({ estimatedFee: fees }),

  executionIntervals: {
    [ExecutionType.Sequence]: 0,
    [ExecutionType.Fast]: 0,
  },
  setExecutionIntervals: intervals => set({ executionIntervals: intervals }),

  addReceiving: (address, amount, network) => {
    if (get().receiving.length > MAX_BULK_RECIPIENTS) return;

    set({
      receiving: [...get().receiving, { address, amount, network }],
      isDirty: address !== '' || amount !== '',
    });
  },
  removeReceiving: (index: number) => {
    if (get().receiving.length <= 2) {
      const { setReceivingData } = get();

      setReceivingData(index, {
        address: '',
        amount: '',
        network: undefined,
      });
      return;
    }
    set({
      receiving: get().receiving.filter((_, i) => i !== index),
    });
  },

  setReceivingData: (index: number, data) => {
    if (index < 0) return;

    const { addReceiving } = get();

    if (index >= get().receiving.length) {
      addReceiving(data.address, data.amount, data.network);
    }

    set({
      receiving: get().receiving.map((r, i) =>
        i === index ? { ...r, ...data } : r
      ),
      isDirty: data.address !== '' || data.amount !== '',
    });
  },
  setReceivingAddress: (index, address) =>
    set({
      receiving: get().receiving.map((r, i) =>
        i === index ? { ...r, address } : r
      ),
      isDirty: address !== '',
    }),
  setReceivingAmount: (index, amount) =>
    set({
      receiving: get().receiving.map((r, i) =>
        i === index ? { ...r, amount } : r
      ),
      isDirty: amount !== '',
    }),
  setReceivingNetwork: (index, network) => {
    const { shouldSetSingleNetwork } = get();
    set({
      receiving: get().receiving.map((r, i) =>
        i === index || (shouldSetSingleNetwork && !!network)
          ? { ...r, network }
          : r
      ),
      isDirty: network !== '',
    });
  },
  setExecutionType: type =>
    set({ executionType: type, isDirty: type !== ExecutionType.Sequence }),

  validateForm: (isSafe = false) => {
    const { networks } = networksStore.getState();
    const { networkFrom, currencyFrom, receiving, currencies } = get();

    if (!networkFrom) {
      if (!isSafe) throw new Error('Please select source network');
      return false;
    }

    if (!currencyFrom) {
      if (!isSafe) throw new Error('Please select source token');
      return false;
    }

    for (let i = 0; i < receiving.length; i++) {
      const { address, amount, network } = receiving[i];

      if (!address) {
        if (!isSafe) throw new Error('Please fill in the address');
        return false;
      }

      if (!amount) {
        if (!isSafe) throw new Error('Please fill in the amount');
        return false;
      }

      if (!network) {
        if (!isSafe) throw new Error('Please select network');
        return false;
      }

      const networkTo = networks.find(n => n.id === network);

      if (
        networkTo?.network_type === NetworkTypes.EVM &&
        !isValidAddress(address)
      ) {
        if (!isSafe) throw new Error('Invalid EVM address');
        return false;
      }

      if (
        networkTo?.network_type === NetworkTypes.STARKNET &&
        !isValidStarknetAddress(address)
      ) {
        if (!isSafe) throw new Error('Invalid Starknet address');
        return false;
      }

      if (!network || !networkTo) {
        if (!isSafe) throw new Error('Please select network');
        return false;
      }

      if (parseFloat(amount) < currencyFrom.min_send) {
        if (!isSafe)
          throw new Error('The amount to send is less than the minimal limit');
        return false;
      }

      if (parseFloat(amount) > currencyFrom.max_send) {
        if (!isSafe)
          throw new Error('The amount to send exceeds the maximum limit');
        return false;
      }

      const isETH = (symbol: string) => ethSymbols.includes(symbol);
      const isBtc = (symbol: string) => btcSymbols.includes(symbol);

      const currencyTo = currencies.find(
        c =>
          (c.symbol === currencyFrom.symbol ||
            (isETH(currencyFrom.symbol) && isETH(c.symbol)) ||
            (isBtc(currencyFrom.symbol) && isBtc(c.symbol))) &&
          c.contract.network.id === networkTo?.id
      );

      if (!currencyTo) {
        if (!isSafe)
          throw new Error('Can not find currency for selected network');
        return false;
      }
    }

    return true;
  },

  resetForm: () => {
    set({
      isDirty: false,
      formStep: FormStep.Input,
      networkFrom: undefined,
      currencyFrom: undefined,
      receiving: [],
      executionType: ExecutionType.Sequence,
    });
  },
}));

export const useAdvancedWalletStore = advancedWalletStore;
