import { useTonAddress } from '@tonconnect/ui-react';
import { useCallback, useEffect, useState } from 'react';
import { useAccount as useEVMAccount } from 'wagmi';

import { useStarknetAccount } from '@/starknet/hooks/account';
import { getEvmBalance } from '../evm/utils';
import { NetworkTypes } from '../providers/web3Provider';
import { getStarknetBalance } from '../starknet/utils/getStarknetBalance';
import { getTonBalance } from '../ton/utils';
import { ICurrency, INetwork } from '../types/apiTypes';
import { getRPCByNetwork } from '../utils/getRpcUrl';
import { useDebouncedCallback } from './useDebouncedCallback';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { getSolanaBalance } from '@/solana/utils/getSolanaBalance';

export const useBalance = (
  network: INetwork | undefined,
  token: ICurrency | undefined
) => {
  const { address } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const tonAddress = useTonAddress();
  const { publicKey: solanaAddress } = useSolanaWallet();

  const [balance, setBalance] = useState('0');
  const [loading, setLoading] = useState(true);

  const fetchBalanceCallback = useCallback(async () => {
    try {
      setLoading(true);

      if (!network) return;
      const rpcItem = await getRPCByNetwork(network);

      const isEVM =
        (network.network_type === NetworkTypes.EVM &&
          token?.contract.network.network_type === NetworkTypes.EVM) ||
        (network.network_type === NetworkTypes.ZK_SYNC_ERA &&
          token?.contract.network.network_type === NetworkTypes.ZK_SYNC_ERA);

      const isStarknet =
        network.network_type === NetworkTypes.STARKNET &&
        token?.contract.network.network_type === NetworkTypes.STARKNET;

      const isTon =
        network.network_type === NetworkTypes.TON &&
        token?.contract.network.network_type === NetworkTypes.TON;

      const isSol =
        network.network_type === NetworkTypes.SOLANA &&
        token?.contract.network.network_type === NetworkTypes.SOLANA;

      if (address && isEVM) {
        const balance = await getEvmBalance(
          address,
          rpcItem.rpc,
          token?.contract.address,
          token?.decimals
        );
        return setBalance(balance);
      } else if (starknetAddress && isStarknet) {
        const balance = await getStarknetBalance(
          starknetAddress,
          rpcItem.rpc,
          true,
          token?.contract.address,
          token?.decimals
        );
        return setBalance(balance);
      } else if (tonAddress && isTon) {
        const balance = await getTonBalance(
          tonAddress,
          rpcItem.rpc,
          token?.contract.address,
          token?.decimals
        );
        return setBalance(balance);
      } else if (solanaAddress && isSol) {
        const balance = await getSolanaBalance(
          solanaAddress.toBase58(),
          rpcItem.rpc,
          token.contract.address,
          token.decimals
        );
        return setBalance(balance);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('unhandled error');
    } finally {
      setLoading(false);
    }
  }, [address, starknetAddress, tonAddress, solanaAddress, network, token]);

  const fetchBalance = useDebouncedCallback(fetchBalanceCallback, 500);

  //useEffect(() => fetchBalance(), [fetchBalance]);

  useEffect(() => {
    if (network && token) {
      fetchBalance();
    } else {
      setBalance('0');
    }
  }, [network, token, fetchBalance]);

  return { balance, loading };
};
