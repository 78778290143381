import { ArrowBackIcon } from '@/Assets/Icons/arrowBack';
import { BASE_URL } from '@/Config/ApiConfig';
import { Button } from 'react-bootstrap';
import { FormStep, useAdvancedWalletStore } from '../../store';
import { SelectReceivingAddress } from '../SelectReceivingAddress';
import { SubmitButton } from '../SubmitButton';
import { SummaryBlock } from './SummaryBlock';
import './styles.css';

export function AdvancedTransferSummary() {
  const [networkFrom, currencyFrom, setFormStep] = useAdvancedWalletStore(s => [
    s.networkFrom,
    s.currencyFrom,
    s.setFormStep,
  ]);

  return (
    <div className="form-info-mt w-100">
      <div
        style={{
          maxWidth: '746px',
          width: '100vw',
        }}
        className="formBg mb-0 form-max-width defaultRadius send-advanced-form"
      >
        <div className="transitionHeight formBody text-white align-items-center send-form-min-height">
          <h1 className="advanced-transfer-heading">Your Transfer Summary</h1>
          <div className="advanced-from-input d-flex justify-content-between gap-2">
            <div className="d-flex flex-column gap-2 flex-grow-1 ">
              <div className="bold advanced-summary-header-title">Network</div>
              <div className="w-100 d-flex flex-direction-row align-items-center flex-nowrap">
                <div
                  style={{ justifyContent: 'left' }}
                  className="d-flex border-0 bg-opacity-10 advanced-summary-header-network padding-6 cursor-default pointer-events-none"
                >
                  <div className="d-flex coinBtn border-0 p-1">
                    <img
                      src={BASE_URL + networkFrom?.network_image_url}
                      alt="token"
                      className="ms1 networkIcon"
                    />
                  </div>
                  <div className="mx-1 networkSelectBtn-text">
                    {networkFrom?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="bold advanced-summary-header-title">Token</div>
              <div className="d-flex my-auto advanced-summary-header-token">
                <div className="w-100 d-flex flex-direction-row align-items-center flex-nowrap">
                  <div className="d-flex align-items-center gap-2 p-0 border-0 bg-opacity-10 padding-6 cursor-default pointer-events-none">
                    <div className="d-flex coinBtn border-0 p-1">
                      <img
                        src={BASE_URL + currencyFrom?.image_url}
                        alt="token"
                        className="networkIcon"
                      />
                    </div>
                    <div className="mx-1 networkSelectBtn-text">
                      {currencyFrom?.symbol}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="advanced-transfer-divider" />
          <SelectReceivingAddress label="List of recipients" disabled />
          <hr className="advanced-transfer-divider" />
          <SummaryBlock />
          <div className="d-flex flex-column gap-4">
            <SubmitButton />
            <div className="center">
              <Button
                variant="link"
                onClick={() => setFormStep(FormStep.Input)}
                className="d-flex align-items-center backBtn ms-auto me-auto text-white"
              >
                <ArrowBackIcon className="arroBack" />

                <div className="ms-2">Back</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
