import { Adventure } from '..';
import activeCardBg from '@/Assets/images/journeys/scroll/adventureCardBg.png';
import nftImage from '@/Assets/images/journeys/scroll/nftImage.png';
import { ROUTES } from '@/constants/routes.constants';
import { Phase } from '@/types/phases';

export const scrollAdventure: Adventure = {
  phase: Phase.ScrollJourney,

  anchorClassName: 'scroll',

  nftImage,
  activeCardBg,
  heading: <>Scroll Sessions Adventure</>,
  startDate: '2024-05-30T14:30:00.000Z',
  endDate: '2024-06-06T14:30:00.000Z',
  link: ROUTES.PHASE_SCROLL_ADVENTURE_PAGE,
  linkText: 'Take Scroll Sessions Adventure',
};
