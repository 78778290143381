import { useTonAddress } from '@tonconnect/ui-react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { useDebounce, useEffectOnce, useWindowSize } from 'usehooks-ts';

import { GitBook } from '@/Assets/Icons/GitBoook';
import { AppModeSwitch } from '@/components/AppModeSwitch';
import { NetworkTypes } from '@/providers/web3Provider';
import { bulkApi } from '@/services/BulkApi';
import { api } from '@/services/api';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useNetworksStore } from '@/stores/networks.store';
import { useAccount as useEVMAccount } from 'wagmi';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useShallow } from 'zustand/react/shallow';
import { AdvancedTransferSummary } from './components/AdvancedTransferSummary';
import { AdvancedInfoBlock } from './components/AdvencedInfoBlock';
import { FormPlaceholder } from './components/FormPlaceholder';
import { NetworkFromInput } from './components/NetworkFromInput';
import { SelectExecutionType } from './components/SelectExecutionType';
import { SelectReceivingAddress } from './components/SelectReceivingAddress';
import { SubmitButton } from './components/SubmitButton';
import { TokenFromInput } from './components/TokenFromInput';
import { FormStep, useAdvancedWalletStore } from './store';
import './styles.css';

interface SendAdvancedProps {
  openWithoutAnimation?: boolean;
  onOpenChange: (isOpen: boolean) => void;
  initialHeight?: number;
}

export const ANIMATION_DURATION_S = 0.2;

export function SendAdvanced({
  openWithoutAnimation,
  onOpenChange,
  initialHeight,
}: SendAdvancedProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [shouldIgnoreAnimation, setShouldIgnoreAnimation] =
    useState(openWithoutAnimation);
  const isCheckedAnimation = useDebounce(
    isChecked,
    shouldIgnoreAnimation ? 0 : ANIMATION_DURATION_S * 1000
  );
  const [
    formStep,
    receiving,
    networkFrom,
    resetForm,
    addReceiving,
    isDirty,
    currencies,
    setIsEstimationLoading,
    currencyFrom,
  ] = useAdvancedWalletStore(state => [
    state.formStep,
    state.receiving,
    state.networkFrom,
    state.resetForm,
    state.addReceiving,
    state.isDirty,
    state.currencies,
    state.setIsEstimationLoading,
    state.currencyFrom,
  ]);
  const networks = useNetworksStore(s => s.networks);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  useEffectOnce(() => {
    const shouldIgnoreAnimation = openWithoutAnimation;
    if (shouldIgnoreAnimation) {
      setShouldIgnoreAnimation(shouldIgnoreAnimation);
    }
    setTimeout(
      () => {
        setIsChecked(true);
        window.history.replaceState(null, '');
      },
      shouldIgnoreAnimation ? 0 : ANIMATION_DURATION_S * 1000
    );
  });

  useEffect(() => {
    if (networks.length <= 0 || !networkFrom?.id) return;

    if (receiving.length === 0) {
      addReceiving('', '', undefined);
      addReceiving('', '', undefined);
    }
  }, [receiving.length, networks, networkFrom?.id]);

  const receivingNetworks = useAdvancedWalletStore(
    useShallow(s => [...new Set(s.receiving.map(r => r.network))])
  );

  useEffect(() => {
    if (!networkFrom?.id || receivingNetworks.length <= 0) return;

    bulkApi.getCurrencies([
      networkFrom.id,
      ...(receivingNetworks.filter(el => el !== undefined) as string[]),
    ]);
  }, [networkFrom?.id, receivingNetworks]);

  useEffect(() => {
    if (!currencyFrom?.id) return;
    api.getAllCurrencyPairs(currencyFrom.id);
  }, [currencyFrom?.id]);

  useEffect(() => {
    bulkApi.getExecutionTimes();
  }, []);

  const { address: EVMAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const tonAddress = useTonAddress();

  const address = useMemo(() => {
    switch (networkFrom?.network_type) {
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;
      case NetworkTypes.SOLANA:
        return solanaAddress?.toBase58();
      default:
        return EVMAddress;
    }
  }, [
    networkFrom?.network_type,
    starknetAddress,
    tonAddress,
    solanaAddress,
    EVMAddress,
  ]);

  useEffect(() => {
    if (
      !currencies.length ||
      networks.length <= 0 ||
      receivingNetworks.length <= 0
    )
      return;

    setIsEstimationLoading(true);

    const timer = setTimeout(() => {
      bulkApi.getEstimatedFee(address, receiving).finally(() => {
        setIsEstimationLoading(false);
      });
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [
    address,
    receiving,
    currencies,
    networks,
    receivingNetworks,
    currencyFrom?.id,
  ]);

  useBeforeUnload(e => {
    if (
      isChecked &&
      (isDirty || receiving.some(r => !!r.address || !!r.amount || !r.network))
    ) {
      e.preventDefault();
      return true;
    }
    return undefined;
  });

  const isOpenChangeTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleSwitchChange = (isChecked: boolean) => {
    setShouldIgnoreAnimation(false);
    setIsChecked(isChecked);

    if (isOpenChangeTimeout.current) clearTimeout(isOpenChangeTimeout.current);

    isOpenChangeTimeout.current = setTimeout(() => {
      onOpenChange(isChecked);
      isOpenChangeTimeout.current = null;
    }, ANIMATION_DURATION_S * 1000);
  };

  const { width } = useWindowSize();

  const initialPadding = useMemo(() => {
    if (width < 480) {
      return '0px 0px 28px 0px';
    }
    return '28px 28px 28px 28px';
  }, [width]);

  const padding = useMemo(() => {
    if (width < 480) {
      return '0px 0px 32px 0px';
    }
    return '32px 28px 32px 28px';
  }, [width]);

  if (formStep === FormStep.Summary) return <AdvancedTransferSummary />;

  return (
    <>
      <AppModeSwitch
        isAdvancedOpen={isChecked}
        setIsAdvancedOpen={val => handleSwitchChange(val)}
      />
      <motion.div
        layout
        animate={{ height: 'auto' }}
        style={{
          maxWidth: isChecked ? 746 : 474,
          maxHeight: isChecked ? 'auto' : initialHeight,
          transition: shouldIgnoreAnimation
            ? undefined
            : `${ANIMATION_DURATION_S}s ease-in-out`,
        }}
        transition={{
          duration: shouldIgnoreAnimation ? 0 : ANIMATION_DURATION_S,
          ease: 'easeInOut',
        }}
        className={clsx(
          'formBg mb-0 form-max-width send-advanced-form',
          shouldIgnoreAnimation && 'ignore-animation'
        )}
      >
        <motion.div
          style={{
            maxHeight: 'auto',
            padding: isChecked ? padding : initialPadding,
            transition: shouldIgnoreAnimation
              ? undefined
              : `${ANIMATION_DURATION_S}s ease-in-out`,
            overflow: isChecked ? 'unset' : 'hidden',
          }}
          transition={{
            duration: shouldIgnoreAnimation ? 0 : ANIMATION_DURATION_S,
          }}
          layoutScroll
          className="transitionHeight formBody text-white align-items-center send-form-min-height"
        >
          <div className="d-flex align-items-center advanced-mode-header">
            <a
              href="https://docs.retrobridge.io/retrobridge-app/advanced-mode"
              target="_blank"
              rel="noopener noreferrer"
              className={clsx('advanced-mode-help', !isChecked && 'opacity-0')}
            >
              <GitBook color="#CCC3FF" />
              Advanced mode
            </a>
          </div>
          <AnimatePresence mode="wait">
            {!isChecked && <FormPlaceholder />}
            {isChecked && (
              <motion.div
                initial={shouldIgnoreAnimation ? false : { opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, height: 0 }}
                transition={{
                  duration: ANIMATION_DURATION_S,
                  delay:
                    !isCheckedAnimation && !shouldIgnoreAnimation
                      ? ANIMATION_DURATION_S * 2
                      : 0,
                }}
              >
                <div className="advanced-from-input">
                  <div className="advanced-from-input-fields">
                    <NetworkFromInput />
                    <TokenFromInput />
                  </div>
                </div>
                <SelectReceivingAddress />
                <SelectExecutionType />
                <AdvancedInfoBlock />
                <SubmitButton />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.div>
    </>
  );
}
