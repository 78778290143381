import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { TriangleDown } from '@/Assets/Icons/TriangleDown';
import { BASE_URL } from '@/Config/ApiConfig';
import { INetwork } from '@/types/apiTypes';
import ChooseNetworkModal from './chooseNetworkModal';
import './styles.css';

interface NetworkInputProps {
  value?: INetwork;
  onChange: (network: INetwork) => void;
  title: string;
  networks: INetwork[];
}

export function NetworkInput({
  value,
  onChange,
  title,
  networks,
}: NetworkInputProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [fastSelectNetworks, setFastSelectNetworks] = useState<INetwork[]>([]);

  useEffect(() => {
    if (fastSelectNetworks.length > 0) return;

    const networksToSelect = networks
      .filter(n => n.id !== value?.id)
      .slice(0, 5);
    setFastSelectNetworks(networksToSelect);
  }, [networks, fastSelectNetworks.length, value?.id]);

  const prevValue = useRef<INetwork | undefined>(undefined);

  useEffect(() => {
    if (!value) return;

    const index = fastSelectNetworks.findIndex(n => n.id === value?.id);

    if (index !== -1 && prevValue.current) {
      const newFastSelect = fastSelectNetworks;
      newFastSelect[index] = prevValue.current;
      setFastSelectNetworks(newFastSelect);
    }

    prevValue.current = value;
  }, [fastSelectNetworks, value]);

  const handleChange = (network: INetwork) => {
    onChange(network);
  };

  return (
    <>
      <div className="network-input">
        <div className="network-input--value">
          {value?.network_image_url && (
            <div className="network-input--image">
              <img
                src={BASE_URL + value.network_image_url}
                alt={`${value.name} favicon`}
              />
            </div>
          )}
          {value?.name || 'Select network'}
        </div>

        <div className="network-input--fast-select">
          {fastSelectNetworks.map((network, i, arr) => (
            <motion.button
              key={i}
              initial={{ x: -4 * i, zIndex: arr.length - i }}
              whileHover={{ x: i === 0 ? 0 : -4 * (i - 1) }}
              className="network-input--image"
              aria-label={`Select ${network.name}`}
              onClick={() => handleChange(network)}
              disabled={!network.active}
            >
              <img
                src={BASE_URL + network.network_image_url}
                alt={`${network.name} favicon`}
              />
            </motion.button>
          ))}
        </div>
        <button
          className="network-input--action"
          aria-label="Show more networks"
          onClick={() => setIsOpen(true)}
        >
          <TriangleDown width={17} height={17} />
        </button>
      </div>
      <ChooseNetworkModal
        title={title}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setNetwork={handleChange}
      />
    </>
  );
}
