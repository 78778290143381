import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { BULK_API_URL } from '../Config/ApiConfig';
import { maxPlatformDecimals } from '../constants/numbers';
import { advancedProgressStore } from '../pages/ProgressAdvancedPage/store';
import {
  AdvancedWalletStore,
  advancedWalletStore,
  btcSymbols,
  ethSymbols,
} from '../pages/SendAdvancedPage/store';
import { useSignStore } from '../stores/sign.store';
import {
  IApiResp,
  IBulkOrder,
  IBulkOrderResponse,
  IBulkReceipt,
  ICurrency,
  INetwork,
  IOrder,
} from '../types/apiTypes';
import { ExecutionType, OrderSearchStatuses } from '../types/enums';
import { logInGroup } from '../utils';
import { getCookie } from '../utils/cookies';
import { toFixed } from '../utils/numbers';
import { ApiService as Api } from './CoreApi';
import { api, apiKey, networkTypeName, networkTypeSelector } from './api';

const isETH = (symbol: string) => ethSymbols.includes(symbol);
const isBtc = (symbol: string) => btcSymbols.includes(symbol);

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const api_key = getCookie('api_key');
  if (api_key) {
    config.headers[apiKey] = api_key;
  }
  return config;
};

export class BulkService {
  private _api: Api;
  constructor() {
    this._api = new Api(BULK_API_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.defaults.headers.put['Content-Type'] =
      'application/json';
    this._api.instance.interceptors.request.use(config =>
      requestInterceptor(config)
    );
  }

  async createBulkOrder(
    currencyId: string,
    amount: string,
    walletSender: string,
    receivingData: AdvancedWalletStore['receiving'],
    transferTimeType: string
  ) {
    const { currencyFrom, currencies, networkFrom } =
      advancedWalletStore.getState();

    if (currencies.length <= 0 || !currencyFrom || !networkFrom) return;

    const type = networkTypeSelector(networkFrom);

    try {
      const url = '/api/bulk_order';

      const { data } = await this._api.post<{ data: IBulkReceipt }>(
        url,
        {
          currency_in_id: currencyId,
          amount: toFixed(+amount, maxPlatformDecimals),
          wallet_sender: walletSender,
          receiving_data: receivingData.map(el => ({
            wallet_receiver: el.address,
            amount_out: el.amount,
            currency_out_id: currencies.find(
              currency =>
                currency.contract.network.id === el.network &&
                (currency.symbol === currencyFrom?.symbol ||
                  (isETH(currencyFrom.symbol) && isETH(currency.symbol)) ||
                  (isBtc(currencyFrom.symbol) && isBtc(currency.symbol)))
            )?.id,
          })),
          transfer_time_type: transferTimeType,
        },
        {
          headers: {
            [networkTypeName]: type,
          },
        }
      );

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        const { setResign } = useSignStore.getState();
        setResign(type);
      }
    }
    return undefined;
  }

  async getBulkOrderById(orderId: IBulkOrder['id']) {
    try {
      const url = `/api/bulk_order/${orderId}`;
      const { setCurrentOrder } = advancedProgressStore.getState();

      const { data } = await this._api.get<{ data: IBulkOrder }>(url);

      setCurrentOrder(data);

      return data;
    } catch (error) {
      logInGroup('[BULK] Error while getting bulk order by id', error);
    }
    return null;
  }

  async closeBulkOrder(orderId: IBulkOrder['id'], network: INetwork) {
    try {
      const url = `/api/bulk_order/close/${orderId}`;
      const { data } = await this._api.put<{ data: { orderId: IOrder['id'] } }>(
        url,
        {},
        {
          headers: {
            [networkTypeName]: networkTypeSelector(network),
          },
        }
      );

      await api.closeOrder(data.orderId, network);
    } catch (error) {
      logInGroup('[BULK] Error while closing bulk order', error);
    }
  }

  async setOrderAsFailed(orderId: IBulkOrder['id'], network: INetwork) {
    try {
      const url = `/api/bulk_order/set_bulk_order_as_failed/${orderId}`;

      const { data } = await this._api.put<{ data: { orderId: IOrder['id'] } }>(
        url,
        {},
        {
          headers: {
            [networkTypeName]: networkTypeSelector(network),
          },
        }
      );

      await api.setOrderAsFailed(data.orderId, network);

      return data;
    } catch (error) {
      logInGroup('[BULK] Error while setting order as failed', error);
    }
    return undefined;
  }

  async getEstimatedFee(
    address: string | undefined,
    receivingData: AdvancedWalletStore['receiving']
  ) {
    const { currencies, currencyFrom, executionType, setIsEstimationLoading } =
      advancedWalletStore.getState();
    try {
      setIsEstimationLoading(true);

      if (
        receivingData.length === 0 ||
        currencies.length === 0 ||
        receivingData.some(el => !el.network) ||
        !currencyFrom
      )
        return;

      const body = receivingData.map(el => ({
        wallet_sender: address,
        wallet_receiver: el.address,
        amount: el.amount.length <= 0 ? '0' : el.amount,
        currency_in_id: currencyFrom.id,
        currency_out_id: currencies.find(
          currency =>
            currency.contract.network.id === el.network &&
            (currency.symbol === currencyFrom?.symbol ||
              (isETH(currencyFrom.symbol) && isETH(currency.symbol)) ||
              (isBtc(currencyFrom.symbol) && isBtc(currency.symbol)))
        )?.id,
        transfer_time_type: executionType,
      }));

      const url = '/api/bulk_order/estimate_fee';

      const { data } = await this._api.patch<{
        data: { data: never };
      }>(url, body);

      const { setEstimatedFee } = advancedWalletStore.getState();

      setEstimatedFee(data.data);

      return data.data;
    } catch (error) {
      logInGroup('[BULK] Error while getting estimated fee', error);
    } finally {
      setIsEstimationLoading(false);
    }
  }

  async getExecutionTimes() {
    try {
      const { setExecutionIntervals } = advancedWalletStore.getState();

      const { data } = await this._api.get<{
        data: Record<ExecutionType, number>;
      }>('/api/bulk_order/execution/type');

      setExecutionIntervals(data);
    } catch (error) {
      logInGroup('[BULK] Error while getting transfer time', error);
    }
  }

  async getCurrencies(networkIDs: string[]) {
    try {
      if (networkIDs.length === 0) return;

      const uniqueNetworkIDs = [...new Set(networkIDs)];

      const url = `/api/currency/${uniqueNetworkIDs.join(',')}`;
      const { data } = await this._api.get<{ data: ICurrency[] }>(url);

      const { currencyFrom, setCurrencies, setCurrencyFrom } =
        advancedWalletStore.getState();

      const sortedData = data.sort((a: ICurrency, b: ICurrency) => {
        if (ethSymbols.includes(a.symbol)) return -1;
        if (ethSymbols.includes(b.symbol)) return 1;

        if (a.symbol === 'USDT') return -1;
        if (b.symbol === 'USDT') return 1;

        return 0;
      });

      const currenciesForSourceNetwork: ICurrency[] = [];

      for (const token of sortedData) {
        if (token.contract.network.id === networkIDs[0] && token.active) {
          currenciesForSourceNetwork.push(token);
        }
      }

      setCurrencies(sortedData);

      const targetCurrency =
        currenciesForSourceNetwork.find(
          currency => currency.symbol === currencyFrom?.symbol
        ) ?? currenciesForSourceNetwork[0];

      setCurrencyFrom(targetCurrency);

      return sortedData;
    } catch (error) {
      logInGroup('[BULK] Error while getting currencies', error);
    }
  }

  async getOrders(
    status: OrderSearchStatuses,
    search?: string,
    wallet?: string,
    take = 50
  ) {
    let url = `/api/bulk_order?skip=0&take=${take}&status=${status}`;
    if (search) {
      url = `${url}&search=${search}`;
    }
    if (wallet) {
      url = `${url}&wallet=${wallet}`;
    }

    try {
      const res: IApiResp<IBulkOrderResponse> = await this._api.get(url);

      return res.data.data;
    } catch (error) {
      logInGroup('[BULK] Error while getting orders', error);
    }
    return undefined;
  }
}

export const bulkApi = new BulkService();
