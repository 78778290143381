import ethereumLogo from '@/Assets/images/ethereum.png';
import earlyNftImage from '@/Assets/images/phaseEarlyCard.png';
import lateNftImage from '@/Assets/images/phaseLateCard.png';
import { BASE_URL } from '@/Config/ApiConfig';
import Footer from '@/components/Footer';
import { Header } from '@/components/Header';
import { Link } from 'react-router-dom';
import '../../PhasePage/PhaseContent/styles.css';
import { PhaseJourneysHero } from '../PhaseJourneysHero';
import './styles.css';
import { PHASE_SUNRISE_PAGE } from '@/constants/routes.constants';

import SunriseNFT from '@/Assets/images/phases/Sunrise.png';
import SunriseNFTFilter from '@/Assets/images/phases/SunriseJourneyFilter2.png';
import SunsetNFTFilter from '@/Assets/images/phases/SunsetJourneyFilter2.png';
import MidnightNFTFilter from '@/Assets/images/phases/MidnightJourneyFilter2.png';

export function PhaseJourneysContent() {
  return (
    <div className="phase-journeys-page">
      <Header />
      <div className="phase-page">
        <article className="phase-container container-xxl">
          <div className="phase-body">
            <PhaseJourneysHero />
          </div>
          <div className="journeys-grid">
            <div className="journeys-grid-item early">
              <div className="journeys-grid-item-nftCard">
                <img src={earlyNftImage} draggable={false} alt="" />
              </div>
              <div className="journeys-grid-item--content">
                <div className="journeys-grid-item--badge">NFT Minted 5240</div>
                <h2 className="journeys-grid-item--heading">
                  <span className="d-flex align-items-center gap-2 bold">
                    Early Sunrise
                    <div className="journeys-grid-item--token-group">
                      <div className="journeys-grid-item--token-group-item">
                        <img src={ethereumLogo} alt="" />
                      </div>
                      <div className="journeys-grid-item--token-group-item">
                        <img
                          src={BASE_URL + '/static/networks/arbitrum.svg'}
                          alt=""
                        />
                      </div>
                      <div className="journeys-grid-item--token-group-item">
                        <img
                          src={BASE_URL + '/static/networks/optimism.svg'}
                          alt=""
                        />
                      </div>
                    </div>
                  </span>
                  <span className="journeys-grid-item--badge">Finished</span>
                </h2>
                <div className="journeys-grid-item--fee">
                  <div className="d-flex justify-content-between align-items-start">
                    <span className="journeys-page-card--info">
                      NFTs with EVM Bridging Fee discount{' '}
                      <strong>20-30%</strong>
                    </span>
                  </div>
                </div>
                <Link
                  to={PHASE_SUNRISE_PAGE}
                  className="adventure-page--btn"
                  state={{ selectedJourney: 0 }}
                >
                  Go to Early Sunrise
                </Link>
              </div>
            </div>
            <div className="journeys-grid-item late">
              <div className="journeys-grid-item-nftCard">
                <img src={lateNftImage} draggable={false} alt="" />
              </div>
              <div className="journeys-grid-item--content">
                <div className="journeys-grid-item--badge">Active</div>
                <h2 className="journeys-grid-item--heading">
                  <span className="d-flex align-items-center gap-2 bold">
                    Sunrise Journey
                    <div className="journeys-grid-item--token-group">
                      <div className="journeys-grid-item--token-group-item">
                        <img
                          src={BASE_URL + '/static/networks/arbitrum.svg'}
                          alt=""
                        />
                      </div>
                      <div className="journeys-grid-item--token-group-item">
                        <img
                          src={BASE_URL + '/static/networks/optimism.svg'}
                          alt=""
                        />
                      </div>
                    </div>
                  </span>
                </h2>
                <div className="journeys-grid-item--fee">
                  <div className="d-flex justify-content-between align-items-start">
                    <span className="journeys-page-card--info">
                      <strong>1</strong> of 4 RetroBridge
                      <br />
                      Phases NFTs
                    </span>
                    <div className="phases-NFTs-progress">
                      <img
                        src={SunriseNFT}
                        className="phases-NFTs-progress-item active"
                        alt="Sunrise NFT"
                      />
                      <img
                        src={SunriseNFTFilter}
                        className="phases-NFTs-progress-item"
                        alt="Midday NFT"
                      />
                      <img
                        src={SunsetNFTFilter}
                        className="phases-NFTs-progress-item"
                        alt="Sunset NFT"
                      />
                      <img
                        src={MidnightNFTFilter}
                        className="phases-NFTs-progress-item"
                        alt="Midnight NFT"
                      />
                    </div>
                  </div>
                </div>
                <Link
                  to={PHASE_SUNRISE_PAGE}
                  className="journeys-page-card--btn"
                  state={{ selectedJourney: 1 }}
                >
                  Go to Sunrise Journey
                </Link>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div className="phase-page-footer">
        <Footer />
      </div>
    </div>
  );
}
