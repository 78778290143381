import SendBg from '@/Assets/images/send-bg-png.png';
import SendBgSmall from '@/Assets/images/send-bg-small.svg';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import Footer from '@/components/Footer';
import Header from '@/components/Header/header';
import { Loader } from '@/components/Loader';
import useDebounce from '@/hooks/useDebounce';
import { useIsPageLoaded } from '@/hooks/useIsPageLoaded';
import { useResizeObserver } from '@/hooks/useResizeObserver';
import { NetworkTypes } from '@/providers/web3Provider';
import { api } from '@/services/api';
import { phaseService } from '@/services/phaseApi';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useAppStore, WalletToType } from '@/stores/app.store';
import { logInGroup } from '@/utils';
import { useTonAddress } from '@tonconnect/ui-react';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { useAccount as useEVMAccount } from 'wagmi';
import { useShallow } from 'zustand/react/shallow';
import { SendAdvanced } from '../SendAdvancedPage';
import './SendPage.css';
import SendForm from './components/SendForm';
import TransferInfoForm from './components/TransferInfo/form';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';

const SendPage = () => {
  const { address: evmAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const tonAddress = useTonAddress();
  const { publicKey: solanaAddress } = useSolanaWallet();

  const [openModal, setOpenModal] = useState(false);
  const [openWithoutAnimation, setOpenWithoutAnimation] = useState(false);

  const [isConfirmOpen, setIsConfirmOpen] = useAppStore(s => [
    s.isConfirmOpen,
    s.setIsConfirmOpen,
  ]);
  const [isAdvancedOpen, setIsAdvancedOpen] = useAppStore(s => [
    s.isAdvancedOpen,
    s.setIsAdvancedOpen,
  ]);

  const [
    networkFrom,
    networkTo,
    currencyFrom,
    currencyTo,
    amountFrom,
    walletTo,
    walletToType,
  ] = useAppStore(
    useShallow(s => [
      s.networkFrom,
      s.networkTo,
      s.currencyFrom,
      s.currencyTo,
      s.amountFrom,
      s.walletTo,
      s.walletToType,
    ])
  );

  const amountFromDebounced = useDebounce(amountFrom, 150);

  const [searchParams, setSearchParams] = useSearchParams();

  const walletFrom = useMemo(() => {
    switch (networkFrom?.network_type) {
      case NetworkTypes.EVM:
        return evmAddress;
      case NetworkTypes.ZK_SYNC_ERA:
        return evmAddress;
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;
      case NetworkTypes.SOLANA:
        return solanaAddress?.toBase58();
      default:
        return '';
    }
  }, [
    evmAddress,
    starknetAddress,
    tonAddress,
    solanaAddress,
    networkFrom?.network_type,
  ]);

  const addressTo = useMemo(() => {
    if (walletToType === WalletToType.CurrentWallet) {
      switch (networkTo?.network_type) {
        case NetworkTypes.EVM:
          return evmAddress;
        case NetworkTypes.STARKNET:
          return starknetAddress;
        case NetworkTypes.TON:
          return tonAddress;
        case NetworkTypes.SOLANA:
          return solanaAddress?.toBase58();

        default:
          return evmAddress;
      }
    }
    return walletTo;
  }, [
    walletToType,
    walletTo,
    evmAddress,
    starknetAddress,
    tonAddress,
    solanaAddress,
    networkTo?.network_type,
  ]);

  useEffectOnce(() => {
    const mode = searchParams.get('mode');

    if (mode?.toLowerCase() === 'advanced') {
      setIsAdvancedOpen(true);
      setOpenWithoutAnimation(true);
      return;
    } else {
      setOpenWithoutAnimation(false);
    }
  });

  const pageLoaded = useIsPageLoaded(SendBg);

  const openConfirm = () => setIsConfirmOpen(true);
  const closeConfirm = () => setIsConfirmOpen(false);

  useEffect(() => {
    api.getNetwork();
  }, []);

  useEffect(() => {
    return () => {
      setIsConfirmOpen(false);
    };
  }, []);

  useEffect(() => {
    if (networkFrom && networkTo) api.getCurrency(networkFrom.id, networkTo.id);
  }, [networkFrom, networkTo]);

  useEffect(() => {
    if (currencyFrom && networkTo)
      api.getDestinationPairs(currencyFrom.id, networkTo.id);
  }, [currencyFrom, networkTo]);

  useEffect(() => {
    if (evmAddress && !!currencyTo?.contract.network.id && isConfirmOpen) {
      phaseService.getUserNFT(evmAddress, currencyTo.contract.network.id);
    }
  }, [evmAddress, currencyTo?.contract.network.id, isConfirmOpen]);

  useEffect(() => {
    if (currencyFrom && currencyTo && currencyFrom.id !== currencyTo.id)
      api.getLimits(currencyFrom, currencyTo);
  }, [currencyFrom, currencyTo]);

  useEffect(() => {
    if (currencyFrom && currencyTo && +amountFromDebounced > 0) {
      api
        .getAmountOut(
          currencyFrom.id,
          currencyTo.id,
          amountFromDebounced,
          walletFrom,
          addressTo && addressTo.length > 0 ? addressTo : undefined
        )
        .catch(e => logInGroup('getAmountOut', e));
    }
  }, [currencyFrom, currencyTo, amountFromDebounced, walletFrom, addressTo]);

  const [initialHeight, setInitialHeight] = useState(0);

  useResizeObserver({
    refId: 'regular-bridge-form',
    onResize: ({ height }) => {
      if (height) {
        setInitialHeight(height);
      }
    },
  });

  return (
    <div className="page-background-color">
      <Helmet>
        <title>App | Retro Bridge</title>
      </Helmet>
      <Container
        fluid
        className="send-page-container mainSend  min-vh-100 w-100 d-flex flex-1  justify-content-center flex-column align-items-center page-padding-bottom pt-2 position-relative"
      >
        {pageLoaded ? (
          <>
            <img src={SendBg} alt="" className="send-bg-img" />
            <img src={SendBgSmall} alt="" className="send-bg-img-mobile" />
            <Header />
            <Stack className="mw-100 col-xs-12 col-sm-12 col-md-6 mx-auto col-lg-5 col-xl-4 w-100 flex align-items-center">
              <div className="form-mt">
                <AnimatePresence mode="wait">
                  {!isConfirmOpen && !isAdvancedOpen && (
                    <React.Fragment key="bridge-form">
                      <SendForm
                        setOpenModal={setOpenModal}
                        openConfirm={openConfirm}
                      />
                    </React.Fragment>
                  )}
                  {isConfirmOpen && !isAdvancedOpen && (
                    <div className="form-info-mt" key="transfer-info">
                      <TransferInfoForm closeConfirm={closeConfirm} />
                    </div>
                  )}

                  {isAdvancedOpen && (
                    <React.Fragment key="advanced-bridge-form">
                      <SendAdvanced
                        initialHeight={initialHeight}
                        onOpenChange={val => {
                          setIsAdvancedOpen(val);
                          setSearchParams(searchParams => {
                            if (!val) {
                              searchParams.delete('mode');
                            } else {
                              searchParams.set('mode', 'advanced');
                            }
                            return searchParams;
                          });
                          if (!val) {
                            setOpenWithoutAnimation(false);
                          }
                        }}
                        openWithoutAnimation={openWithoutAnimation}
                      />
                    </React.Fragment>
                  )}
                </AnimatePresence>
              </div>
            </Stack>
            <div className="send-page-footer">
              <Footer />
            </div>
          </>
        ) : (
          <Loader />
        )}
        <ConnectWalletModalWithDisabled
          setOpenModal={setOpenModal}
          openModal={openModal}
          networkType={networkFrom?.network_type}
          onlySelectedNetworkType
        />
      </Container>
    </div>
  );
};

export default SendPage;
