import clsx from 'clsx';
import { useMemo } from 'react';
import { useBalance } from '../../../../hooks/useBalance';
import { useWalletStore } from '../../../../stores/wallet.store';
import { INetwork } from '../../../../types/apiTypes';
import { getMinimalDecimalNumber } from '../../../../utils/numbers';
import { LoaderSmall } from '../../../../components/LoaderSmall';
import { ROUND_TOKENS } from '../../../../constants/tokens';
import { useAppStore } from '../../../../stores/app.store';

interface BalanceProps {
  network: INetwork;
}

export function Balance({ network }: BalanceProps) {
  const { allCurrencyPairs } = useWalletStore();

  const [currencyFrom] = useAppStore(s => [s.currencyFrom]);

  const [isTokenInNetwork, currency] = useMemo(() => {
    let isTokenInNetwork = false;
    let symbol = currencyFrom;
    allCurrencyPairs.forEach(currency => {
      if (currency.contract.network.id === network.id) {
        isTokenInNetwork = true;
        symbol = currency;
      }
    });

    return [
      isTokenInNetwork || currencyFrom?.contract.network.id === network.id,
      symbol,
    ];
  }, [allCurrencyPairs, network.id, currencyFrom]);

  const { balance, loading } = useBalance(network, currency);

  const formattedBalance = useMemo(() => {
    const shouldRoundToken = ROUND_TOKENS.includes(
      currency?.symbol.toLowerCase() ?? ''
    );

    const formatted = +balance <= 0 ? 0 : getMinimalDecimalNumber(balance);

    if (+formatted <= 0) return '0.00';

    if (shouldRoundToken) {
      return +formatted < 0.0001 ? '0.00' : formatted;
    }

    return +formatted < 0.000001 ? '0.00' : formatted;
  }, [balance, currency?.symbol]);

  if (!isTokenInNetwork) return null;

  return (
    <p
      className={clsx(
        'network-balance',
        +formattedBalance <= 0 && 'network-balance-disabled',
        loading && 'network-balance-loading'
      )}
    >
      {loading ? (
        <LoaderSmall
          width={16}
          height={16}
          className="network-balance-loader"
        />
      ) : (
        formattedBalance
      )}{' '}
      {currency?.symbol}
    </p>
  );
}
