import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';
import {
  MAX_BULK_RECIPIENTS,
  advancedWalletStore,
  useAdvancedWalletStore,
} from '../../store';
import { FillInWithTextDialog } from '../FillInWithTextDialog';
import { ReceivingItem } from './ReceivingItem';
import './styles.css';
import { useWindowSize } from 'usehooks-ts';
import { OutlinedPlusInCircle } from '@/Assets/Icons/OutlinedPlusInCircle';

interface SelectReceivingAddressProps {
  disabled?: boolean;
  label?: string;
}

const ITEM_HEIGHT = 50;
const ITEM_GAP = 8;
const MAX_VIEWPORT_HEIGHT = 224;

export function SelectReceivingAddress({
  label = 'Select Receiving Addresses',
  disabled,
}: SelectReceivingAddressProps) {
  const [receiving, addReceiving] = useAdvancedWalletStore(s => [
    s.receiving,
    s.addReceiving,
  ]);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const { width } = useWindowSize();

  const handleAddReceiving = () => {
    const { receiving, shouldSetSingleNetwork } =
      advancedWalletStore.getState();

    let networkId: string | undefined = undefined;
    const singleNetwork = receiving.find(item => !!item.network);

    if (shouldSetSingleNetwork && !!singleNetwork) {
      networkId = singleNetwork.network;
    }

    addReceiving('', '', networkId);
    if (receiving.length >= 4 && width > 746) {
      setTimeout(() => {
        document
          .querySelector('#receiving-viewport .simplebar-content-wrapper')
          ?.scrollTo({
            top: document.querySelector(
              '#receiving-viewport .simplebar-content-wrapper'
            )?.scrollHeight,
            behavior: 'smooth',
          });
      }, 0);
    }
  };

  const viewportHeight = useMemo(() => {
    return Math.min(
      ITEM_HEIGHT * receiving.length + ITEM_GAP * receiving.length - 1,
      MAX_VIEWPORT_HEIGHT
    );
  }, [receiving.length]);

  return (
    <div className={disabled ? '' : 'select-receiving-wrapper'}>
      {disabled && (
        <p className={`select-receiving-label-title ${width < 746 && 'm-0'}`}>
          {label}
        </p>
      )}

      <div className={'select-receiving mb-2'}>
        <span className="select-receiving-label">
          {disabled ? 'Address' : label} (
          {receiving.length <= 0 ? 1 : receiving.length}/{MAX_BULK_RECIPIENTS})
        </span>
        <span className="select-receiving-label">Amount To Receive</span>
        <span className="select-receiving-label">Network</span>
        <span className="select-receiving-label"></span>
      </div>
      <motion.div
        animate={{
          height: width > 746 ? viewportHeight : '100%',
        }}
        onAnimationStart={() => {
          if (receiving.length < 4) setIsAnimationStarted(true);
        }}
        onAnimationComplete={() => setIsAnimationStarted(false)}
        transition={{ duration: 0.1 }}
      >
        <SimpleBar
          style={{
            maxHeight: width > 746 ? '224px' : '100%',
            marginRight: '-19px',
            paddingRight: '19px',
            height: '100%',
            overflow: isAnimationStarted ? 'hidden' : 'auto',
          }}
          id="receiving-viewport"
        >
          <div
            className={clsx(
              'select-receiving',
              disabled && 'select-receiving--disabled'
            )}
          >
            {receiving.map((item, i) => (
              <ReceivingItem item={item} i={i} key={i} disabled={disabled} />
            ))}
          </div>
        </SimpleBar>
      </motion.div>
      {!disabled && (
        <div className="d-flex justify-content-between align-items-center select-receiving-bottom">
          <button
            className="select-receiving--add"
            disabled={receiving.length >= MAX_BULK_RECIPIENTS}
            onClick={handleAddReceiving}
          >
            <OutlinedPlusInCircle />
            Add new address
          </button>
          <FillInWithTextDialog />
        </div>
      )}
    </div>
  );
}
