import { Adventure } from '..';
import { ROUTES } from '@/constants/routes.constants';
import nftImage from '@/Assets/images/journeys/zkSync/cardImageLinea.png';
import activeCardBg from '@/Assets/images/journeys/multiChain/adventureCardBg.png';
import { Phase } from '@/types/phases';

export const zkSyncAdventure: Adventure = {
  phase: Phase.ZkSyncJourney,

  nftImage,
  activeCardBg,
  heading: <>ZkSync Adventure</>,
  startDate: '2024-04-11T14:00:00.000Z',
  endDate: '2024-04-18T14:00:00.000Z',
  link: ROUTES.PHASE_ZKSYNC_ADVENTURE_PAGE,
  linkText: 'Take zkSync Adventure',
};
