import * as ReactTooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import { TooltipArrow } from './TooltipArrow';
import './styles.css';

interface TooltipProps extends ReactTooltip.TooltipProps {
  children?: ReactNode;
  trigger?: ReactNode;
  triggerProps?: ReactTooltip.TooltipTriggerProps;
  variant?: 'default' | 'dark' | 'dark-2' | 'flat';
  contentProps?: ReactTooltip.TooltipContentProps;
}

export function Tooltip({
  children,
  trigger,
  triggerProps,
  variant,
  contentProps,
  ...props
}: TooltipProps) {
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <ReactTooltip.Provider>
      <ReactTooltip.Root {...props}>
        <ReactTooltip.Trigger
          ref={triggerRef}
          onClick={event => event.preventDefault()}
          {...triggerProps}
          className={clsx(
            'default-tooltip-trigger',
            triggerProps?.className,
            variant
          )}
        >
          {trigger || '?'}
        </ReactTooltip.Trigger>
        <ReactTooltip.Portal>
          <ReactTooltip.Content
            side="top"
            align="start"
            alignOffset={-15}
            avoidCollisions={false}
            sideOffset={5}
            asChild
            onPointerDownOutside={event => {
              if (event.target === triggerRef.current) event.preventDefault();
            }}
            {...contentProps}
          >
            <div className={clsx('default-tooltip', variant)}>
              <div className="default-tooltip-bg"></div>
              <TooltipArrow />
              {children}
            </div>
          </ReactTooltip.Content>
        </ReactTooltip.Portal>
      </ReactTooltip.Root>
    </ReactTooltip.Provider>
  );
}
