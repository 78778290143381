import {
  TonProofItemReplySuccess,
  useTonAddress,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import { AxiosError } from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountInterface, constants } from 'starknet';
import {
  useAccount as useEVMAccount,
  useDisconnect as useEVMDisconnect,
  useSignMessage,
} from 'wagmi';

import { useStarknetAccount } from '@/starknet/hooks/account';
import {
  useNetwork,
  useConnect as useStarknetConnect,
  useDisconnect as useStarknetDisconnect,
} from '@starknet-react/core';
import { Address } from '@ton/core';
import { Connector } from 'starknetkit';
import { toHex } from 'viem';
import { useNotify } from '../hooks/useToast';
import { api } from '../services/api';
import { WrongAccountChainIdError } from '../starknet/errors';
import { AppTypedData } from '../starknet/types/app_typed_data';
import { getAppStarknetChanId } from '../starknet/utils/getAppStarknetChainId';
import { useAppStore } from '../stores/app.store';
import { useSignStore } from '../stores/sign.store';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import base58 from 'bs58';

// import useAppStore from '../../stores/appStore';
interface PropsTypes {
  children: React.ReactNode;
}
export enum NetworkTypes {
  EVM = 'EVM',
  ZK_SYNC_ERA = 'ZK_SYNC_ERA',
  STARKNET = 'STARKNET',
  TON = 'TON',
  SOLANA = 'SOLANA',
  TRON = 'TRON',
}

const starknetNetworks: Record<string, string> = {
  [constants.StarknetChainId.SN_SEPOLIA]: 'Sepolia Testnet',
  [constants.StarknetChainId.SN_MAIN]: 'MainNet',
};

const routesWithSign = ['app.', '/send', '/phases/'];

type IOverloadedActivateFunction = {
  (networkType: NetworkTypes.STARKNET, connector: Connector): Promise<void>;
};
export type ContextType = {
  handleDeactivate: (networkType: NetworkTypes) => void;
  handleActivate: IOverloadedActivateFunction;
};

const InitialContextValues = {
  handleActivate: async () => {},
  handleDeactivate: () => {},
};

export const WalletConnectInteractorContext =
  createContext<ContextType>(InitialContextValues);
export const useWalletConnectInteractor = () =>
  useContext(WalletConnectInteractorContext);

export const WalletConnectInteractorProvider = ({ children }: PropsTypes) => {
  const { address: evmAddress } = useEVMAccount();
  const { disconnect } = useEVMDisconnect();
  const { signMessage, data } = useSignMessage();
  const { address: starknetAddress, account: starknetAccount } =
    useStarknetAccount();
  const { connectAsync: connectStarknet } = useStarknetConnect();
  const { disconnect: disconnectStarknet } = useStarknetDisconnect();
  const {
    wallet: solanaWallet,
    signMessage: signSolanaMessage,
    publicKey: solanaAddress,
  } = useSolanaWallet();

  const navigate = useNavigate();
  const [networkFrom] = useAppStore(s => [s.networkFrom]);
  const { resign, setResign } = useSignStore();

  const { notify } = useNotify();
  const { chain } = useNetwork();

  const location = useLocation();

  const handleActivate = useCallback<IOverloadedActivateFunction>(
    async (networkType, connector) => {
      try {
        if (networkType === NetworkTypes.STARKNET && connector) {
          connectStarknet({ connector })
            .then(() => {
              window.sessionStorage.setItem('starknet-provider', connector.id);
              notify({ meassage: 'Wallet connected', type: 'success' });
            })
            .catch((err: any) => {
              if (err instanceof WrongAccountChainIdError) {
                notify({
                  title: 'Wrong StarkNet Network',
                  meassage: `Please ensure to switch StarkNet network to ${
                    starknetNetworks[getAppStarknetChanId()]
                  } in your wallet`,
                  type: 'error',
                });
                return;
              }
              console.error(err);
            });

          return;
        }
      } catch (e) {
        console.error(e);
      }
    },
    [evmAddress, connectStarknet, networkFrom]
  );

  const deactivateEthWallet = useCallback(() => {
    window.sessionStorage.removeItem('provider');
    disconnect();
  }, [disconnect]);

  const deactivateStarknetWallet = useCallback(() => {
    window.sessionStorage.removeItem('starknet-provider');
    disconnectStarknet();
  }, [disconnectStarknet]);

  const deactivateSolanaWallet = useCallback(() => {
    solanaWallet?.adapter.disconnect();
  }, [solanaWallet]);

  const handleDeactivate = (networkType: NetworkTypes) => {
    if (
      location.pathname !== '/' &&
      location.pathname !== '/explorer' &&
      !location.pathname.includes('/phases')
    ) {
      navigate('/send');
    }

    if (networkType === NetworkTypes.STARKNET) {
      deactivateStarknetWallet();
    }
  };

  const deactivateWrongStarknetNetwork = useCallback(async () => {
    if (starknetAddress && chain && networkFrom) {
      if (
        networkFrom.network_type === NetworkTypes.STARKNET &&
        toHex(chain?.id) !== networkFrom.chainId
      ) {
        handleDeactivate(NetworkTypes.STARKNET);
        const network = starknetNetworks[networkFrom.chainId];
        notify({
          title: 'Wrong StarkNet Network',
          meassage: `Please ensure to switch StarkNet network to ${network} in your wallet`,
          type: 'error',
        });
      }
    }
  }, [chain, starknetAddress, networkFrom]);

  const value = { handleActivate, handleDeactivate };

  const oldEthAddressRef = useRef(evmAddress);
  const oldStarknetAddressRef = useRef(starknetAddress);
  const oldSolAddressRef = useRef(solanaWallet?.adapter.publicKey?.toBase58());

  const [tonconnect] = useTonConnectUI();
  const tonAddress = useTonAddress();
  const oldTonAddressRef = useRef(tonAddress);

  const errorNotifier = (error?: unknown) => {
    if (error instanceof AxiosError) {
      return notify({ type: 'error', meassage: error.response?.data.message });
    }
    // if (error instanceof Error) {
    //   return notify({ type: "error", meassage: error.message });
    // }
    return notify({
      type: 'error',
      meassage: 'Something went wrong. Please try again later.',
    });
  };

  const checkIsAuthWallet = async (
    address: string,
    network_type: NetworkTypes
  ) => {
    try {
      await api.checkWallet(address, network_type);
      return true;
    } catch (error) {
      return false;
    }
  };

  const signStarknetWallet = async (
    account: AccountInterface,
    address: string
  ) => {
    if (address === oldStarknetAddressRef.current) {
      return;
    }

    const isAuth = await checkIsAuthWallet(address, NetworkTypes.STARKNET);
    if (isAuth) return;

    oldStarknetAddressRef.current = address;
    try {
      const typedData = await getTypedDataWithAddress(address);

      typedData.domain.chainId = getAppStarknetChanId();

      const signed = await account.signMessage(typedData);
      await api.validateSignedMessage(address, NetworkTypes.STARKNET, signed);
    } catch (error) {
      errorNotifier(error);
      deactivateStarknetWallet();
    }
  };

  const signEthWalletHelper = useCallback(
    async (address: string) => {
      try {
        const typedData = await getTypedDataWithAddress(address);
        await signMessage({ message: typedData.message.value });
      } catch (error) {
        deactivateEthWallet();
        errorNotifier(error);
      }
    },
    [deactivateEthWallet]
  );

  useEffect(() => {
    if (data && evmAddress) {
      api.validateSignedMessage(evmAddress, NetworkTypes.EVM, data);
    }
  }, [data, evmAddress]);

  const getTypedDataWithAddress = async (
    address: string
  ): Promise<AppTypedData> => {
    const { data } = await api.getSignMessage();

    data.message.value = `${data.message.value}\n${address}`;
    return data;
  };

  const signEthWallet = useCallback(
    async (address?: `0x${string}`) => {
      if (!address || address === oldEthAddressRef.current) {
        return;
      }

      const isAuth = await checkIsAuthWallet(address, NetworkTypes.EVM);

      if (isAuth) {
        return;
      }

      oldEthAddressRef.current = address;
      await signEthWalletHelper(address);
    },
    [signEthWalletHelper]
  );

  const signSolWallet = useCallback(
    async (
      address: string,
      signSolMessage: (message: Uint8Array) => Promise<Uint8Array>
    ) => {
      try {
        if (!address) return;
        const isAuth = await checkIsAuthWallet(address, NetworkTypes.SOLANA);
        if (isAuth) return;

        const typedData = await getTypedDataWithAddress(address);
        const encodedMessage = new TextEncoder().encode(
          typedData.message.value
        );
        const signedMessage = await signSolMessage(encodedMessage);
        const signature = base58.encode(signedMessage as Uint8Array);
        await api.validateSignedMessage(
          address,
          NetworkTypes.SOLANA,
          signature
        );
      } catch (error) {
        console.error(error);
        deactivateSolanaWallet();
        errorNotifier(error);
      }
    },
    [deactivateSolanaWallet]
  );

  const resignWalletHelper = useCallback(
    async (type: NetworkTypes) => {
      setResign(null);
      if (
        type === NetworkTypes.STARKNET &&
        starknetAddress &&
        starknetAccount
      ) {
        return await signStarknetWallet(starknetAccount, starknetAddress);
      } else if (
        type === NetworkTypes.TON &&
        tonAddress &&
        tonconnect.wallet?.connectItems?.tonProof &&
        'proof' in tonconnect.wallet?.connectItems?.tonProof
      ) {
        return await signTonWallet(tonAddress, {
          ...tonconnect.wallet?.connectItems?.tonProof,
          stateInit: tonconnect.wallet.account.walletStateInit,
        });
      } else if (
        type === NetworkTypes.SOLANA &&
        solanaAddress &&
        signSolanaMessage
      ) {
        return await signSolWallet(solanaAddress.toBase58(), signSolanaMessage);
      } else if (evmAddress) {
        return await signEthWalletHelper(evmAddress);
      }
    },
    [
      setResign,
      evmAddress,
      starknetAccount,
      starknetAddress,
      tonAddress,
      solanaAddress,
      tonconnect.wallet?.connectItems?.tonProof,
      signEthWalletHelper,
    ]
  );

  useEffect(() => {
    if (
      evmAddress &&
      routesWithSign.some(route => window.location.href.includes(route))
    ) {
      signEthWallet(evmAddress);
    }
    oldEthAddressRef.current = evmAddress;
  }, [evmAddress, signEthWallet]);

  useEffect(() => {
    if (
      solanaAddress &&
      signSolanaMessage &&
      routesWithSign.some(route => window.location.href.includes(route))
    ) {
      signSolWallet(solanaAddress.toBase58(), signSolanaMessage);
    }
  }, [solanaAddress, signSolWallet, signSolanaMessage]);

  useEffect(() => {
    if (networkFrom?.network_type !== NetworkTypes.STARKNET) {
      return;
    }

    if (
      starknetAccount &&
      starknetAddress &&
      routesWithSign.some(route => window.location.href.includes(route))
    ) {
      signStarknetWallet(starknetAccount, starknetAddress);
    }

    oldStarknetAddressRef.current = starknetAddress;
  }, [starknetAccount, starknetAddress, networkFrom]);

  const signTonWallet = useCallback(
    async (
      address: string,
      tonProof: TonProofItemReplySuccess & { stateInit: string }
    ) => {
      try {
        if (!address) return;
        const isAuth = await checkIsAuthWallet(address, NetworkTypes.TON);
        if (isAuth) return;

        oldTonAddressRef.current = address;

        api.validateSignedMessage(
          Address.parse(address).toRawString(),
          NetworkTypes.TON,
          JSON.stringify({ ...tonProof.proof, stateInit: tonProof.stateInit })
        );
      } catch (error) {
        errorNotifier(error);
        await tonconnect.connector.disconnect();
      }
    },
    [tonconnect]
  );

  const generateTonProof = useCallback(async () => {
    tonconnect.setConnectRequestParameters({ state: 'loading' });
    const { data } = await api.getSignMessage();
    if (data) {
      tonconnect.setConnectRequestParameters({
        state: 'ready',
        value: { tonProof: btoa(data.message.value) },
      });
    } else {
      tonconnect.setConnectRequestParameters(null);
    }
  }, [tonconnect]);

  useEffect(() => {
    if (routesWithSign.some(route => window.location.href.includes(route))) {
      generateTonProof();
      if (
        tonAddress &&
        tonconnect.wallet?.connectItems?.tonProof &&
        'proof' in tonconnect.wallet?.connectItems?.tonProof
      ) {
        signTonWallet(tonAddress, {
          ...tonconnect.wallet?.connectItems?.tonProof,
          stateInit: tonconnect.wallet.account.walletStateInit,
        });
      }
    }
  }, [
    tonAddress,
    generateTonProof,
    tonconnect.wallet?.connectItems?.tonProof,
    signTonWallet,
  ]);

  useEffect(() => {
    deactivateWrongStarknetNetwork();
  }, [deactivateWrongStarknetNetwork]);

  useEffect(() => {
    if (resign) {
      resignWalletHelper(resign);
    }
  }, [resign, resignWalletHelper]);

  return (
    <WalletConnectInteractorContext.Provider value={value}>
      {children}
    </WalletConnectInteractorContext.Provider>
  );
};
