import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { Loader } from '../../components/Loader';
import { HOME_PAGE } from '../../constants/routes.constants';
import { bulkApi } from '../../services/BulkApi';
import { phaseService } from '../../services/phaseApi';
import { usePhaseBannerStore } from '../../stores/phaseBanner.store';
import { BulkOrderStatuses } from '../../types/enums';
import { ErrorView } from './components/ErrorView';
import { ProgressView } from './components/ProgressView';
import { SuccessView } from './components/SuccesView';
import { useAdvancedProgressStore } from './store';
import './styles.css';

export function AdvancedProgressForm() {
  const { address } = useAccount();
  const { orderId } = useParams();
  const [currentOrder, isCanceled, setIsCanceled, reset] =
    useAdvancedProgressStore(s => [
      s.currentOrder,
      s.isCanceled,
      s.setIsCanceled,
      s.reset,
    ]);
  const [isLoading] = usePhaseBannerStore(s => [s.isLoading]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !address ||
      !currentOrder?.order.currency_in.symbol ||
      currentOrder.status !== BulkOrderStatuses.COMPLETE
    )
      return;

    phaseService.getPhaseBannerContent(
      address,
      currentOrder?.order.currency_in.symbol
    );
  }, [address, currentOrder?.status, currentOrder?.order.currency_in.symbol]);

  useEffect(() => {
    if (orderId) {
      bulkApi.getBulkOrderById(orderId).then(data => {
        if (!data) {
          navigate(HOME_PAGE);
          clearInterval(id);
          setIsCanceled(false);
          return;
        }

        if (
          data?.status === BulkOrderStatuses.COMPLETE ||
          data?.status === BulkOrderStatuses.REJECTED ||
          data?.status === BulkOrderStatuses.EXPIRED
        ) {
          clearInterval(id);
        } else {
          setIsCanceled(false);
        }
      });

      const id = setInterval(async () => {
        const data = await bulkApi.getBulkOrderById(orderId);
        if (
          data?.status !== BulkOrderStatuses.COMPLETE &&
          data?.status !== BulkOrderStatuses.REJECTED &&
          data?.status !== BulkOrderStatuses.EXPIRED &&
          data?.status !== BulkOrderStatuses.DEPOSIT_FAILED
        ) {
          setIsCanceled(false);
        }
      }, 10000);

      return () => clearInterval(id);
    } else {
      navigate(HOME_PAGE);
    }
  }, [orderId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  if (!currentOrder || isLoading) {
    return (
      <div className="formBg defaultRadius formContainer  gradient-border-mask  mt-0 send-advanced-form send-advanced-progress-form">
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      </div>
    );
  }

  if (currentOrder.status === BulkOrderStatuses.COMPLETE) {
    return <SuccessView />;
  }
  if (
    currentOrder.status === BulkOrderStatuses.REJECTED ||
    currentOrder.status === BulkOrderStatuses.EXPIRED ||
    currentOrder.status === BulkOrderStatuses.DEPOSIT_FAILED ||
    isCanceled
  ) {
    return <ErrorView />;
  }

  return <ProgressView />;
}
