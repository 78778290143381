import clsx from 'clsx';
import { Tooltip } from '../../../../components/tooltips';
import { ExecutionType } from '../../../../types/enums';
import { useAdvancedWalletStore } from '../../store';
import './styles.css';

export function SelectExecutionType() {
  const [executionType, setExecutionType] = useAdvancedWalletStore(s => [
    s.executionType,
    s.setExecutionType,
  ]);

  const handleExecutionTypeChange = (type: ExecutionType) => {
    setExecutionType(type);
  };

  return (
    <div className="select-execution-type-wrapper">
      <div className="d-flex align-items-center text-white bold network-label">
        <span className="bold">Execution type</span>
        <Tooltip variant="dark">
          <div className="lh-lg">
            <span className="text-white">Sequence</span> - Transfers executed
            over time
            <br />
            <span className="text-white">Fast</span> - Immediate send to all
            addresses.
          </div>
        </Tooltip>
      </div>
      <div className="execution-type-wrapper">
        <div
          className={clsx(
            'execution-type',
            executionType === 'Sequence' && '_1',
            executionType === 'Fast' && '_2'
          )}
        >
          <div className="execution-type--highlight" />
          <button
            className={clsx(
              'execution-type--trigger',
              executionType === 'Sequence' && 'active'
            )}
            onClick={() => handleExecutionTypeChange(ExecutionType.Sequence)}
          >
            Sequence
          </button>
          <button
            className={clsx(
              'execution-type--trigger',
              executionType === 'Fast' && 'active'
            )}
            onClick={() => handleExecutionTypeChange(ExecutionType.Fast)}
          >
            Fast
          </button>
        </div>
      </div>
    </div>
  );
}
