import { AdventureBox } from '@/Assets/Icons/AdventureBox';
import adventureBanner from '@/Assets/images/journeys/ton/adventureBanner.png';
import adventureBannerMobile from '@/Assets/images/journeys/ton/adventureBannerMobile.png';
import coin1 from '@/Assets/images/journeys/ton/coins/coin1.png';
import coin10 from '@/Assets/images/journeys/ton/coins/coin10.png';
import coin2 from '@/Assets/images/journeys/ton/coins/coin2.png';
import coin3 from '@/Assets/images/journeys/ton/coins/coin3.png';
import coin4 from '@/Assets/images/journeys/ton/coins/coin4.png';
import coin5 from '@/Assets/images/journeys/ton/coins/coin5.png';
import coin6 from '@/Assets/images/journeys/ton/coins/coin6.png';
import coin7 from '@/Assets/images/journeys/ton/coins/coin7.png';
import coin8 from '@/Assets/images/journeys/ton/coins/coin8.png';
import coin9 from '@/Assets/images/journeys/ton/coins/coin9.png';
import nftCard from '@/Assets/images/journeys/ton/nftImage.png';
import reward1 from '@/Assets/images/journeys/ton/reward1.svg';
import { Image } from '@/components/Image';
import { ROUTES } from '@/constants/routes.constants';
import { useTimeDifference } from '@/hooks/useTimeDifference';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { tonAdventure } from '../../ton';
import './styles.css';

export function TONBanner() {
  const { width } = useWindowSize();

  const timeToEnd = useTimeDifference(
    new Date(tonAdventure.endDate).getTime(),
    true
  );

  const endsIn = useMemo(() => {
    if (timeToEnd.days >= 1) {
      return `${timeToEnd.days} ${timeToEnd.days > 1 ? 'days' : 'day'}`;
    }
    if (timeToEnd.hours >= 1) {
      return `${timeToEnd.hours} ${timeToEnd.hours > 1 ? 'hours' : 'hour'}`;
    }
    if (+timeToEnd.minutes >= 1) {
      return `${+timeToEnd.minutes} ${
        +timeToEnd.minutes > 1 ? 'minutes' : 'minute'
      }`;
    }
    return 'less than a minute';
  }, [timeToEnd.days, timeToEnd.hours, timeToEnd.minutes]);

  const isActive =
    timeToEnd.days > 0 ||
    timeToEnd.hours > 0 ||
    +timeToEnd.minutes > 0 ||
    +timeToEnd.seconds > 0;

  return (
    <div className="adventures-carousel-item ton">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="adventure-carousel-item--bg"
      >
        <Image
          withSmoothLoading
          src={width < 992 ? adventureBannerMobile : adventureBanner}
          alt=""
          draggable={false}
        />
      </motion.div>
      <div className="position-relative z-1 my-auto">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="adventures-carousel-item--badge mb-2"
        >
          {isActive ? <>Ends in {endsIn}</> : 'Ended'}
        </motion.div>
        <motion.h2
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="adventures-carousel-item--heading"
        >
          TON Adventure
        </motion.h2>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="adventures-carousel-item--rewards"
        >
          <div className="adventures-carousel-item--rewards-label">
            Complete quests and get rewards:
          </div>
          <div className="adventures-carousel-item--rewards-item">
            <AdventureBox />
            RetroBridge & TON Adventure NFT
          </div>
          <div className="adventures-carousel-item--rewards-item">
            <img src={reward1} alt="" />
            -30% discount on bridging fee to TON
          </div>
        </motion.div>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Link
            to={ROUTES.PHASE_TON_ADVENTURE_PAGE}
            className="btn-white adventures-carousel-item--action"
          >
            Take TON Adventure
          </Link>
        </motion.div>
      </div>
      <div className="position-relative ms-lg-auto">
        <div className="adventures-carousel-item--coin-group">
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: -84, y: -50 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.1 }}
            src={coin1}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: -114, y: 172 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            src={coin2}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: 332, y: 271 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.3 }}
            src={coin3}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: -186, y: 114 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.4 }}
            src={coin4}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: -101, y: 48 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.5 }}
            src={coin5}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: 392, y: 161 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.6 }}
            src={coin6}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: 305, y: 19 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.7 }}
            src={coin7}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: -168, y: 301 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.8 }}
            src={coin8}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 0, y: 0 }}
            animate={{ opacity: 1, x: 399, y: 33 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delay: 0.9 }}
            src={coin9}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
          <motion.img
            initial={{ opacity: 0, x: 220, y: 0 }}
            animate={{ opacity: 1, x: 220, y: -51 }}
            exit={{
              opacity: 0,
              y: 0,
              transition: {
                duration: 0.2,
                delay: 0,
              },
            }}
            transition={{ duration: 0.2, delay: 0.9 }}
            src={coin10}
            draggable={false}
            alt=""
            className="adventures-carousel-item--coin"
          />
        </div>
        <motion.div
          initial={{ y: '100%', rotate: 45 }}
          animate={{ y: 0, rotate: 0 }}
          exit={{
            y: '100%',
            rotate: -45,
            transition: { duration: 0.5, delay: 0.2 },
          }}
          transition={{ duration: 0.5 }}
          className="adventures-carousel-item--nft-card"
        >
          <div className="adventures-carousel-item--nft-image-container">
            <Image
              withSmoothLoading
              src={nftCard}
              className="adventures-carousel-item--nft-image"
              alt=""
              draggable={false}
            />
          </div>
          <div className="adventures-carousel-item--nft-badge">
            Get NFT Reward
          </div>
          <div className="adventures-carousel-item--nft-heading">
            RetroBridge & TON Adventure
          </div>
        </motion.div>
      </div>
    </div>
  );
}
