import {
  PopoverAnchor,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import curetDown from '@/Assets/Icons/curetDownWiti.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { ICurrency } from '@/types/apiTypes';
import { useAdvancedWalletStore } from '../../store';
import { AdvancedTokenSelect } from '../AdvancedTokenSelect';
import './styles.css';

interface TokenFromInputProps {
  rootProps?: PopoverProps;
  menuProps?: PopoverContentProps;
  className?: string;
}

export function TokenChangeInput({
  rootProps,
  menuProps,
  className,
}: TokenFromInputProps) {
  const [currencyFromId, setCurrencyFrom] = useAdvancedWalletStore(s => [
    s.currencyFrom,
    s.setCurrencyFrom,
  ]);

  const handleChangeCurrency = (currency: ICurrency | undefined) => {
    if (!currency) return;
    setCurrencyFrom(currency);
  };

  return (
    <div className={clsx('advanced-token-change-input', className)}>
      <div className="text-white bold network-label">Token</div>

      <div className="d-flex flex-diections-row  my-auto">
        <AdvancedTokenSelect
          value={currencyFromId}
          onChange={handleChangeCurrency}
          contentProps={menuProps}
          rootProps={rootProps}
        >
          {currency => (
            <PopoverAnchor className="w-100 d-flex flex-direction-row align-items-center networks-list flex-nowrap">
              <button
                key={'currency-' + currency?.id}
                className={
                  'd-flex  border-0 bg-opacity-10 roundBtn selected-network padding-6 size-auto '
                }
              >
                <div className="d-flex coinBtn border-0 p-1 advanced-token-change-input-icon">
                  <img
                    src={
                      currency?.image_url ? BASE_URL + currency?.image_url : ''
                    }
                    alt="token"
                    className={`${
                      currency?.id === currencyFromId && 'ms1'
                    } networkIcon`}
                  />
                </div>
                <div className="mx-1 networkSelectBtn-text">
                  {currency?.symbol}
                </div>
              </button>
              <PopoverTrigger asChild>
                <div className="rounded-circle d-flex  roundBtn btn ">
                  <img
                    width="11"
                    height="8"
                    src={curetDown}
                    alt="open"
                    className="btnIcon"
                  />
                </div>
              </PopoverTrigger>
            </PopoverAnchor>
          )}
        </AdvancedTokenSelect>
      </div>
    </div>
  );
}
