import curetDown from '@/Assets/Icons/curetDownWiti.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { useBalance } from '@/hooks/useBalance';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useNetworksStore } from '@/stores/networks.store';
import { INetwork } from '@/types/apiTypes';
import { toFixed } from '@/utils/numbers';
import { PopoverAnchor, PopoverTrigger } from '@radix-ui/react-popover';
import { useTonAddress } from '@tonconnect/ui-react';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAccount as useEVMAccount } from 'wagmi';
import { useAdvancedWalletStore } from '../../store';
import { AdvancedNetworkSelect } from '../AdvancedNetworkSelect';
import { NetworkFromItem } from './NetworkFormItem';
import './styles.css';

export function NetworkFromInput() {
  const networks = useNetworksStore(s => sortBy(s.networks, 'source_position'));
  const [networkFrom, currencyFrom, setNetworkFrom] = useAdvancedWalletStore(
    s => [s.networkFrom, s.currencyFrom, s.setNetworkFrom]
  );
  const { address: account } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const tonAddress = useTonAddress();

  useEffect(() => {
    if (!networkFrom && networks[0]) {
      setNetworkFrom(networks[0]);
    }
  }, [networkFrom, networks[0]]);

  const connectedAccount = useMemo(
    () => account || starknetAddress || tonAddress,
    [account, starknetAddress, tonAddress]
  );
  const { balance } = useBalance(networkFrom, currencyFrom);

  const [fastSelectNetworks, setFastSelectNetworks] = useState<INetwork[]>([]);

  useEffect(() => {
    if (fastSelectNetworks.length > 0) return;

    const networksToSelect = networks
      .filter(n => n.id !== networkFrom?.id)
      .slice(0, 6);
    setFastSelectNetworks(networksToSelect);
  }, [networks, fastSelectNetworks.length, networkFrom?.id]);

  const prevValue = useRef<INetwork | undefined>(undefined);

  useEffect(() => {
    if (!networkFrom) return;

    const index = fastSelectNetworks.findIndex(n => n.id === networkFrom?.id);

    if (index !== -1 && prevValue.current) {
      const newFastSelect = fastSelectNetworks;
      newFastSelect[index] = prevValue.current;
      setFastSelectNetworks(newFastSelect);
    }

    prevValue.current = networkFrom;
  }, [fastSelectNetworks, networkFrom]);

  const handleNetworkChange = (network: INetwork | undefined) => {
    setNetworkFrom(network || networks[0]);
  };

  return (
    <div className="advanced-network-from-wrapper">
      <div className="advanced-from-input-header">
        <p>Network</p>
        <div className="">
          <span className="fw-medium blanco">Your Balance:</span>{' '}
          <span className="fz12 advanced-network-user-balance">
            {connectedAccount ? toFixed(+balance, 6) : '0'}
          </span>
        </div>
      </div>
      <AdvancedNetworkSelect
        value={networkFrom}
        onChange={handleNetworkChange}
        variant="from"
        networks={networks}
      >
        <PopoverAnchor>
          <div className="advanced-network-from">
            <div className="network-name">
              <div className="advanced-network-from-icon">
                <img
                  src={BASE_URL + networkFrom?.network_image_url}
                  alt="token"
                  className="advanced-network-from-icon-img"
                />
              </div>
              {networkFrom?.name}
            </div>
            <div className="d-flex flex-direction-row">
              <div className="w-100 d-flex flex-direction-row align-items-center networks-list flex-nowrap">
                {fastSelectNetworks.slice(1, 6).map((el, i, arr) => (
                  <NetworkFromItem
                    network={el}
                    networkFrom={networkFrom}
                    key={i}
                    onNetworkChange={handleNetworkChange}
                    i={i}
                    arr={arr}
                    style={{ zIndex: arr.length - i }}
                  />
                ))}
                <PopoverTrigger asChild>
                  <img
                    width="11"
                    height="8"
                    src={curetDown}
                    alt="open"
                    className="btnIcon"
                  />
                </PopoverTrigger>
              </div>
            </div>
          </div>
        </PopoverAnchor>
      </AdvancedNetworkSelect>
    </div>
  );
}
